import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';
import { TiWarning } from 'react-icons/ti';

import styles from './transport-legend.module.scss';

const TransportLegend = () => {
  const eventReducer = useSelector((store) => store.eventReducer);
  const [isOpen, setIsOpen] = useState(false);
  const { list: { types } } = eventReducer;

  return (
    <div className={styles.legend}>
      <div className={isOpen ? `${styles.header} ${styles.open}` : styles.header} onClick={() => setIsOpen((state) => !state)}>
        <p>Légende types transport</p>
        {isOpen ? <IoIosRemove /> : <IoIosAdd />}
      </div>
      {(types && isOpen) && (
        <ul>
          <li>
            <span className={styles.icon}><TiWarning /></span>
            <p>
              DAC, PB, REL
            </p>
          </li>
          {types.map((d) => <li key={`status-${d.value}`}>
            <div className={styles.dot} style={{ background: d.color }} />
            <p>
              {d.label}
            </p>
          </li>)}
        </ul>
      )}
    </div>
  );
};

export default TransportLegend;
