import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useReducer,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import produce, { current } from 'immer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import {
  getDayOfYear, addDays, setDayOfYear, formatISO,
} from 'date-fns';
import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronUp,
  BiChevronDown,
  BiCheckSquare,
  BiSquare,
} from 'react-icons/bi';
import { FiRefreshCcw } from 'react-icons/fi';
import { IoIosAdd } from 'react-icons/io';
import { BsSearch } from 'react-icons/bs';

import {
  getEvents,
  getListEvent,
  updateEvent,
} from '../../actions/event';

import {
  DatePicker,
  StyledSelect,
  InputText,
} from '../../lib/HooksFormFields';
import ModalStandalone from '../../lib/ModalStandalone';
import RightModalStandalone from '../../lib/RightModalStandalone';
import PlanningTransport from '../../components/PlanningTransport';
import TransportEvent from '../../components/TransportEvent';
import CreateTransportEvent from '../../components/form/TransportEventForm/CreateTransportEvent';
import UpdateTransportEvent from '../../components/form/TransportEventForm/UpdateTransportEvent';
import styles from './transport.module.scss';
import TransportLegend from '../../components/TransportLegend';

const refrestTiming = 20 * 1000;

const dragReducer = produce((draft, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'SET': {
      const data = action.payload || {};
      const draftEntries = Object.keys(current(draft));
      draftEntries.forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        if (key !== 'notAssign') delete draft[key];
      });
      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        draft[key] = data[key];
      });
      break;
    }
    case 'RESET': {
      // eslint-disable-next-line no-param-reassign
      draft.notAssign = [];
      // eslint-disable-next-line no-param-reassign
      draft.draggableId = [];
      // eslint-disable-next-line no-param-reassign
      draft.droppableId = [];
      break;
    }
    case 'DRAG_START': {
      // eslint-disable-next-line no-param-reassign
      draft.isDragging = true;
      break;
    }
    case 'MOVE': {
      // eslint-disable-next-line no-param-reassign
      draft[action.from] = draft[action.from] || [];
      // eslint-disable-next-line no-param-reassign
      draft[action.to] = draft[action.to] || [];
      // eslint-disable-next-line no-param-reassign
      draft.isDragging = false;
      draft[action.from].splice(action.fromIndex, 1);
      draft[action.to].splice(action.toIndex, 0, action.updatedEvent);
      draft[action.from].sort((a, b) => a.position - b.position);
      draft[action.to].sort((a, b) => a.position - b.position);
    }
  }
});

const viewOpts = [
  { label: 'Vue semaine', value: 'week' },
  { label: 'Vue jour', value: 'day' },
];

const Transport = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const planningRef = useRef();
  const modalCreate = useRef();
  const modalUpdate = useRef();
  const { authReducer, eventReducer } = useSelector((store) => store);
  const { selectedAgency, user: { rights = {} } } = authReducer;
  const {
    events, changedAt, isLoading, list,
  } = eventReducer;

  const defaultView = 'day';
  const [week, setWeek] = useState();
  // const [listOpts, setListOpts] = useState();
  const [assignableEventOpen, setAssignableEventOpen] = useState(true);
  const initialData = { notAssign: [], draggableId: null, droppableId: null };
  const [stateEvents, dispatchDrag] = useReducer(dragReducer, initialData);
  const [showWeekend, setShowWeekend] = useState(false);

  const idleTimestamp = useRef(new Date());

  // PARAMS FOR GET EVENTS
  const paramDay = searchParams.get('day');
  const paramView = searchParams.get('view') || defaultView;
  const paramsType = searchParams.get('type');
  const paramsUser = searchParams.get('user');
  const paramsCaseId = searchParams.get('caseId');
  const paramsDirection = searchParams.get('direction');
  const paramsFilterStartDate = searchParams.get('filterStartDate');
  const paramsFilterEndDate = searchParams.get('filterEndDate');
  const paramsSearch = useDebounce(searchParams.get('search'), 300);

  const [directionEvent, setDirectionEvent] = useState(paramsDirection);

  const {
    control,
    setValue,
    // watch,
  } = useForm();

  const onDragEnd = useCallback((result) => {
    if (result.reason === 'DROP') {
      if (!result.destination) {
        return;
      }

      const updatedEvent = events.find((event) => event._id === result.draggableId);

      let distance = new Date(updatedEvent.endDate).getTime()
        - new Date(updatedEvent.startDate).getTime();

      if (distance === 0) distance = 1800 * 1000;

      const [time, day] = result.destination.droppableId.split('-');

      let startDate = null;
      let endDate = null;
      if (day) {
        startDate = setDayOfYear(new Date(), day);
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10); minutes = parseInt(minutes, 10);
        startDate.setHours(hours); startDate.setMinutes(minutes);
        startDate.setSeconds(0);
        endDate = formatISO(new Date(startDate.getTime() + distance));
        startDate = formatISO(startDate);
      }

      const eventData = {
        _id: result.draggableId,
        startDate,
        endDate,
      };

      if (updatedEvent.direction === 'departure') {
        eventData.loadDate = startDate;
      } else if (updatedEvent.direction === 'return') {
        eventData.deliveryDate = startDate;
      }

      updateEvent(dispatch, eventData);

      dispatchDrag({
        type: 'MOVE',
        from: result.source.droppableId,
        to: result.destination.droppableId,
        fromIndex: result.source.index,
        toIndex: result.destination.index,
        updatedEvent: {
          ...updatedEvent,
          startDate,
          endDate,
        },
      });
    }
  }, [events]);

  const onDragStart = useCallback(() => {
    dispatchDrag({
      type: 'DRAG_START',
    });
  }, []);

  // HANDLE NAVIGATION WITH KEYBOARD ARROW
  useEffect(() => {
    const navigateWithKeyboard = (e) => {
      if (e.key === 'ArrowLeft') planningRef.current.prevWeek();
      else if (e.key === 'ArrowRight') planningRef.current.nextWeek();
    };
    document.body.addEventListener('keyup', navigateWithKeyboard);
    document.body.addEventListener('touchend', navigateWithKeyboard);
    return () => {
      document.body.removeEventListener('keyup', navigateWithKeyboard);
      document.body.removeEventListener('touchend', navigateWithKeyboard);
    };
  }, []);

  const handleGetEvents = () => {
    // rest params allow us to get all params except day and view
    const {
      view,
      day,
      ...params
    } = Object.fromEntries(searchParams.entries());
    let currentDay;

    if (/^(-|\d)[0-9]*$/g.test(paramDay)) {
      currentDay = parseInt(paramDay, 10);
      if (currentDay < (365 * -3)) currentDay = parseInt(getDayOfYear(new Date()), 10);
    } else {
      currentDay = getDayOfYear(new Date());
    }

    if (paramView === 'week') {
      const curr = setDayOfYear(new Date(), currentDay);
      const first = (curr.getDate() - curr.getDay()) + 1;
      const startDate = new Date(curr.setDate(first));
      const endDate = addDays(startDate, 6);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);
      params.startDate = startDate.toISOString();
      params.endDate = endDate.toISOString();
    } else if (paramView === 'day') {
      const startDate = setDayOfYear(new Date(), currentDay);
      const endDate = setDayOfYear(new Date(), currentDay);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);
      params.startDate = startDate.toISOString();
      params.endDate = endDate.toISOString();
    }

    getEvents(dispatch, params);
  };

  // GET EVENTS
  useEffect(() => {
    handleGetEvents();
  }, [
    selectedAgency,
    paramsType,
    paramsUser,
    paramDay,
    paramView,
    paramsCaseId,
    paramsDirection,
    paramsFilterStartDate,
    paramsFilterEndDate,
    paramsSearch,
  ]);

  // HANDLE CANCEL TRIGGER REFRESH EVENTS
  useEffect(() => {
    const handleResetIdleTimer = () => {
      idleTimestamp.current = Date.now();
    };
    document.addEventListener('keyup', handleResetIdleTimer);
    document.addEventListener('click', handleResetIdleTimer);
    document.addEventListener('wheel', handleResetIdleTimer);
    return () => {
      document.addEventListener('keyup', handleResetIdleTimer);
      document.removeEventListener('wheel', handleResetIdleTimer);
      document.removeEventListener('click', handleResetIdleTimer);
    };
  }, []);

  useEffect(() => {
    getListEvent(dispatch);
  }, [changedAt]);

  // HANDLE REFRESH EVENTS WHEN NO ACTION IS DONE FOR A WHILE
  useEffect(() => {
    const timer = setTimeout(() => {
      const paramId = searchParams.get('id');
      if (!paramId) handleGetEvents();
      idleTimestamp.current = Date.now();
    }, refrestTiming);
    return () => {
      clearTimeout(timer);
    };
  }, [idleTimestamp.current, searchParams]);

  function setSearchParam(param, d) {
    const params = new URLSearchParams(searchParams);
    params.set(param, d);
    setSearchParams(params);
  }

  function deleteSearchParam(param) {
    const params = new URLSearchParams(searchParams);
    params.delete(param);
    setSearchParams(params);
  }

  // HANDLE OPEN MODAL DETAIL
  useEffect(() => {
    if (!rights.write) return;
    const paramId = searchParams.get('id');
    if (paramId) {
      modalUpdate.current.open();
    } else {
      modalUpdate.current.close();
    }
  }, [searchParams, rights.write]);

  // INIT EVENTS
  useEffect(() => {
    if (!events || events.length === 0) {
      dispatchDrag({
        type: 'RESET',
      });
      return;
    }
    const data = { notAssign: [] };

    // CREATE LAYOUT
    const tree = [];
    let arrayEvents = events.map((d) => JSON.parse(JSON.stringify(d)));
    arrayEvents = arrayEvents.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

    let end = (new Date(arrayEvents[0].endDate)).getTime();
    let branch = 0;
    tree[branch] = [arrayEvents[0]];
    arrayEvents.splice(0, 1);

    const createTree = () => {
      // console.log('createTree');
      if (arrayEvents.length === 0) {
        // console.log('end');
        return;
      }
      let indexFind = null;
      const find = arrayEvents.find((event, i) => {
        if ((new Date(event.startDate)).getTime() < end) {
          indexFind = i;
          return true;
        } return false;
      });
      if (find) {
        tree[branch].push(find);
        arrayEvents.splice(indexFind, 1);
        if ((new Date(find.endDate)).getTime() > end) {
          end = (new Date(find.endDate)).getTime();
        }
        createTree();
      } else {
        end = (new Date(arrayEvents[0].endDate)).getTime();
        branch += 1;
        tree[branch] = [arrayEvents[0]];
        arrayEvents.splice(0, 1);
        createTree();
      }
    };

    createTree();

    const layout = {};
    // console.log(tree);
    tree.forEach((group, groupIndex) => {
      group.forEach((event, eventIndex) => {
        layout[event._id] = {
          group: groupIndex,
          countGroup: group.length,
          zIndex: Object.keys(layout).length,
          indexInGroup: eventIndex,
        };
      });
    });

    // console.log(layout);

    //

    events.forEach((event) => {
      const key = getDayOfYear(new Date(event.startDate));
      const startDate = new Date(event.startDate);
      let startHours = startDate.getHours();
      let startMinutes = startDate.getMinutes();
      startMinutes = startMinutes < 30 ? '00' : '30';
      startHours = startHours < 10 ? `0${startHours}:${startMinutes}` : `${startHours}:${startMinutes}`;
      const eventWithLayoutData = { ...event, ...layout[event._id] };
      if (event.startDate && !Number.isNaN(key) && data[`${startHours}-${key}`]) {
        data[`${startHours}-${key}`].push(eventWithLayoutData);
        data[`${startHours}-${key}`].sort((a, b) => a.position - b.position);
      } else if (event.startDate && !Number.isNaN(key)) data[`${startHours}-${key}`] = [eventWithLayoutData];
      else { data.notAssign.push(eventWithLayoutData); }
      if (data[`${startHours}-${key}`]) {
        data[`${startHours}-${key}`] = data[`${startHours}-${key}`].sort((a, b) => a.indexInGroup - b.indexInGroup);
      }
    });

    data.notAssign = data.notAssign
      .sort((a, b) => {
        const dateA = a.deliveryDate ? new Date(a.deliveryDate) : new Date();
        const dateB = b.deliveryDate ? new Date(b.deliveryDate) : new Date();
        return dateA - dateB;
      });

    data.notAssign = [
      ...data.notAssign.filter((d) => !d.deliveryDate),
      ...data.notAssign.filter((d) => d.deliveryDate)];

    dispatchDrag({
      type: 'SET',
      payload: data,
    });
  }, [events, changedAt]);

  useEffect(() => {
    if (!planningRef.current) return;
    planningRef.current.toggleShowWeekend(showWeekend);
  }, [planningRef, showWeekend]);

  useEffect(() => {
    if (!directionEvent) {
      deleteSearchParam('direction');
    } else setSearchParam('direction', directionEvent);
  }, [directionEvent]);

  function getDefaultViewValue() {
    return viewOpts.find((opt) => opt.value === (paramView || defaultView));
  }

  function handleCloseModalUpdate() {
    const params = new URLSearchParams(searchParams);
    params.delete('id');
    setSearchParams(params);
  }

  const deleteFilterDates = () => {
    setValue('filterStartDate', null);
    setValue('filterEndDate', null);
    const params = new URLSearchParams(searchParams);
    params.delete('filterStartDate');
    params.delete('filterEndDate');
    setSearchParams(params);
    // deleteSearchParam('filterEndDate');
  };

  const getItemStyle = (isDragging, draggableStyle, index) => ({
    userSelect: 'none',
    margin: 0,
    zIndex: stateEvents.notAssign.length - index,
    ...draggableStyle,
  });

  function getDefaultTypeValue() {
    return list?.types.find((opt) => opt.value === paramsType);
  }

  function getDefaultCaseValue() {
    return list?.cases.find((opt) => opt.value === paramsCaseId);
  }

  function getDefaultUserValue() {
    return list?.users.find((opt) => opt.value === paramsUser);
  }

  return (
    <>
      {rights.write && (
        <>
          <ModalStandalone ref={modalCreate} overflow>
          <CreateTransportEvent
            handleClose={() => modalCreate.current.close()}
            listOpts={list}
          />
        </ModalStandalone>
        <RightModalStandalone ref={modalUpdate} zIndex={2000}>
          <UpdateTransportEvent
            handleClose={() => handleCloseModalUpdate()}
            listOpts={list}
          />
        </RightModalStandalone>
        </>
      )}
      <DragDropContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <div className={styles.transport} >
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.filters}>
                <h3>Transport à planifier</h3>
                <p className={directionEvent === 'departure' ? styles.selected : ''} onClick={() => setDirectionEvent((type) => (type === 'departure' ? '' : 'departure'))}>Départ</p>
                <span> / </span>
                <p className={directionEvent === 'return' ? styles.selected : ''} onClick={() => setDirectionEvent((type) => (type === 'return' ? '' : 'return'))}>Retour</p>
                <div className={styles.filtersDate}>
                  <DatePicker
                    name="filterStartDate"
                    control={control}
                    placeholder='Date de début'
                    className='primary'
                    defaultValue={paramsFilterStartDate ? new Date(paramsFilterStartDate) : null}
                    handleChange={(date) => {
                      if (!date) deleteSearchParam('filterStartDate');
                      else {
                        const d = new Date(date.getTime() + 2 * 3600 * 1000);
                        setSearchParam('filterStartDate', d.toISOString());
                      }
                    }}
                  />
                  <DatePicker
                    name="filterEndDate"
                    control={control}
                    placeholder='Date de fin'
                    className='primary'
                    defaultValue={paramsFilterEndDate ? new Date(paramsFilterEndDate) : null}
                    handleChange={(date) => {
                      if (!date) deleteSearchParam('filterEndDate');
                      else {
                        const d = new Date(date.getTime() + (2 * 3600 * 1000));
                        setSearchParam('filterEndDate', d.toISOString());
                      }
                    }}
                  />
                  <p onClick={deleteFilterDates}>
                    Effacer les dates
                  </p>
                </div>
              </div>
              <div className={styles.fieldSearch}>
                <InputText
                  name="search"
                  control={control}
                  placeholder="Rechercher..."
                  className='primary'
                  defaultValue={searchParams.get('search') || ''}
                  onChange={(search) => {
                    if (!search) deleteSearchParam('search');
                    else {
                      setSearchParam('search', search);
                    }
                  }}
                />
              <div className={styles.icon}>
                <BsSearch />
              </div>
              </div>
            </div>
            <div>
              <TransportLegend />
            </div>
            <div className={styles.notAssignEvents}>
              <div className={styles.containerBtn}>
                {rights.write && (
                  <button onClick={() => modalCreate.current.open()}>
                    <span className='icon'><IoIosAdd size='20px' /></span>
                    <span>Créer un événement</span>
                  </button>
                )}
                <button onClick={() => handleGetEvents()}>
                  <FiRefreshCcw className={isLoading.includes('EVENTS_GET') ? styles.loading : ''}/>
                </button>
              </div>
              <div className={styles.containerEvents}>
                <div className={`${styles.toggle} ${styles.top}`}
                  onClick={() => setAssignableEventOpen((bool) => !bool)}
                >
                  {assignableEventOpen
                    ? <BiChevronUp size='24' />
                    : <BiChevronDown size='24' />
                  }
                </div>
                <div className={styles.toggle}
                  onClick={() => setAssignableEventOpen((bool) => !bool)}
                >
                  {assignableEventOpen
                    ? <BiChevronUp size='24' />
                    : <BiChevronDown size='24' />
                  }
                </div>
                <Droppable
                  droppableId={'notAssign'}
                  type="EVENT"
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {(assignableEventOpen
                        && stateEvents?.notAssign.length > 0
                      ) ? (
                        <div
                          className={styles.droppable}
                        >
                          {stateEvents?.notAssign.map((event, index) => (
                            <Draggable
                              key={event._id} draggableId={event._id} index={index}
                              isDragDisabled={!rights.write}
                            >
                              {(providedDraggable, snapshotDraggable) => (
                                <div
                                  ref={providedDraggable.innerRef}
                                  {...providedDraggable.draggableProps}
                                  {...providedDraggable.dragHandleProps}
                                  style={getItemStyle(
                                    snapshotDraggable.isDragging,
                                    providedDraggable.draggableProps.style,
                                    index,
                                  )}
                                >
                                  <TransportEvent
                                    key={event._id}
                                    index={index}
                                    id={event._id}
                                    number={event.number}
                                    agency={event.agency}
                                    startDate={event.startDate}
                                    endDate={event.endDate}
                                    deliveryDate={event.deliveryDate}
                                    deliveryLocationCity={event?.deliveryLocation?.city}
                                    clients={event?.cases}
                                    type={event.type}
                                    transport={event.transport}
                                    showTransport={false}
                                    trunkType={event.trunkType}
                                    carrier={event.carrier}
                                    direction={event.direction}
                                    linkedTasks={event.linkedTasks}
                                    steps={event.steps.filter(
                                      (step) => step.type === event.direction,
                                    )}
                                    listEvents={stateEvents.notAssign}
                                    countGroup={event.countGroup}
                                    zIndex={event.zIndex}
                                    indexInGroup={event.indexInGroup}
                                    hasWarning={event.hasWarning}
                                    maxWidth={100}
                                    notAssign
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                        ) : (
                          <div className={styles.containerEventsClosed}>
                            {(stateEvents?.notAssign.length === 0 || !stateEvents?.notAssign)
                              ? <p>Aucun transport à planifier</p>
                              : <p>
                                <span>{stateEvents?.notAssign.length}</span>
                                Tâche{stateEvents?.notAssign.length > 1 && 's'} à planifier
                              </p>
                            }
                          </div>
                        )}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
            <div className={styles.planningActions}>
              <div className={styles.weekNav}>
                <h3>Semaine {week}</h3>
                <button onClick={() => planningRef.current.prevWeek()}><BiChevronLeft /></button>
                <button onClick={() => planningRef.current.nextWeek()}><BiChevronRight /></button>
              </div>
              <div className={styles.containerSelect}>
                <StyledSelect
                  name="view"
                  control={control}
                  className='primary'
                  defaultValue={getDefaultViewValue()}
                  options={viewOpts}
                  handleChange={(opt) => {
                    planningRef.current.setViewType(opt.value);
                  }}
                />
              </div>
              <button className='third' onClick={() => planningRef.current.selectToday()}>Aujourd'hui</button>
              <div
                className={`${styles.toggleWeekend}${showWeekend ? ` ${styles.selected}` : ''}`}
                onClick={() => setShowWeekend((state) => !state)}
              >
                {showWeekend ? <BiCheckSquare size={14} /> : <BiSquare size={14} />}
                <p>Week-end</p>
              </div>
              <div className={styles.filters}>
                {list?.cases && (
                  <div className={styles.containerSelect}>
                    <StyledSelect
                      name="caseId"
                      control={control}
                      className='primary'
                      placeholder='Affaire: Tous'
                      defaultValue={getDefaultCaseValue()}
                      options={list?.cases}
                      isClearable
                      isSearchable
                      handleChange={(opt) => {
                        if (!opt?.value) deleteSearchParam('caseId');
                        else setSearchParam('caseId', opt.value);
                      }}
                    />
                  </div>
                )}
                {list?.types && (
                  <div className={styles.containerSelect}>
                    <StyledSelect
                      name="type"
                      control={control}
                      className='primary'
                      placeholder='Type de transport: Tous'
                      defaultValue={getDefaultTypeValue()}
                      options={list?.types}
                      isClearable
                      handleChange={(opt) => {
                        if (!opt?.value) deleteSearchParam('type');
                        else setSearchParam('type', opt.value);
                      }}
                    />
                  </div>
                )}
                {list?.users && (
                  <div className={styles.containerSelect}>
                    <StyledSelect
                      name="user"
                      control={control}
                      className='primary'
                      placeholder='Commerciaux: Tous'
                      defaultValue={getDefaultUserValue()}
                      options={list?.users}
                      isClearable
                      handleChange={(opt) => {
                        if (!opt?.value) deleteSearchParam('user');
                        else setSearchParam('user', opt.value);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.planning}>
              {list && <PlanningTransport
                ref={planningRef}
                defaultView={defaultView}
                listEvents={stateEvents}
                listOpts={list}
                closingDays={selectedAgency.closingDays}
                isDragging={stateEvents.isDragging}
                showWeekend={showWeekend}
                handleChangeWeek={(w) => setWeek(w)}
              />}
            </div>
          </div>
        </div>
      </DragDropContext>
    </>
  );
};

export default Transport;
