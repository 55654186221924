import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Droppable } from 'react-beautiful-dnd';
import {
  getDayOfYear,
  setDayOfYear,
  getWeek,
  format,
  isValid as isValidDate,
  // getTime,
} from 'date-fns';
import fr from 'date-fns/locale/fr';
import ModalStandalone from '../../lib/ModalStandalone';
import TransportEvent from '../TransportEvent';
import CreateTransportEvent from '../form/TransportEventForm/CreateTransportEvent';
import styles from './planning-transport.module.scss';

const PlanningTransport = ({
  listEvents = {},
  defaultView = 'week',
  isDragging = false,
  showWeekend = false,
  handleChangeWeek,
  listOpts = [],
  closingDays = [],
}, ref) => {
  const initialDays = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
  const times = ['06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00',
    '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30'];
  const modalCreate = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [day, setDay] = useState();
  const [view, setView] = useState(defaultView);
  const [withWeekend, setWithWeekend] = useState(showWeekend);

  const [defaultCreateDate, setDefaultCreateDate] = useState(new Date());
  const [defaultCreateTime, setDefaultCreateTime] = useState(times[0]);

  const days = useMemo(
    () => initialDays.slice(0, initialDays.length - (withWeekend ? 0 : 2)),
    [withWeekend],
  );

  function setSearchParam(param, d) {
    const params = new URLSearchParams(searchParams);
    params.set(param, d);
    setSearchParams(params);
  }

  useImperativeHandle(ref, () => ({
    getWeek: () => getWeek(setDayOfYear(new Date(), day)),
    selectToday: () => {
      const currDay = getDayOfYear(new Date());
      setSearchParam('day', currDay);
    },
    setViewType: (type) => {
      if (!['week', 'day'].includes(type)) {
        console.warn(`view type should be ['week', 'day'], got : ${type}`);
      } else {
        if (type === 'day') {
          const currDay = setDayOfYear(new Date(), day);
          const currDisplayWeek = getWeek(currDay);
          const todayWeek = getWeek(new Date());

          // IF WE SWITCH TO VIEW DAY AND THE CURRENT DISPLAY WEEK
          // ISN'T THE CURRENT REAL WEEK SET DAY TO THE FIRST DAY OF THE
          // DISPLAY WEEK
          if (todayWeek !== currDisplayWeek) {
            const firstDayOfCurrentWeek = (currDay.getDate() - currDay.getDay()) + 1;
            const firstDateOfCurrentWeek = new Date(currDay
              .setDate((firstDayOfCurrentWeek))).toUTCString();
            setSearchParam('day', getDayOfYear(new Date(firstDateOfCurrentWeek)));
          } else if (todayWeek === currDisplayWeek) {
            // IF WE SWITCH TO VIEW DAY AND
            // THE CURRENT DISPLAY WEEK === THE CURRENT REAL WEEK
            // SET DAY TO THE REAL CURRENT DAY OF THE REAL WEEK
            setSearchParam('day', getDayOfYear(new Date()));
          }
        }
        setSearchParam('view', type);
      }
    },
    prevWeek: () => {
      let curr = day;
      curr -= view === 'week' ? 7 : 1;
      const currentIndex = setDayOfYear(new Date(), day).getDay();
      // JUMP OVER WEEK-END
      // if (view === 'day' && currentIndex === 1) curr -= 1;
      if (!withWeekend && view === 'day' && currentIndex === 1) curr -= 2;
      setSearchParam('day', curr);
    },
    // eslint-disable-next-line no-param-reassign
    nextWeek: () => {
      let curr = day;
      curr += view === 'week' ? 7 : 1;
      const currentIndex = setDayOfYear(new Date(), day).getDay();
      if (!withWeekend && view === 'day' && currentIndex === 5) curr += 2;
      setSearchParam('day', curr);
    },
    toggleShowWeekend: (bool) => {
      const curr = day;
      const currentIndex = setDayOfYear(new Date(), day).getDay();
      if (!bool && [0, 6].includes(currentIndex)) {
        setSearchParam('day', curr + (currentIndex === 6 ? 2 : 1));
      }
      setWithWeekend((state) => (bool || !state));
    },
  }));

  // HANDLE STATE DAY DEPENDING ON PARAM DAY
  // HANDLE STATE VIEW DEPENDING ON PARAM VIEW
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const paramDay = params.get('day');
    if (/^(-|\d)[0-9]*$/g.test(paramDay)) {
      let value = parseInt(paramDay, 10);
      if (value < (365 * -3)) value = getDayOfYear(new Date());
      const currentIndex = setDayOfYear(new Date(), value).getDay();
      if (!withWeekend && [0, 6].includes(currentIndex)) {
        value += (currentIndex === 6 ? 2 : 1);
        setSearchParam('day', value);
      }
      setDay(value);
    } else {
      setDay(getDayOfYear(new Date()));
    }

    const paramView = params.get('view');
    if (['week', 'day'].includes(paramView)) {
      setView(paramView);
    } else setView(defaultView);
  }, [searchParams]);

  // HANDLE CHANGE WEEK DEPENDING ON DAY
  useEffect(() => {
    if (handleChangeWeek) {
      handleChangeWeek(getWeek(setDayOfYear(new Date(), day)));
    }
  }, [day]);

  // RETURN COMPLETE DATE ACCORDING TO strDay
  function getDay(strDay) {
    let indexDay = days.indexOf(strDay);

    const curr = setDayOfYear(new Date(), day);
    const first = (curr.getDate() - curr.getDay()) + 1;

    if (withWeekend && view === 'day' && indexDay === 6) {
      indexDay = -1;
    }

    const resDay = new Date(curr.setDate((first + indexDay))).toUTCString();
    return new Date(resDay);
  }

  function getListRenderDay() {
    const curr = setDayOfYear(new Date(), day);
    let currentIndex = curr.getDay() - 1;
    if (withWeekend && currentIndex < 0) currentIndex = 6;
    return (view === 'day' ? days.filter((d) => d === days[currentIndex]) : days);
  }

  function openModalCreate(d, hour) {
    setDefaultCreateDate(d);
    setDefaultCreateTime(hour);
    if (!isDragging) modalCreate.current.open();
  }

  const isClose = useCallback((d, hour) => {
    let isClosed = false;
    if (hour) {
      const time = hour.split(':');
      const currentDayStart = new Date(d);
      currentDayStart.setHours(parseInt(time[0], 10));
      currentDayStart.setMinutes(parseInt(time[1], 10));
      currentDayStart.setSeconds(0);
      closingDays.forEach((date) => {
        const startDate = new Date(date.startDate);
        const endDate = new Date(date.endDate);
        if (
          currentDayStart >= startDate
          && currentDayStart < endDate
        ) isClosed = true;
      });
    }

    return isClosed;
  }, [closingDays]);

  const isAllDayClose = useCallback((d) => {
    let isClosed = false;
    const currentDayStart = new Date(d);
    currentDayStart.setHours(6);
    currentDayStart.setMinutes(0);
    currentDayStart.setSeconds(0);

    const currentDayEnd = new Date(d);
    currentDayEnd.setHours(20);
    currentDayEnd.setMinutes(0);
    currentDayEnd.setSeconds(0);

    closingDays.forEach((date) => {
      const startDate = new Date(date.startDate);
      const endDate = new Date(date.endDate);
      if (currentDayStart >= startDate && currentDayEnd <= endDate) isClosed = true;
    });

    return isClosed;
  }, [closingDays]);

  const getCellHeaderClassName = (d) => {
    let className = `${styles.cell} ${styles.header}`;
    if (getDayOfYear(getDay(d)) === getDayOfYear(new Date())) className += ` ${styles.today}`;
    if (isValidDate(getDay(d)) && isAllDayClose(getDay(d))) className += ` ${styles.closed}`;
    return className;
  };

  const getCellClassName = (d, hour) => {
    let className = styles.cell;
    if (getDayOfYear(getDay(d)) === getDayOfYear(new Date())) className += ` ${styles.today}`;
    if (isClose(getDay(d), hour)) className += ` ${styles.closed}`;
    return className;
  };

  return (
    <>
      <ModalStandalone ref={modalCreate} overflow>
        <CreateTransportEvent
          defaultDate={defaultCreateDate}
          defaultTime={defaultCreateTime}
          listOpts={listOpts}
          handleClose={() => modalCreate.current.close()}
        />
      </ModalStandalone>
      <div className={styles.planning}>
        <div className={`${styles.row} ${styles.header}`}>
          <div className={styles.containerCell}>
            {getListRenderDay().map((d) => (
              <div
                key={`day-${d}`}
                className={getCellHeaderClassName(d)}
              >
                <p>{d}</p>
                <p>{isValidDate(getDay(d)) && (
                  <>
                    <span>{getDay(d)?.getDate()}</span>
                    <span className={styles.month}> {format(getDay(d), 'MMMM', { locale: fr })}</span>
                  </>
                )}</p>
              </div>
            ))}
          </div>
        </div>
        {typeof day === 'number' && times.map((hour) => (
          <div
            key={`row-time-${hour}`}
            className={styles.row}
          >
            <div
              key={`legend-time-${hour}`}
              className={styles['legend-line']}
            >
              <p>
                {hour}
              </p>
            </div>
            <div
              className={styles.containerCell}
            >
              {getListRenderDay().map((d, i) => (
                <div
                  key={`${hour}-${d}`}
                  onClick={() => openModalCreate(getDay(d), hour)}
                  className={getCellClassName(d, hour)}>
                  <DroppableZone
                    index={i}
                    id={`${hour}-${getDayOfYear(getDay(d))}`}
                    events={listEvents[`${hour}-${getDayOfYear(getDay(d))}`]}
                    isDragging={isDragging}
                    listEvents={listEvents}
                    view={view}
                    isDropDisabled={isClose(getDay(d), hour)}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const DroppableZone = ({
  id,
  events = [],
  isDragging = false,
  isDropDisabled = false,
  view,
  listEvents,
}) => {
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    if (!window.innerWidth) return;
    const droppableZone = document.getElementById(id);
    setMaxWidth(droppableZone?.clientWidth || 0);
  }, [window.innerWidth]);

  return (
    <Droppable
      droppableId={id}
      type="EVENT"
      direction="horizontal"
      isDropDisabled={isDropDisabled}
    // isCombineEnabled={true}
    >
      {(provided, snapshot) => (
        <div
          id={id}
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={`${styles.droppable}${isDragging ? ` ${styles.dragging}` : ''}`}
        >
          {/* {console.log(events)} */}
          {events?.map((event, i) => (
            <TransportEvent
              key={event._id}
              index={i}
              id={event._id}
              number={event.number}
              agency={event.agency}
              startDate={event.startDate}
              endDate={event.endDate}
              clients={event?.cases}
              type={event.type}
              deliveryLocationCity={event?.deliveryLocation?.city}
              transport={event.transport}
              showTransport={view === 'day'}
              trunkType={event.trunkType}
              commentIntern={event.commentIntern}
              commentExtern={event.commentExtern}
              carrier={event.carrier}
              direction={event.direction}
              linkedTasks={event.linkedTasks}
              steps={event.steps.filter((step) => step.type === event.direction)}
              listEvents={listEvents}
              countGroup={event.countGroup}
              zIndex={event.zIndex}
              indexInGroup={event.indexInGroup}
              hasWarning={event.hasWarning}
              maxWidth={maxWidth}
              showComments={event.countGroup === 1 && view === 'day'}
              showIconComments={
                (event.countGroup > 1 || (view === 'week')) && event.commentIntern
              }
            />
          ))}
          <div
            className={`${styles.placeholderEvent}${snapshot.isDraggingOver ? ` ${styles.hover}` : ''}`}
            style={{ width: maxWidth }}
          >{provided.placeholder}</div>
        </div>
      )}
    </Droppable>
  );
};

export default forwardRef(PlanningTransport);
