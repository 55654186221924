import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../actions/auth';
import EmailResetPwdForm from '../../components/form/AuthForm/EmailResetPwdForm';
import styles from './forgot-password.module.scss';

const ForgotPassword = () => {
  const authReducer = useSelector((d) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/home');
  }, [authReducer.user]);

  function submitForgot(data) {
    forgotPassword(dispatch, data).then((res) => {
      if (res?.status === 200) {
        setMessage('Un email permettant de changer votre mot de passe vous a été envoyé.');
      }
    });
  }

  return (
    <div className={styles['forgot-password']}>
      <div className={styles.col}></div>
      <div className={styles.col}>
        <div className={styles['container-form']}>
          <h1>Mot de passe oublié</h1>
          <EmailResetPwdForm
            submit={submitForgot}
            succeedMessage={message}
            errorMessage={authReducer.error}
          />
          <div className={styles.center}>
            <NavLink to="/">
              Se connecter à mon compte ?
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
