import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';

import styles from './select-event-cases.module.scss';
import {
  StyledSelect,
} from '../../../../lib/HooksFormFields';

const SelectEventCases = ({
  options = [],
  control,
  onChange,
  clearErrors,
}) => {
  const {
    control: control2,
    watch,
    setValue,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'cases', // unique name for your Field Array
    defaultValue: null,
  });
  const selectedCases = watch('select-cases');

  useEffect(() => {
    if (selectedCases) {
      setValue('select-cases', null);
      append(selectedCases);
      if (clearErrors) clearErrors('cases', null);
    }
  }, [selectedCases]);

  function handleDelete(index) {
    remove(index);
  }

  function filterOptions(opts) {
    const caseId = fields.map((c) => c.value);
    const filteredOpts = opts.filter((opt) => !caseId.includes(opt.value));
    return filteredOpts.sort((a, b) => a.label.localeCompare(b.label));
  }

  return (
    <div className={styles.fieldCases}>
      <div className={styles['add-cases']}>
        <StyledSelect
          name="select-cases"
          placeholder="Rechercher les clients à ajouter"
          inline
          isSearchable
          control={control2}
          icon={<div className={styles.icon}><BsSearch /></div>}
          handleChange={(value) => {
            if (typeof onChange === 'function') onChange(value);
          }}
          options={filterOptions(options)}
        />
      </div>
      <div className={styles.cases}>
        {fields.map((field, index) => (
          <div key={field.id} className={styles.case}>
            <p>{field.label}</p>
            <span className={styles.delete} onClick={() => handleDelete(index, field.label)}>
              <AiOutlineDelete />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectEventCases;
