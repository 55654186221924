import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsCheck } from 'react-icons/bs';
import { formatISO, getDayOfYear } from 'date-fns';
import { TASK_PUT } from '../../../../actions/types';
import { updateTask } from '../../../../actions/task';
import Loader from '../../../Loader';
import styles from './update-task-form.module.scss';
import SelectTaskUser from '../SelectTaskUser';

import {
  loadOptionsTasks,
  // loadOptionsTransports,
} from '../../../../utils';

import {
  StyledSelect,
  DatePicker,
} from '../../../../lib/HooksFormFields';

const loadOptions = async () => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
    },
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/task/list`, config);
    const data = await response.json();
    return data.list;
  } catch (e) {
    return [];
  }
};

const UpdateTaskForm = ({ handleClose, task }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { authReducer, caseReducer } = useSelector((store) => store);
  const { isLoading } = caseReducer;
  const { user: { agencies }, selectedAgency } = authReducer;
  const [listOpts, setListOpts] = useState();
  const [optionsTask, setOptionsTask] = useState([]);
  // const [optionsTransport, setOptionsTransport] = useState([]);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {
      agency: { label: selectedAgency.name, value: selectedAgency._id },
    },
  });

  // INIT ASSIGNED TO VALUE
  useEffect(() => {
    if (!listOpts?.users) return;
    const assignedToID = (task?.assignedTo || []).map((user) => user._id);
    const defaultValueUsers = listOpts.users.filter((opt) => assignedToID.includes(opt.value));
    setValue('assignedTo', defaultValueUsers);
  }, [task, listOpts]);

  // INIT LINKED TASK VALUE
  useEffect(() => {
    const currentValue = getValues('linkedTask');
    if (!optionsTask.length || !task?.linkedTask?._id || currentValue) return;
    const defaultTask = optionsTask.find((opt) => opt.value === task.linkedTask._id);
    if (defaultTask) setValue('linkedTask', defaultTask);
  }, [task, optionsTask]);

  // INIT LINKED TRANSPORT VALUE
  // useEffect(() => {
  // const currentValue = getValues('linkedTransport');
  // if (!optionsTransport.length || !task?.linkedTransport?._id || currentValue) return;
  // const defaultTransport =
  // optionsTransport.find((opt) => opt.value === task.linkedTransport._id);
  // if (defaultTransport) setValue('linkedTransport', defaultTransport);
  // }, [task, optionsTransport]);

  useEffect(() => {
    loadOptionsTasks(task.caseId).then((opts) => {
      setOptionsTask(opts);
    });
    // loadOptionsTransports(task.caseId).then((opts) => {
    //   setOptionsTransport(opts);
    // });
  }, []);

  useEffect(() => {
    const getList = async () => {
      const list = await loadOptions();
      const options = {};
      Object.keys(list).forEach((key) => {
        const arr = list[key];
        const opts = arr.map((d) => {
          if (key === 'users') {
            return ({
              value: d._id,
              label: `${d.profile.firstName} ${d.profile.lastName}`,
              initial: `${d.profile.firstName[0]}${d.profile.lastName[0]}`,
              item: d,
            });
          }
          if (key === 'cases') {
            return ({ value: d._id, label: `${d.client} - #${d.ref}` });
          }
          return ({ value: d, label: d });
        });
        options[key] = opts;
      });
      setListOpts(options);
    };
    getList();
  }, []);

  function onSubmit(data) {
    const priority = data?.priority?.value || 'Basse';
    const status = task.status || 'Todo';
    const assignedTo = (data?.assignedTo || []).map((user) => user.value) || null;

    const payload = {
      _id: task._id,
      date: data.date ? formatISO(data.date) : null,
      priority,
      status,
      assignedTo,
      linkedTask: data?.linkedTask?.value || null,
      agency: data?.agency.value || selectedAgency._id,
      // linkedTransport: data?.linkedTransport?.value || null,
    };

    // SET INFO ABOUT CELL TO UPDATE POSITION
    if ((payload.date && payload.date !== task.date)
      || (payload.status !== task.status)) {
      payload.position = -1;
    }

    console.log(payload);
    updateTask(dispatch, payload).then(() => {
      if (data.date && data.date !== task.date) {
        const params = Object.fromEntries(searchParams.entries());
        params.day = getDayOfYear(data.date);
        setSearchParams(params);
      }
      handleClose();
    });
  }

  function handlePressEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  const optionsAgency = useMemo(() => (
    agencies.map((d) => ({ label: d.name, value: d._id }))
  ), [agencies]);

  return (
    <div onKeyDown={handlePressEnter} className={styles.form}>
      <h1>
        Attribution et planification<br /> de la tâche
        {task.number && <span> ({task.number})</span>}
      </h1>
      {listOpts && (
        <>
          <div className={styles.containerField}>
            <StyledSelect
              name="agency"
              control={control}
              label='Agence'
              placeholder="Choisir une agence"
              inline
              options={optionsAgency}
              rules={{
                required: true,
              }}
            />
          </div>
          <div className={styles.containerField}>
            <SelectTaskUser
              optsUser={listOpts.users}
              control={control}
              defaultValue={() => (
                listOpts.users.find((user) => user.value === task?.assignedTo?._id)
              )}
            />
          </div>
          <div className={styles.containerField}>
            <DatePicker
              name="date"
              control={control}
              label={'Planifier'}
              placeholder="Choisir une date"
              defaultValue={task.date ? new Date(task.date) : null}
              inline
            />
          </div>
          <div className={styles.containerField}>
            <StyledSelect
              name="priority"
              control={control}
              label={'Priorité'}
              placeholder="Choisir une priorité"
              inline
              defaultValue={() => (
                listOpts.priorities.find((priority) => priority.value === task?.priority)
              )}
              options={listOpts.priorities}
            />
          </div>
          {task?.type !== 'Préparation' && (
            <div className={styles.containerField}>
              <StyledSelect
                name="linkedTask"
                control={control}
                label={'Tâche associée'}
                placeholder="Choisir une tâche associée"
                inline
                isSearchable
                options={optionsTask}
              />
            </div>
          )}
          {/* {(optionsTransport.length > 0) && (
            <div className={styles.containerField}>
              <StyledSelect
                name="linkedTransport"
                control={control}
                label={'Transport associé'}
                placeholder="Choisir un transport associé"
                inline
                isSearchable
                options={optionsTransport}
              />
            </div>
          )} */}
          <div className={styles.center}>
            <button onClick={handleSubmit(onSubmit)}>
              {isLoading.includes(TASK_PUT)
                ? <span className='icon loader'><Loader size={14} className='secondary' /></span>
                : <span className='icon'><BsCheck size={'24px'} /></span>
              }
              <span> Valider </span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default UpdateTaskForm;
