import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { resetError } from '../../../../actions/auth';

import {
  InputEmail,
  InputPassword,
  ErrorField,
} from '../../../../lib/HooksFormFields';

import styles from './signin-form.module.scss';

const SignInForm = ({
  errorMessage,
  registerLink,
  submit,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchEmail = watch('email');
  const watchPassword = watch('password');

  useEffect(() => resetError(dispatch), []);

  useEffect(() => {
    if (errorMessage) resetError(dispatch);
  }, [watchEmail, watchPassword]);

  function onSubmit(data) {
    submit(data);
  }

  function handlePressEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['signin-form']}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          <InputEmail
            name="email"
            control={control}
            required
            label="Identifiant"
            placeholder="adressemail@mail.fr"
          />
          {errors.email && <ErrorField message={errors.email.message} />}
        </div>
        <div className={styles['container-field']}>
          <InputPassword
            name="password"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label="Mot de passe"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
          />
          {errors.password && <ErrorField message={errors.password.message} />}
        </div>
        {(!errors.email && !errors.password) && errorMessage
          && <p className={styles['message-error']}>
            {errorMessage}
          </p>
        }
      </div>
      {registerLink
        && <div className={styles['container-link']}>
          <NavLink to={registerLink}>Créer un compte</NavLink>
        </div>
      }
      <div className={styles.center}>
        <button onClick={handleSubmit(onSubmit)}>Connexion</button>
      </div>
    </div>
  );
};

SignInForm.propTypes = {
  submit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default SignInForm;
