import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';
import styles from './case-legend.module.scss';

const CaseLegend = () => {
  const searchReducer = useSelector((store) => store.searchReducer);
  const [isOpen, setIsOpen] = useState(false);
  const { list: { status } } = searchReducer;
  function getClassNameStatus(s) {
    let className = 'pending';
    switch (s) {
      case 'Litige':
        className = 'dispute';
        break;
      case 'En attente':
        className = 'pending';
        break;
      case 'En cours':
        className = 'in-progress';
        break;
      case 'Archivés':
        className = 'archived';
        break;
      default:
        className = 'pending';
        break;
    }
    return className;
  }
  return (
    <div className={styles.legend}>
      <div className={isOpen ? `${styles.header} ${styles.open}` : styles.header} onClick={() => setIsOpen((state) => !state)}>
        <p>Légende statut affaire</p>
        {isOpen ? <IoIosRemove /> : <IoIosAdd />}
      </div>
      {(status && isOpen) && (
        <ul>
          {status.map((d) => <li key={`status-${d.value}`}>
            <div className={`${styles.dot} ${styles[getClassNameStatus(d.label)]}`} />
            <p>
              {d.label}
            </p>
          </li>)}
        </ul>
      )}
    </div>
  );
};

export default CaseLegend;
