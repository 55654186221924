import React, { useState, useEffect } from 'react';
import { BiChevronUp, BiChevronDown } from 'react-icons/bi';
import styles from './header-list.module.scss';

const HeaderList = ({
  children,
  className,
  sortable = [],
  handleSort,
  sort,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // HANDLE CLOSE LIST
  useEffect(() => {
    const root = document.getElementById('root');
    const closeList = () => {
      if (isOpen) setIsOpen(false);
    };
    root.addEventListener('click', closeList);
    root.addEventListener('wheel', closeList);
    return () => {
      root.removeEventListener('wheel', closeList);
      root.removeEventListener('click', closeList);
    };
  }, [isOpen]);

  function getSelectedSort() {
    const find = children.find((child) => child.props?.itemProperty === sort.key);
    return (
      <p onClick={() => setIsOpen((state) => !state)}>
        <span>Trier par&nbsp;:&nbsp;</span>
        {find?.props?.title || 'null'}
        {sort.order === 'asc'
          ? <BiChevronUp />
          : <BiChevronDown />
        }
      </p>
    );
  }
  return (
    <>
      <div className={`${styles.sortMobile} ${className}`}>
        {getSelectedSort()}
        {isOpen && (
          <ul>
            {children.filter((d, i) => sortable[i]).map((child, i) => (
              <React.Fragment key={`sort-${i}`}>
                {(sort.key !== sortable[i] || sort.order !== 'asc') && (
                  <li
                    onClick={() => handleSort(sortable[i], 'asc')}
                    style={sortable[i] ? { cursor: 'pointer' } : {}}
                  >
                    {child} <BiChevronUp />
                  </li>
                )}
                {(sort.key !== sortable[i] || sort.order !== 'desc') && (
                  <li
                    key={`cell-${i}`}
                    onClick={() => handleSort(sortable[i], 'desc')}
                    style={sortable[i] ? { cursor: 'pointer' } : {}}
                  >
                    {child} <BiChevronDown />
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        )}
      </div>
      <div className={`${styles.header} ${className}`}>
        {children.map((child, i) => (
          <div
            key={`cell-${i}`}
            className={`${styles.cell}${sortable[i] === sort.key ? ` ${styles.selected}` : ''}`}
            style={sortable[i] ? { cursor: 'pointer' } : {}}
            onClick={sortable[i] ? () => handleSort(sortable[i]) : () => { }}
          >
            {child}
            {sortable[i] && (
              <div className={`${styles.sort} ${sortable[i] === sort.key ? ` ${styles[sort.order]}` : ''}`} >
                <BiChevronUp />
                <BiChevronDown />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default HeaderList;
