import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAdd } from 'react-icons/io';
import { SEARCH_GET } from '../../actions/types';
import { getSearch, getSearchList } from '../../actions/search';
import CaseFilters from '../../components/CaseFilters';
import TileFolder from '../../components/TileFolder';
import CasesList from '../../components/list/CasesList';
import CaseLegend from '../../components/CaseLegend';
import ModalSendEmail from '../../components/ModalSendEmail';
import ResultSearch from '../../components/ResultSearch';
import Loader from '../../components/Loader';
import noCaseImg from '../../assets/images/no-case.png';
import styles from './home.module.scss';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isOpened, setIsOpened] = useState(null);
  const [isSearchString, setIsSearchString] = useState(null);
  const [isFilterActive, setIsFilterActive] = useState(null);
  const [casesWIPorWaiting, setCasesWIPorWaiting] = useState([]);
  const [sendDocument, setSendDocument] = useState(null);
  const { searchReducer, authReducer } = useSelector((store) => store);
  const { selectedAgency, user: { rights = {} } } = authReducer;
  const {
    cases,
    files,
    list,
    isLoading,
  } = searchReducer;

  useEffect(() => {
    getSearchList(dispatch);
  }, [selectedAgency]);

  // HANDLE SEARCH WITH searchParams
  useEffect(() => {
    if (!Object.values(list).length) return;
    getSearch(dispatch, searchParams, list);
  }, [searchParams, list, selectedAgency]);

  // SET CASES WIP OR WAITING
  useEffect(() => {
    const casesFiltered = (cases || []).filter((c) => c.status === 'En cours' || c.status === 'En attente');
    setCasesWIPorWaiting(casesFiltered);
  }, [cases]);

  // INIT IS OPENED
  useEffect(() => {
    if (typeof isOpened === 'boolean') return;
    const params = new URLSearchParams(searchParams);
    const search = Object.fromEntries(params);
    const {
      users,
      status,
      clients,
      creationDate,
    } = search;
    const isSearchParams = Object.values({
      users,
      clients,
      status,
      creationDate,
    }).filter((d) => d).length > 0;
    setIsOpened(isSearchParams);
  }, [searchParams, isOpened]);

  // INIT SEARCH STRING
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const search = Object.fromEntries(params);
    const { search: searchTxt } = search;
    setIsSearchString(!!searchTxt);
  }, [searchParams]);

  // HANDLE IF CASE FILTERS ACTIVE
  // TWO CONDITIONS => IS OPEN OR IF THERE ARE A TEXT SEARCH
  useEffect(() => {
    setIsFilterActive(isOpened || isSearchString);
  }, [isOpened, isSearchString]);

  function getLastModifiedCases() {
    const lastModifiedCases = (cases || []).sort((a, b) => (
      new Date(b.updatedAt) - new Date(a.updatedAt)
    ));
    return lastModifiedCases.slice(0, 3);
  }

  return (
    <div className={styles.home}>
      <ModalSendEmail sendDocument={sendDocument} />
      <div className={styles.search}>
        <CaseFilters isOpened={isOpened} setIsOpened={setIsOpened} />
      </div>
      <div className={styles.container}>
        {(isLoading.includes(SEARCH_GET))
          && <div className={styles.loader}><Loader /></div>
        }
        {cases !== null && (
          <>
            {!isFilterActive ? (
              <div>
                {cases.length > 0
                  ? <>
                    <div className={styles.lastCases}>
                      <div className={styles.header}>
                        <h3>Derniers dossiers modifiés</h3>
                        <CaseLegend />
                      </div>
                      <ul className={styles.list}>
                        {getLastModifiedCases().map((c) => <li key={`tile-${c._id}`}>
                          <TileFolder
                            link={`/case/${c._id}`}
                            lastUpdate={c.updatedAt ? new Date(c.updatedAt) : new Date()}
                            name={c.client}
                            caseRef={c.ref}
                            commercialUser={{
                              firstName: c?.user?.profile?.firstName,
                              lastName: c?.user?.profile?.lastName,
                            }}
                            status={c.status}
                          />
                        </li>)}
                      </ul>
                    </div>
                    <div>
                      <div className={styles.header}>
                        <h3>Les affaires en cours & en attente</h3>
                      </div>
                      {casesWIPorWaiting.length > 0
                        ? <CasesList cases={casesWIPorWaiting} sendCase={(doc) => {
                          setSendDocument(doc);
                        }} />
                        : <p>Aucune affaire en cours ou en attente</p>
                      }
                    </div>
                  </>
                  : <div className={styles.noCase}>
                    <p>il n'y a pas encore d'affaires...</p>
                    {rights.write && (
                      <button className='secondary' onClick={() => navigate('/cases?createCase=1')}>
                        <span className='icon'><IoIosAdd size={'20px'} /></span>
                        <span>Créer une affaire</span>
                      </button>
                    )}
                    <img src={noCaseImg} alt="il n'y a pas encore d'affaires" />
                  </div>
                }

              </div>
            ) : (
              <div>
                <ResultSearch cases={cases} files={files} />
              </div>
            )}
        </>
        )}
      </div>
    </div >
  );
};

export default Home;
