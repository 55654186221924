import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';

const Modal = ({ children }) => {
  const modalRoot = document.getElementById('modal');
  const [pendingFunction, setPendingFunction] = useState({
    action: null,
    message: null,
  });

  function childrenWithProps() {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          confirm: (action, message) => setPendingFunction({
            action,
            message,
          }),
        });
      }
      return child;
    });
  }

  function confirmAction(bool) {
    if (bool) pendingFunction.action();
    setPendingFunction({
      action: null,
      message: null,
    });
  }

  return (
    <>
      {(pendingFunction.action && pendingFunction.message) && (
        <>
          {createPortal(
            <>
              <div className={styles['overlay-modal']} />
              <div className={styles.modal}>
                <p>{pendingFunction.message}</p>
                <div className={styles['container-btn']}>
                  <button onClick={() => confirmAction(true)}>
                    <span>Confirmer</span>
                  </button>
                  <button onClick={() => confirmAction(false)}>
                    <span>Annuler</span>
                  </button>
                </div>
              </div>
            </>,
            modalRoot,
          )}
        </>
      )}
      {childrenWithProps(children)}
    </>
  );
};

const withModalConfirm = (Component) => ({ ...props }) => (
  <Modal>
    <Component {...props} />
  </Modal>
);

export default withModalConfirm;
