import React, {
  useRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  format,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAdd } from 'react-icons/io';
import { TiWarning } from 'react-icons/ti';
import { HiOutlinePencil } from 'react-icons/hi';
import { useSearchParams } from 'react-router-dom';
import { selectTask, deleteTask } from '../../actions/task';
import ModalStandalone from '../../lib/ModalStandalone';
import withModalConfirm from '../../lib/withModalConfirm';
import UpdateTaskForm from '../form/TaskForm/UpdateTaskForm';
import styles from './task.module.scss';
import load from '../../assets/images/task-types/load.svg';
import unload from '../../assets/images/task-types/unload.svg';
import prepare from '../../assets/images/task-types/prepare.svg';
import review from '../../assets/images/task-types/review.svg';
import fileIcon from '../../assets/images/icons/file-blue.svg';
import fileYellowIcon from '../../assets/images/icons/file-yellow.svg';
import deleteIcon from '../../assets/images/icons/delete.svg';
import TaskArticles from './TaskArticles';

const Task = ({
  id,
  name,
  clientName,
  caseId,
  linkedTask,
  linkedTransport,
  type = 'Chargement',
  hasWarning = false,
  refCase = null,
  number = null,
  priority = 'moyenne',
  status = null,
  zone = null,
  assignement = null,
  date = null,
  index = 0,
  articles = [],
  files = [],
  confirm,
}) => {
  const dispatch = useDispatch();
  const modalArticlesRef = useRef();
  const modalRef = useRef();
  const taskRef = useRef();
  const modalImage = useRef();
  const { taskReducer, authReducer } = useSelector((store) => store);
  const { selectedTask } = taskReducer;
  const { user: { rights = {} } } = authReducer;
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedImage, setSelectedImage] = useState();

  function handleClose(e) {
    e.stopPropagation();
    selectTask(dispatch, null);
  }

  useEffect(() => {
    if (selectedImage) modalImage.current?.open();
  }, [selectedImage]);

  useEffect(() => {
    const root = document.getElementById('root');
    const closeTask = (e) => {
      if (selectedTask === id && !e.target.closest(`[data-task='${id}']`)) {
        selectTask(dispatch, null);
      }
    };
    root.addEventListener('click', closeTask);
    return () => {
      root.removeEventListener('click', closeTask);
    };
  }, [selectedTask]);

  function openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    modalRef.current.open();
  }

  async function download(fileId, filename) {
    const config = {
      headers: {
        Authorization: `${localStorage.getItem('token')}`,
      },
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/file/${fileId}`, config);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } catch (e) {
      console.log(e);
    }
  }

  function handleDelete() {
    const fnDelete = async () => {
      deleteTask(dispatch, id);
    };
    confirm(fnDelete, 'Êtes vous sûr de vouloir supprimer cette tâche ?');
  }

  // IF modalArticlesRef.current.open();
  // is call the modal with articles
  // will be open
  const openDetailTask = () => {
    // modalArticlesRef.current.open();
    const params = new URLSearchParams(searchParams);
    params.set('taskId', id);
    setSearchParams(params);
  };

  const previewImgUrl = useMemo(() => {
    const token = localStorage.getItem('token');
    return `${process.env.REACT_APP_API_URL}/static/${selectedImage?.path.replace('upload/', '')}?token=${token}`;
  }, [selectedImage]);

  const filterdArticles = useMemo(() => articles.filter((article) => (
    article.plannedQuantity
    || article.return.ok
    || article.return.damaged
    || article.return.hs
  )), [articles]);

  const enteredInSage = useMemo(() => {
    const bool = (
      status === 'Done'
      && (filterdArticles.length === 0 || filterdArticles.every((article) => article.checkedSage))
    );
    return !!bool;
  }, [filterdArticles]);

  const withAnomaly = useMemo(() => {
    const bool = filterdArticles.length && filterdArticles
      .some((article) => article.quantity !== article.plannedQuantity);
    return !!bool;
  }, [filterdArticles]);

  return (
    <>
      <ModalStandalone ref={modalArticlesRef}>
        <div className={styles.modalArticles}>
          <TaskArticles taskId={id} />
        </div>
      </ModalStandalone>
      {(rights.write) && (
        <ModalStandalone ref={modalRef}>
          <UpdateTaskForm
            handleClose={() => {
              modalRef?.current?.close();
            }}
            task={{
              _id: id,
              caseId,
              linkedTask,
              linkedTransport,
              assignedTo: assignement,
              status,
              type,
              priority,
              date,
              number,
            }} />
        </ModalStandalone>
      )}
      <ModalStandalone
        ref={modalImage}
        handleClose={() => setSelectedImage()}
      >
        <div className={styles.modalImage}>
          <img
            src={previewImgUrl}
          />
        </div>
      </ModalStandalone>
      <div
        className={
          `${styles.task}
          ${selectedTask === id ? ` ${styles.opened}` : ''}
          ${(enteredInSage && selectedTask !== id) ? ` ${styles.disabled}` : ''}`
        }
        ref={taskRef}
        style={{ height: '72px' }}
        onClick={() => selectTask(dispatch, id)}
        data-task={id}
      >
        <div className={`${styles.container}${index === 3 ? ` ${styles.right}` : ''}`}>
          {withAnomaly && <div className={styles.dot} />}
          {(selectedTask === id) && (
            <div className={styles.actions}>
              {(rights.write) && (
                <>
                  <div
                    className={styles.delete}
                    onClick={() => handleDelete()}
                  >
                    <img src={deleteIcon} alt="supprimer" />
                  </div>
                  <div
                    className={styles.edit}
                    onClick={() => modalRef.current.open()}
                  >
                    <HiOutlinePencil />
                  </div>
                </>
              )}
              <div
                className={styles.close}
                onClick={(e) => handleClose(e)}
              >
                <IoIosAdd size='24' />
              </div>
            </div>
          )}
          {type === 'Chargement' && <img src={load} alt='chargement' />}
          {type === 'Déchargement' && <img src={unload} alt='déchargement' />}
          {type === 'Préparation' && <img src={prepare} alt='préparation' />}
          {type === 'Retour' && <img src={review} alt='retour' />}
          <div className={styles.info}>
            <p className={styles.clientName}>
              {clientName}
              {number && <> / <span>{number}</span></>}
            </p>
            <p>
              {(!date && !name) && '---'}
              {date && <>{format(new Date(date), 'dd.MM.yy', { locale: fr })}</>}
              {name && <span> {name}</span>}
            </p>
            {refCase && <p>{`#${refCase}`}</p>}
            {selectedTask === id && (
              <div className={styles.details}>
                {zone && (
                  <div className={styles.location}>
                    <p>ZONE <span>{zone}</span></p>
                  </div>
                )}
                <a
                  className={styles.detailsArticles}
                  onClick={() => openDetailTask()}
                >
                  <img src={fileYellowIcon} className={styles.fileImg} alt={'articles'} />
                  <p>Voir le détail</p>
                </a>
                {files.length > 0 && (
                  <ul className={styles.files}>
                    {files.map((file) => (
                      <li key={`file-${file._id}`}>
                        <a onClick={() => {
                          if (file.mimetype === 'image/jpeg') {
                            setSelectedImage(file);
                          } else {
                            download(file._id, file.name);
                          }
                        }} >
                          <img src={fileIcon} className={styles.fileImg} alt={`${file.name}`} />
                          <p>{file.name}</p>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
          {(files.length > 0 && selectedTask !== id) && (
            <img src={fileIcon} className={styles.fileImg} alt={`bon de préparation ${clientName}`} />
          )}
          <div className={styles.containerAssignments}>
            <div className={styles.assignments}>
              {selectedTask === id ? (
                <>
                  {assignement && assignement.length ? (
                    <ul>
                      {assignement.map((user) => <li key={`${id}-${user._id}`}>
                        <div className={styles.initials}>
                          <p>
                            {user.profile.firstName[0]}
                            {user.profile.lastName[0]}
                          </p>
                        </div>
                        <p>
                          {user.profile.firstName}{' '}
                          {user.profile.lastName}
                        </p>
                      </li>)}
                    </ul>
                  ) : (
                    <div className={styles.notAssignment}>
                      <p>Ticket non attribué</p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {assignement && assignement.length ? (
                    <>
                      <div className={styles.buttonAssignment}>
                        <p>+</p>
                      </div>
                      {assignement.length === 1 ? (
                        <>
                          <div className={styles.initials}>
                            <p>
                              {assignement[0]?.profile.firstName[0]}{' '}
                              {assignement[0]?.profile.lastName[0]}
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className={styles.initials}>
                          <p>{assignement.length}</p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {(rights.write) && (
                        <div
                          className={styles.buttonNotAssignment}
                          onClick={(e) => openModal(e)}
                        >
                          <p >+</p>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {hasWarning && (
            <div className={styles.warning}>
              <TiWarning />
            </div>
          )}
          <div className={`${styles.priority} ${styles[priority.toLowerCase()]}`}></div>
        </div>
      </div>
    </>
  );
};

export default withModalConfirm(Task);
