import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IoIosAdd } from 'react-icons/io';
import { useFieldArray, useWatch } from 'react-hook-form';
import styles from './select-array-field.module.scss';

import {
  StyledSelect,
} from '../../../lib/HooksFormFields';

const SelectArrayField = ({
  name,
  control,
  setValue,
  options,
  item,
  placeholder,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const watchValue = useWatch({
    control, name: `select-${name}`,
  });

  useEffect(() => {
    if (watchValue) {
      const params = new URLSearchParams(searchParams);
      if (watchValue) {
        const commercials = params.get(name);
        const commercialsStr = `${commercials ? `${commercials},` : ''}${watchValue.value}`;
        params.set(name, commercialsStr);
      }
      setSearchParams(params);
      setValue(`select-${name}`, null);
      append(watchValue);
    }
  }, [watchValue]);

  function handleRemoveCommercial(id, index) {
    const params = new URLSearchParams(searchParams);
    const commercials = params.get(name);
    const commercialsStr = commercials.split(',').filter((d) => d !== id).join(',');
    if (commercialsStr) params.set(name, commercialsStr);
    else params.delete(name);
    setSearchParams(params);
    remove(index);
  }

  function filterOptions() {
    const selectedOptions = fields.map((d) => d.value);
    return (options || []).filter((opt) => !selectedOptions.includes(opt.value));
  }

  return (
    <div className={styles.containerField}>
      <StyledSelect
        name={`select-${name}`}
        control={control}
        value={null}
        placeholder={placeholder}
        options={filterOptions()}
        className='primary'
        isSearchable={false}
        icon={<span className={styles.selectIcon}><IoIosAdd /></span>}
      />
      {fields.length > 0 && (
        <div className={styles.items}>
          {fields.map((field, index) => item(field, index, handleRemoveCommercial))}
        </div>
      )}
    </div>
  );
};

export default SelectArrayField;
