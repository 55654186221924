import React, { useRef } from 'react';
import { IoIosAdd } from 'react-icons/io';
import Loader from '../Loader';

const ButtonUpload = ({ handleChangeFile, isLoading }) => {
  const inputRef = useRef();

  function handleClick() {
    inputRef.current.click();
  }

  function handleChange(e) {
    const fileUploaded = e.target.files[0];
    handleChangeFile(fileUploaded);
  }

  return (
    <>
      <button onClick={handleClick}>
        {isLoading
          ? <span className='icon loader'><Loader size={14} className='secondary' /></span>
          : <span className='icon'><IoIosAdd size={'24px'} /></span>
        }
        <span>Ajouter un document</span>
      </button>
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </>
  );
};

export default ButtonUpload;
