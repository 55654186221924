import {
  GET_GED_FOLDER,
  GED_FOLDER_POST,
  GED_FOLDER_DELETE,
  GED_FILE_DELETE,
  GET_GED_FOLDER_BY_ID,
  FILE_ADD,
  GED_LOADING,
  GED_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  isLoading: [],
  currentFolder: {},
  folders: [],
  files: [],
  error: null,
};

const gedReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  let updateCurrentFolder = state.currentFolder;
  switch (action.type) {
    case GET_GED_FOLDER:
      updatedState = {
        ...state,
        currentFolder: action.payload,
        files: [],
        isLoading: state.isLoading?.filter((d) => d !== GET_GED_FOLDER),
      };
      break;
    case GET_GED_FOLDER_BY_ID:
      updatedState = {
        ...state,
        currentFolder: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== GET_GED_FOLDER_BY_ID),
      };
      break;
    case GED_FOLDER_POST:
      updatedState = {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          folders: [action.payload, ...state.currentFolder.folders],
        },
        isLoading: state.isLoading?.filter((d) => d !== GED_FOLDER_POST),
        error: null,
      };
      break;
    case GED_FOLDER_DELETE:
      updatedState = {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          folders: state.currentFolder.folders.filter((folder) => folder._id !== action.payload),
        },
        isLoading: state.isLoading?.filter((d) => d !== GED_FOLDER_DELETE),
        error: null,
      };
      break;
    case GED_FILE_DELETE:
      updatedState = {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          files: state.currentFolder.files.filter((file) => (
            file._id !== action.payload
          )),
        },
        isLoading: state.isLoading?.filter((d) => d !== GED_FILE_DELETE),
        error: null,
      };
      break;
    case FILE_ADD:
      if (action.payload.folderId === updateCurrentFolder?._id) {
        updateCurrentFolder = {
          ...updateCurrentFolder,
          files: [
            ...action.payload.files,
            ...updateCurrentFolder.files,
          ],
        };
      }
      updatedState = {
        ...state,
        currentFolder: updateCurrentFolder,
        isLoading: state.isLoading?.filter((d) => d !== FILE_ADD),
        error: null,
      };
      break;
    case GED_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case GED_ERROR:
      updatedState = { ...state, error: action.payload };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default gedReducer;
