export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_SET_USER_AGENCY = 'AUTH_SET_USER_AGENCY';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';

// ACTIONS CASES
export const CASES_GET = 'CASES_GET';
export const CASE_FOLDER_GET = 'CASE_FOLDER_GET';
export const CASE_GET_BY_ID = 'CASE_GET_BY_ID';
export const CASES_GET_LIST = 'CASES_GET_LIST';
export const CASE_FOLDER_SET = 'CASE_FOLDER_SET';
export const CASE_POST = 'CASE_POST';
export const CASE_FOLDER_POST = 'CASE_FOLDER_POST';
export const CASE_FOLDER_DELETE = 'CASE_FOLDER_DELETE';
export const CASE_FILE_DELETE = 'CASE_FILE_DELETE';
export const CASE_PUT = 'CASE_PUT';
export const CASE_DELETE = 'CASE_DELETE';
export const CASE_LOADING = 'CASE_LOADING';
export const CASE_RESET_ERROR = 'CASE_RESET_ERROR';
export const CASE_ERROR = 'CASE_ERROR';

// ACTIONS SEARCH
export const SEARCH_GET = 'SEARCH_GET';
export const SEARCH_GET_LIST = 'SEARCH_GET_LIST';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SEARCH_ERROR = 'SEARCH_ERROR';

// ACTIONS FILES
export const FILE_ADD = 'FILE_ADD';
export const FILE_PUT_IN_FOLDER_ERROR = 'FILE_PUT_IN_FOLDER_ERROR';
export const FILE_ERROR = 'FILE_ERROR';
export const FILE_LOADING = 'FILE_LOADING';

// ACTIONS GED
export const GET_GED_FOLDER = 'GET_GED_FOLDER';
export const GET_GED_FOLDER_BY_ID = 'GET_GED_FOLDER_BY_ID';
export const GED_FOLDER_POST = 'GED_FOLDER_POST';
export const GET_FOLDER_PUT = 'GET_FOLDER_PUT';
export const GED_FOLDER_DELETE = 'GED_FOLDER_DELETE';
export const GED_FILE_DELETE = 'GED_FILE_DELETE';
export const GED_ERROR = 'GED_ERROR';
export const GED_LOADING = 'GED_LOADING';

// ACTIONS TASK
export const TASKS_GET = 'TASKS_GET';
export const TASK_SELECT = 'TASK_SELECT';
export const TASK_POST = 'TASK_POST';
export const TASK_PUT = 'TASK_PUT';
export const TASK_DELETE = 'TASK_DELETE';
export const TASKS_PUT = 'TASKS_PUT';
export const TASK_ERROR = 'TASK_ERROR';
export const TASK_LOADING = 'TASK_LOADING';

// ACTIONS EVENT
export const EVENTS_GET = 'EVENTS_GET';
export const EVENTS_GET_LIST = 'EVENTS_GET_LIST';
export const EVENT_SELECT = 'EVENT_SELECT';
export const EVENT_POST = 'EVENT_POST';
export const EVENT_PUT = 'EVENT_PUT';
export const EVENT_DELETE = 'EVENT_DELETE';
export const EVENT_LOADING = 'EVENT_LOADING';
export const EVENT_ERROR = 'EVENT_ERROR';
