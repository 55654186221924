import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { BsCheck } from 'react-icons/bs';
import { CASE_POST } from '../../../actions/types';
import { createFolderCase } from '../../../actions/case';
import { createGedFolder } from '../../../actions/ged';
import Loader from '../../Loader';
import styles from './create-folder.module.scss';

import {
  InputText,
  ErrorField,
} from '../../../lib/HooksFormFields';

const CreateFolder = ({
  handleClose,
  parent = null,
  caseId = null,
}) => {
  const dispatch = useDispatch();
  const caseReducer = useSelector((store) => store.caseReducer);
  const authReducer = useSelector((store) => store.authReducer);
  const { selectedAgency } = authReducer;
  const { isLoading } = caseReducer;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    const action = caseId ? createFolderCase : createGedFolder;
    action(dispatch, {
      name: data.name,
      agency: selectedAgency._id,
      case: caseId,
      files: [],
      parent,
    }).then(() => {
      handleClose();
    });
  }

  function handlePressEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div onKeyDown={handlePressEnter} className={styles.form}>
      <h1>Nouveau dossier</h1>
      <div className={styles.containerField}>
        <InputText
          name="name"
          control={control}
          rules={{ required: 'Veuillez renseigner le nom du dossier' }}
          placeholder="Nom du dossier"
          inline
        />
      </div>
      {!!Object.keys(errors).length && (
        ['name'].map((key) => (
          errors?.[key] ? <div className={styles.errorMessage}>
            <ErrorField key={`error-${key}`} message={errors?.[key].message} />
          </div> : null
        )).filter((d) => d)[0]
      )}
      <div className={styles.center}>
        <button onClick={handleSubmit(onSubmit)}>
          {isLoading.includes(CASE_POST)
            ? <span className='icon loader'><Loader size={14} className='secondary' /></span>
            : <span className='icon'><BsCheck size={'24px'} /></span>
          }
          <span> Valider </span>
        </button>
      </div>
    </div>
  );
};

export default CreateFolder;
