import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { resetError } from '../../../../actions/auth';

import {
  InputEmail,
  ErrorField,
} from '../../../../lib/HooksFormFields';

import styles from './emailresetpwd-form.module.scss';

const ResetPasswordForm = ({
  errorMessage,
  succeedMessage,
  signinLink,
  submit,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const watchEmail = watch('email');

  useEffect(() => resetError(dispatch), []);

  useEffect(() => {
    if (errorMessage) resetError(dispatch);
  }, [watchEmail]);

  function onSubmit(data) {
    submit(data);
  }

  function handlePressEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['emailresetpwd-form']}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          <InputEmail
            name="email"
            control={control}
            required
            label="Identifiant"
            placeholder="adressemail@mail.fr"
          />
          {errors.email && <ErrorField message={errors.email.message} />}
        </div>
        {(!errors.email) && errorMessage
          && <p className={styles['message-error']}>
            {errorMessage}
          </p>
        }
        {succeedMessage && (
          <p className={styles['message-succeed']}>{succeedMessage}</p>
        )}
      </div>
      {signinLink
        && <div className={styles['container-link']}>
          <NavLink to={signinLink}>Se connecter à mon compte</NavLink>
        </div>
      }
      <div className={styles.center}>
        <button onClick={handleSubmit(onSubmit)}>Valider</button>
      </div>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  submit: PropTypes.func.isRequired,
  signinLink: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default ResetPasswordForm;
