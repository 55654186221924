import slugify from 'slugify';

import {
  SEARCH_GET,
  SEARCH_GET_LIST,
  SEARCH_LOADING,
  CASE_POST,
  CASE_DELETE,
  SEARCH_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  cases: null,
  casesCount: null,
  files: null,
  list: {},
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const searchReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  switch (action.type) {
    case CASE_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        cases: [action.payload, ...state.cases],
        isLoading: state.isLoading?.filter((d) => d !== CASE_POST),
        error: null,
      };
      break;
    case CASE_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        cases: state.cases.filter((c) => (
          c._id !== action.payload.case._id)),
        isLoading: state.isLoading?.filter((d) => d !== CASE_DELETE),
        error: null,
      };
      break;
    case SEARCH_GET:
      updatedState = {
        ...state,
        casesCount: action.payload.casesCount,
        cases: action.payload.cases,
        files: action.payload.files,
        isLoading: state.isLoading?.filter((d) => d !== SEARCH_GET),
      };
      break;
    case SEARCH_GET_LIST:
      updatedState = {
        ...state,
        list: {
          users: (action.payload.users || []).map((user) => (
            {
              value: user._id,
              label: `${user.profile.firstName} ${user.profile.lastName}`,
              initials: `${user.profile.firstName[0]}${user.profile.lastName[0]}`,
            }
          )),
          status: (action.payload.status || []).map((status) => (
            { value: slugify(status, { lower: true }), label: status }
          )),
          clients: (action.payload.clients || []).map((client) => (
            { value: slugify(client, { lower: true }), label: client }
          )),
        },
        isLoading: state.isLoading?.filter((d) => d !== SEARCH_GET_LIST),
      };
      break;
    case SEARCH_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case SEARCH_ERROR:
      updatedState = { ...state };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default searchReducer;
