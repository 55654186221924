import React, { useMemo } from 'react';
import {
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { IoIosAdd } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { logout, setUserAgency } from '../../../actions/auth';
import BurgerIcon from '../BurgerIcon';
import styles from './navbar.module.scss';

import logo from '../../../assets/images/logo.png';
import iconHome from '../../../assets/images/icons/home.svg';
import iconHomeActive from '../../../assets/images/icons/home-active.svg';
import iconTransport from '../../../assets/images/icons/transport.svg';
import iconTransportActive from '../../../assets/images/icons/transport-active.svg';
import iconAgenda from '../../../assets/images/icons/agenda.svg';
import iconAgendaActive from '../../../assets/images/icons/agenda-active.svg';
import iconFolder from '../../../assets/images/icons/folder.svg';
import iconFolderActive from '../../../assets/images/icons/folder-active.svg';

import {
  StyledSelect,
} from '../../../lib/HooksFormFields';

const Navbar = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { authReducer, searchReducer } = useSelector((store) => store);

  const { user, user: { agencies, rights = {} }, selectedAgency } = authReducer;
  const { cases, casesCount } = searchReducer;
  const optsAgencies = useMemo(
    () => (agencies || []).map((agency) => ({ label: agency.name, value: agency._id })),
    [agencies],
  );

  const {
    control,
  } = useForm({
    defaultValues: {
      agency: optsAgencies.find((d) => d.value === selectedAgency?._id) || null,
    },
  });

  function handleChangeAgency(agency) {
    const found = agencies.find((d) => d._id === agency.value);
    setUserAgency(dispatch, found);
  }

  function getTitlePage() {
    let title = 'Accueil';
    switch (location.pathname) {
      case '/agencies':
        title = 'Agences';
        break;
      case '/transport':
        title = 'Transport';
        break;
      case '/parc':
        title = 'Parc';
        break;
      case '/affaires':
        title = 'Affaires';
        break;
      default:
        title = 'Accueil';
        break;
    }
    return title;
  }

  return (
    <div className={isOpen ? `${styles.navbar} ${styles.opened}` : styles.navbar}>
      <div className={styles['header-tablet']}>
        <h1>{getTitlePage()}</h1>
        <BurgerIcon isOpen={isOpen} handleClick={() => setIsOpen((state) => !state)} />
      </div>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles['select-agency']}>
        <StyledSelect
          name="agency"
          control={control}
          inline
          options={optsAgencies}
          handleChange={handleChangeAgency}
        />
      </div>
      {rights.write && (
        <div className={styles.containerButton}>
          <button className='secondary' onClick={() => navigate('/cases?createCase=1')}>
            <span className='icon'><IoIosAdd size={'20px'} /></span>
            <span>Créer une affaire</span>
          </button>
        </div>
      )}
      <ul>
        <li>
          <NavLink to='/home' className={({ isActive }) => (isActive ? styles.active : undefined)}>
            <span className={styles.icons}>{location.pathname === '/home' ? <img src={iconHomeActive} alt="accueil selectionné" /> : <img src={iconHome} alt="accueil" />}</span>
            <span>Accueil</span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/transport' className={({ isActive }) => (isActive ? styles.active : undefined)}>
            <span className={styles.icons}>{location.pathname === '/transport' ? <img src={iconTransportActive} alt="transport selectionné" /> : <img src={iconTransport} alt="transport" />}</span>
            <span>Transport</span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/parc' className={({ isActive }) => (isActive ? styles.active : undefined)}>
            <span className={styles.icons}>{location.pathname === '/parc' ? <img src={iconAgendaActive} alt="parc selectionné" /> : <img src={iconAgenda} alt="parc" />}</span>
            <span>Parc</span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/cases' className={({ isActive }) => (isActive ? styles.active : undefined)}>
            <span className={styles.icons}>{location.pathname === '/cases' ? <img src={iconFolderActive} alt="affaires selectionné" /> : <img src={iconFolder} alt="affaires" />}</span>
            <span>
              Affaire
              {(location.pathname === '/cases' && !!cases?.length) && `${cases?.length > 1 ? `s (${cases?.length}` : `(${cases?.length}`})`}
              {(location.pathname !== '/cases' && !!casesCount) && `${casesCount > 1 ? `s (${casesCount}` : `(${casesCount}`})`}
            </span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/ged' className={({ isActive }) => (isActive ? styles.active : undefined)}>
            <span className={styles.icons}>{location.pathname === '/ged' ? <img src={iconFolderActive} alt="ged libre selectionné" /> : <img src={iconFolder} alt="ged libre" />}</span>
            <span>GED libre</span>
          </NavLink>
        </li>
      </ul>
      <div className={styles.profile}>
        <span>
          <div className={styles.initials}>
            <span>
              {user.profile.firstName[0]}
              {user.profile.lastName[0]}
            </span>
          </div>
          <p>Mon profil</p>
        </span>
        <span className={styles.logout} onClick={() => logout(dispatch)}>
          <FiLogOut />
        </span>
      </div>
    </div>
  );
};

export default Navbar;
