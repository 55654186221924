import {
  postDataFormData,
  putData,
} from './index';
import {
  FILE_ADD,
  FILE_PUT_IN_FOLDER_ERROR,
  FILE_ERROR,
  FILE_LOADING,
} from './types';

export const uploadFileInFolder = async (dispatch, data, folder) => {
  let res = null;
  let error = null;

  dispatch({
    type: FILE_LOADING,
    payload: FILE_ADD,
  });

  try {
    const urlPostFile = '/file';
    const response = await postDataFormData(FILE_ERROR, true, urlPostFile, dispatch, data);

    const urlPutFolder = `/folder/${folder._id}`;
    const response2 = await putData(FILE_PUT_IN_FOLDER_ERROR, urlPutFolder, dispatch, {
      files: folder.files ? [
        ...folder.files.map((file) => file._id),
        ...response.data.files.map((file) => file._id),
      ] : response.data.files.map((file) => file._id),
    }, true);
    res = response2;
    dispatch({
      type: FILE_ADD,
      payload: {
        folderId: folder._id,
        files: response.data.files,
      },
    });
  } catch (err) {
    console.log(err);
    error = err;
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
};

export const uploadFile = async (data) => {
  let error = null;
  let res = null;

  try {
    const urlPostFile = '/file';
    res = await postDataFormData(null, true, urlPostFile, null, data);
  } catch (err) {
    console.log(err);
    error = err;
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
};
