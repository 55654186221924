import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { BsCheck } from 'react-icons/bs';
import { CASE_POST, CASE_PUT } from '../../../actions/types';
import { createCase, updateCase } from '../../../actions/case';
import Loader from '../../Loader';
import styles from './case-form.module.scss';

import {
  InputText,
  StyledSelect,
  ErrorField,
} from '../../../lib/HooksFormFields';

const CaseForm = ({ handleClose, dataCase }) => {
  const dispatch = useDispatch();
  const caseReducer = useSelector((store) => store.caseReducer);
  const authReducer = useSelector((store) => store.authReducer);
  const searchReducer = useSelector((store) => store.searchReducer);
  const { selectedAgency } = authReducer;
  const { isLoading, error } = caseReducer;
  const { list } = searchReducer;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [userOpts, setUserOpts] = useState([]);

  useEffect(() => {
    if (!list) return;
    setUserOpts(list.users);
  }, [list]);

  async function onSubmit(data) {
    const { user: { value }, ...rest } = data;
    if (!dataCase) {
      const res = await createCase(dispatch, {
        status: 'En cours',
        agency: selectedAgency._id,
        user: value,
        ...rest,
      });
      if (res?.status === 200) {
        handleClose();
      }
    } else if (dataCase) {
      updateCase(dispatch, {
        _id: dataCase._id,
        agency: selectedAgency._id,
        user: value,
        ...rest,
      }).then(() => {
        handleClose();
      });
    }
  }

  function handlePressEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div onKeyDown={handlePressEnter} className={styles.form}>
      <h1>
        {dataCase ? 'Modifier l\'affaire' : 'Créer une affaire'}
      </h1>
      <div className={styles.containerField}>
        <InputText
          name="ref"
          control={control}
          defaultValue={dataCase?.ref || ''}
          label={'Numéro d’affaire'}
          rules={{ required: 'Veuillez renseigner le numéro d’affaire' }}
          placeholder="Numéro d’affaire"
          inline
        />
      </div>
      <div className={styles.containerField}>
        <InputText
          name="client"
          control={control}
          defaultValue={dataCase?.client || ''}
          label={'Client'}
          rules={{ required: 'Veuillez renseigner le client' }}
          placeholder="Nom du client"
          inline
        />
      </div>
      <div className={styles.containerField}>
        <StyledSelect
          name="user"
          control={control}
          label={'Affectation'}
          placeholder="Personne"
          inline
          rules={{ required: 'Veuillez renseigner l\'affectation' }}
          defaultValue={dataCase?.user
            ? list.users.find((opt) => opt.value === dataCase.user._id)
            : null
          }
          options={userOpts}
        />
      </div>
      {!!Object.keys(errors).length && (
        ['ref', 'client', 'user'].map((key) => (
          errors?.[key] ? <div className={styles.errorMessage}>
            <ErrorField key={`error-${key}`} message={errors?.[key].message} />
          </div> : null
        )).filter((d) => d)[0]
      )}
      {error === 'That case ref is already in use.' && (
        <div className={styles.errorMessage}>
          <ErrorField key={'error-api'} message={'La référence indiquée est déjà utilisée'} />
        </div>
      )}
      <div className={styles.center}>
        <button onClick={handleSubmit(onSubmit)}>
          {isLoading.includes(CASE_POST) || isLoading.includes(CASE_PUT)
            ? <span className='icon loader'><Loader size={14} className='secondary' /></span>
            : <span className='icon'><BsCheck size={'24px'} /></span>
          }
          <span> Valider </span>
        </button>
      </div>
    </div>
  );
};

export default CaseForm;
