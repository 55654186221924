import { useRef, useEffect } from 'react';

export default function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  useEffect(() => () => {
    ref.current = null;
  }, []);

  return ref.current;
}
