import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import Loader from '../Loader';
import styles from './file-upload.module.scss';

const fileTypes = ['JPG', 'JPEG', 'PNG', 'GIF'];

const FileUploaderComponent = ({ handleChangeFile, file, isLoading }) => (
  <FileUploader
  handleChange={file ? () => { } : handleChangeFile}
  name="file"
  types={fileTypes}
  classes={styles.inputFile}
  hoverTitle={'Déposer le fichier pour l\'importer'}
  onTypeError={(e) => console.log(e)}
  onSizeError={(e) => console.log(e)}
>
  <div className={styles.dropZone}>
    {isLoading
      ? <div>
        <Loader />
      </div>
      : <p>Vous pouvez ajouter des documents ici</p>
    }
  </div>
</FileUploader>
);

export default FileUploaderComponent;
