import {
  CASE_GET_BY_ID,
  CASE_FOLDER_GET,
  CASE_FOLDER_SET,
  CASE_FOLDER_POST,
  CASE_FOLDER_DELETE,
  CASE_FILE_DELETE,
  FILE_ADD,
  CASE_PUT,
  CASE_RESET_ERROR,
  CASE_LOADING,
  CASE_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  cases: [],
  currentFolder: null,
  selectedCase: null,
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const caseReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  const updateCases = state.cases || [];
  let updateCurrentFolder = state.currentFolder;
  const caseIndex = (updateCases || []).findIndex((p) => (
    p._id === action.payload?.case?._id
  ));
  switch (action.type) {
    case CASE_GET_BY_ID:
      updatedState = {
        ...state,
        selectedCase: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== CASE_GET_BY_ID),
        error: null,
      };
      break;
    case CASE_FOLDER_POST:
      // eslint-disable-next-line no-case-declarations
      const currentFolderFolders = state.currentFolder.folders || [];
      updatedState = {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          folders: [action.payload, ...currentFolderFolders],
        },
        isLoading: state.isLoading?.filter((d) => d !== CASE_FOLDER_POST),
        error: null,
      };
      if (action.payload.parent === null && action.payload.case === state.selectedCase._id) {
        updatedState.selectedCase = {
          ...updatedState.selectedCase,
          folders: [
            action.payload,
            ...updatedState.selectedCase.folders,
          ],
        };
      }
      break;
    case CASE_FOLDER_DELETE:
      updatedState = {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          folders: state.currentFolder.folders.filter((folder) => (
            folder._id !== action.payload
          )),
        },
        isLoading: state.isLoading?.filter((d) => d !== CASE_FOLDER_DELETE),
        error: null,
      };
      updatedState.selectedCase = {
        ...updatedState.selectedCase,
        folders: state.selectedCase.folders.filter((folder) => (
          folder._id !== action.payload
        )),
      };
      break;
    case CASE_FILE_DELETE:
      updatedState = {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          files: state.currentFolder.files.filter((file) => (
            file._id !== action.payload
          )),
        },
        isLoading: state.isLoading?.filter((d) => d !== CASE_FILE_DELETE),
        error: null,
      };
      break;
    case CASE_FOLDER_SET:
      updatedState = {
        ...state,
        currentFolder: action.payload,
        error: null,
      };
      break;
    case FILE_ADD:
      if (action.payload.folderId === updateCurrentFolder?._id) {
        updateCurrentFolder = {
          ...updateCurrentFolder,
          files: [
            ...action.payload.files,
            ...updateCurrentFolder.files,
          ],
        };
      }
      updatedState = {
        ...state,
        currentFolder: updateCurrentFolder,
        isLoading: state.isLoading?.filter((d) => d !== FILE_ADD),
      };
      break;
    case CASE_FOLDER_GET:
      updatedState = {
        ...state,
        currentFolder: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== CASE_FOLDER_GET),
        error: null,
      };
      break;
    case CASE_PUT:
      if (typeof caseIndex === 'number') {
        updateCases[caseIndex] = action.payload.case;
      }
      updatedState = {
        ...state,
        changedAt: Date.now(),
        cases: updateCases,
        selectedCase: state.selectedCase._id === action.payload.case._id
          ? action.payload.case
          : state.selectedCase,
        isLoading: state.isLoading?.filter((d) => d !== CASE_PUT),
        error: null,
      };
      break;
    case CASE_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case CASE_RESET_ERROR:
      updatedState = {
        ...state,
        error: null,
        isLoading: [],
      };
      break;
    case CASE_ERROR:
      updatedState = {
        ...state,
        error: action.payload.data.error,
        isLoading: [],
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default caseReducer;
