import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from 'react';
import axios from 'axios';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  HiOutlinePencil, HiOutlineDuplicate, HiArrowSmUp, HiArrowSmDown,
} from 'react-icons/hi';
import { FaComment } from 'react-icons/fa';
import { IoIosAdd } from 'react-icons/io';
import { BsCheck } from 'react-icons/bs';
import { MdAddTask } from 'react-icons/md';
import { TiWarning } from 'react-icons/ti';
import { Draggable } from 'react-beautiful-dnd';
import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';

import { createEvent } from '../../actions/event';
import { createTask, updateTask } from '../../utils';

import SelectTaskUser from '../form/TaskForm/SelectTaskUser';
import PositionalModal from '../../lib/PositionalModal';
import ModalStandalone from '../../lib/ModalStandalone';
import { StyledSelect } from '../../lib/HooksFormFields';

import load from '../../assets/images/task-types/load.svg';
import unload from '../../assets/images/task-types/unload.svg';
import prepare from '../../assets/images/task-types/prepare.svg';
import review from '../../assets/images/task-types/review.svg';
import file from '../../assets/images/icons/file-grey.svg';
import styles from './transport-event.module.scss';

const WrapperHover = ({
  className,
  notAssign,
  children,
}) => {
  const modalRoot = document.getElementById('modal');
  const [isHover, setIsHover] = useState(false);
  const [actived, setActived] = useState(false);
  const [bound, setBound] = useState({});
  const timer = useRef();

  useEffect(() => {
    if (isHover) {
      timer.current = setTimeout(() => {
        setActived(true);
      }, 600);
    } else if (!isHover) {
      setActived(false);
      if (timer.current) clearInterval(timer.current);
    }

    return () => {
      if (!isHover && timer.current) clearInterval(timer.current);
    };
  }, [isHover]);

  const handleHover = (ref, bool) => {
    if (notAssign) return;
    const targetBound = ref.current.getBoundingClientRect();
    setBound(bool ? targetBound : {});
    if (!bool) setActived(false);
    setIsHover(bool);
  };

  return (
    <>
      <>
        {children(false, handleHover)}
      </>
      {(!notAssign && actived) && createPortal(
        <div
          className={className}
          style={{
            position: 'fixed',
            zIndex: 1000,
            top: bound.top || 0,
            left: bound.left || 0,
            width: bound.width || 200,
            height: 'auto',
            pointerEvents: 'none',
          }}
        >
          {children(true)}
        </div>,
        modalRoot,
      )}
    </>

  );
};

const TransportEvent = ({
  id,
  index,
  agency = null,
  number = null,
  startDate = null,
  endDate = null,
  deliveryDate,
  deliveryLocationCity,
  clients = [],
  carrier = null,
  direction = null,
  linkedTasks = [],
  transport = [],
  showTransport = false,
  type = null,
  trunkType = null,
  commentIntern = '',
  hasWarning = false,
  showComments = false,
  showIconComments = false,
  steps = [],
  indexInGroup = 0,
  countGroup = 1,
  zIndex = 100,
  maxWidth,
  notAssign = false,
}) => {
  const { user: { rights = {} } } = useSelector((store) => store).authReducer;
  const { list } = useSelector((store) => store).eventReducer;
  const [searchParams, setSearchParams] = useSearchParams();
  const modalRef = useRef();
  const modalParent = useRef();
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    if (!startDate || !endDate) return;
    const distance = new Date(endDate).getTime() - new Date(startDate).getTime();
    const halfHourCount = (distance / (1800 * 1000));
    setHeight((halfHourCount * 25) + halfHourCount);
  }, [startDate, endDate]);

  function getClass() {
    let className = styles.event;
    if (direction === 'departure') className += ` ${styles.checkOut}`;
    if (direction === 'return') className += ` ${styles.checkIn}`;
    return className;
  }

  function getHours() {
    const date = new Date(startDate);
    let startHours = date.getHours();
    let startMinutes = date.getMinutes();
    startMinutes = startMinutes < 30 ? '00' : '30';
    startHours = startHours < 10 ? `0${startHours}:${startMinutes}` : `${startHours}:${startMinutes}`;
    return startHours;
  }

  function openEventDetail(e) {
    e.stopPropagation();
    e.preventDefault();
    const params = new URLSearchParams(searchParams);
    params.set('id', id);
    setSearchParams(params);
  }

  function openTaskModal(e) {
    e.stopPropagation();
    e.preventDefault();
    if (e.detail === 2) {
      if (!rights.write) return;
      openEventDetail(e);
    } else if (modalRef.current) {
      modalRef.current.open();
    }
  }

  function getStyle(providedDraggable, snapshotDraggable) {
    if (notAssign) {
      return ({
        ...providedDraggable.draggableProps.style,
      });
    }
    const style = {
      position: 'relative',
      zIndex: 100 + zIndex,
      width: `${100 / countGroup}%`,
      maxWidth: `${100 / countGroup}%`,
      marginLeft: 0,
      ...providedDraggable.draggableProps.style,
    };
    if (!snapshotDraggable.isDragging) {
      style.transform = null;
      style.marginLeft = index === 0 && indexInGroup > 0 ? `${(indexInGroup) * (100 / countGroup)}%` : 0;
    } else {
      style.width = maxWidth;
      style.maxWith = maxWidth;
    }

    return style;
  }

  const className = useMemo(() => {
    let res = `${styles.containerEvent}${notAssign ? ` ${styles.notAssign}` : ''}`;
    if (type?.value === 'TRANSPORT TERMINÉ') res += ` ${styles.done}`;
    return res;
  }, [notAssign, type]);

  const getStyleHeight = (actived) => {
    const style = {};
    if (!actived) style.height = height;
    if (notAssign) style.height = 62;
    return style;
  };

  return (
    <>
      {(!notAssign)
        && <ModalTransportEvent
          usersTasks={list.usersTasks}
          modalRef={modalRef}
          modalParent={modalParent}
          id={id}
          agency={agency}
          clients={clients}
          startDate={startDate}
          carrier={carrier}
          type={type}
          trunkType={trunkType}
          transport={transport}
          commentIntern={commentIntern}
          direction={direction}
          steps={steps}
          linkedTasks={linkedTasks}
          openEventDetail={(e) => openEventDetail(e)}
        />}
      <Draggable
        key={id}
        draggableId={id}
        index={index}
        isDragDisabled={!rights.write}
      >
        {(providedDraggable, snapshotDraggable) => (
          <div
            className={className}
            ref={providedDraggable.innerRef}
            {...providedDraggable.draggableProps}
            {...providedDraggable.dragHandleProps}
            style={notAssign
              ? {}
              : getStyle(providedDraggable, snapshotDraggable)}
            onClick={(e) => openTaskModal(e)}
          >
            <WrapperHover
              className={className}
              notAssign={notAssign}
            >
              {(actived, handleHover) => (
                <div
                  ref={!actived ? modalParent : null}
                  className={getClass()}
                  style={getStyleHeight(actived)}
                  onMouseEnter={() => handleHover(modalParent, true)}
                  onMouseLeave={() => handleHover(modalParent, false)}
                >
                <div className={styles.background} style={{ background: type ? type.color : '' }} />
                <div className={styles.border} style={{ background: type ? type.color : '' }} />
                <div className={styles.content}>
                  <div>
                    {clients.length > 0 ? (
                      <p className={styles.client}>{clients.filter((d) => d).map((d) => (
                          <React.Fragment key={`client-${d._id}`}>
                              {direction === 'departure' && (
                                <HiArrowSmUp />
                              )}
                              {direction === 'return' && (
                                <HiArrowSmDown />
                              )}
                            {d.client}{d.name && (
                              <> / <span>{d.name}</span></>
                            )}<br />
                            {d.ref}{number && (
                              <span className={styles.linkedTaskNumber}>
                                <img src={file} alt={number} />
                                {` ${number}`}
                              </span>)
                            }
                            <br />
                          </React.Fragment>
                      ))}
                      </p>
                    ) : (
                      <>
                      <p className={styles.client}>
                        {direction === 'departure' && (
                          <HiArrowSmUp />
                        )}
                        {direction === 'return' && (
                          <HiArrowSmDown />
                        )}
                        Aucune affaire associée
                        <br />
                        <br />
                      </p>
                      </>
                    )}

                    {/* <p className={styles.client}>
                      {clients.map((d) => (d.ref)).join(' / ')}
                    </p> */}

                    <p>
                      {(notAssign && deliveryDate) && (
                        <>{format(new Date(deliveryDate), 'dd.MM.yyyy', { locale: fr })}</>
                      )}
                      {!notAssign && <>{getHours()}</>}
                      {(deliveryLocationCity) && (
                        <>{(!notAssign || deliveryDate) && '/'} {deliveryLocationCity}</>
                      )}
                    </p>
                  </div>
                  {((transport || trunkType) && showTransport) && (
                    <div className={styles.transport}>
                      {transport && (
                        <p>Transport: <span>{transport}</span></p>
                      )}
                      {trunkType && (
                        <p>Type de camion: <span>{trunkType}</span></p>
                      )}
                    </div>
                  )}
                  {(showComments && commentIntern) && (
                    <div className={styles.comments}>
                      {commentIntern && (
                        <div>
                          <h5>Commentaire interne :</h5>
                          <p>{commentIntern}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {steps.length > 0 && (
                  <ul className={styles.steps}>
                    {steps.map((step, i) => (
                      <li key={`step-${i}`} >
                        <div className={`${styles.dot}${step.isComplete ? ` ${styles.done}` : ''}`}></div>
                      </li>
                    ))}
                  </ul>
                )}
                {(showIconComments || hasWarning) && (
                  <div className={styles.icons}>
                    {showIconComments && (
                      <div className={styles.comment}>
                        <FaComment />
                      </div>
                    )}
                    {hasWarning && (
                      <div className={styles.warning}>
                        <TiWarning />
                      </div>
                    )}
                  </div>
                )}
              </div>
              )}
            </WrapperHover>
          </div>
        )}
      </Draggable>
    </>
  );
};

const ModalTransportEvent = ({
  id,
  agency,
  clients,
  modalRef,
  modalParent,
  usersTasks,
  startDate,
  carrier,
  type,
  direction,
  transport,
  trunkType,
  commentIntern = '',
  steps,
  linkedTasks,
  openEventDetail,
}) => {
  const dispatch = useDispatch();
  const { user: { rights = {} } } = useSelector((store) => store).authReducer;
  const eventReducer = useSelector((store) => store.eventReducer);
  const { events } = eventReducer;
  const createTaskRef = useRef();
  const [errorTasks, setErrorTasks] = useState();
  const { control, getValues } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [optsTasks, setOptsTasks] = useState([]);
  useEffect(() => {
    if (direction !== 'return' || !isOpen) return;
    const getTask = async () => {
      const token = localStorage.getItem('token');
      const currentAgency = localStorage.getItem('agency') || null;
      const params = {
        type: 'Déchargement',
      };
      const ref = clients?.[0]?.ref;
      if (ref) {
        params.search = ref;
      }
      const queryString = new URLSearchParams(params).toString();

      const url = `${process.env.REACT_APP_API_URL}/task${queryString ? `?${queryString}` : ''}`;
      const res = await axios.get(
        url,
        { headers: { Authorization: `${token}`, agency: currentAgency } },
      );
      const tasks = (res?.data?.tasks || []).map((d) => ({
        value: d._id,
        label: `${d.number} - ${d.case.client}`,
        assignedTo: (d?.assignedTo || []).map((t) => t._id),
      }));
      setOptsTasks(tasks);
    };
    getTask();
  }, [direction, isOpen]);

  function handleModalClose(e) {
    e.stopPropagation();
    e.preventDefault();
    modalRef.current.close();
  }

  async function openEventDuplicate(e) {
    e.stopPropagation();
    e.preventDefault();
    const {
      _id,
      __v,
      updatedAt,
      createdAt,
      ...duplicateEvent
    } = events.find((d) => d._id === id);
    duplicateEvent.cases = duplicateEvent.cases.map((d) => d._id);
    const res = await createEvent(dispatch, duplicateEvent);
    if (res.statusText === 'OK') {
      modalRef.current.close();
    }
  }

  const handleCreateTask = (e) => {
    e.stopPropagation();
    e.preventDefault();
    createTaskRef.current.open();
    setIsOpen(true);
  };

  const createTasks = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    let linkedPreparedTask = null;
    if (direction === 'departure') {
      linkedPreparedTask = linkedTasks.find((d) => d.type === 'Préparation')?._id || null;
    }
    const assignedTo = (getValues('assignedTo') || []).map((user) => user.value) || null;
    const tasks = clients.map((client) => (
      new Promise((resolve) => {
        createTask({
          type: direction === 'departure' ? 'Chargement' : 'Déchargement',
          date: startDate,
          priority: 'Basse',
          status: 'Todo',
          assignedTo,
          case: client._id,
          agency,
          linkedTask: linkedPreparedTask,
          linkedTransport: id,
        }).then((res) => resolve(res));
      })
    ));
    Promise.all(tasks).then((responses) => {
      if (responses.every((res) => res.status === 200)) {
        createTaskRef.current.close();
        setIsOpen(false);
      } else {
        setErrorTasks('Une erreur s\'est produite lors de la création des tâches associèes.');
        console.log(responses.filter((res) => res.status !== 200));
      }
    });
  };

  // For direction return
  const updateTasks = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const assignedTo = (getValues('assignedTo') || []).map((d) => d.value) || [];
    const taskId = getValues('selectedTask')?.value || null;
    const task = optsTasks.find((d) => d.value === taskId);

    if (assignedTo.length > 0 && taskId) {
      try {
        await updateTask(
          {
            assignedTo: [
              ...task.assignedTo,
              assignedTo,
            ],
          },
          taskId,
        );
        setIsOpen(false);
        createTaskRef.current.close();
      } catch (err) {
        console.log(err);
        setErrorTasks('Une erreur s\'est produite pour associer la tâche.');
      }
    }
  };

  return (
    <>
      <ModalStandalone ref={createTaskRef} handleClose={() => setIsOpen(false)}>
        <div className={styles.modalTask} onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}>
          {direction === 'departure' && (
            <>
              {clients.length > 1 ? (
                <p>Voulez-vous créer les tâches de chargement correspondantes ?</p>
              ) : (
                <p>Voulez-vous créer la tâche de chargement correspondante ?</p>
              )}
            </>
          )}
          {direction === 'return' && (
            <>
              {clients.length > 1 ? (
                <p>Attribuer une tâche de déchargement</p>
              ) : (
                <p>Attribuer une tâche de déchargement</p>
              )}
              <div className={styles.containerField} style={{ marginBottom: '10px' }}>
                <StyledSelect
                  name='selectedTask'
                  control={control}
                  label={'Tâche'}
                  options={optsTasks}
                  isSearchable={false}
                  inline
                />
              </div>
            </>
          )}

          <div className={styles.containerField}>
            <SelectTaskUser
              optsUser={usersTasks || []}
              control={control}
            />
          </div>
          {errorTasks && (
           <p className={styles.errorMessage}>
              {errorTasks}
            </p>
          )}

          <button onClick={(e) => {
            if (direction === 'return') {
              updateTasks(e);
            } else {
              createTasks(e);
            }
          }}>
            <span className='icon'><BsCheck size={'24px'} /></span>
            <span> Valider</span>
          </button>
        </div>
      </ModalStandalone>
      <PositionalModal
        ref={modalRef}
        parent={modalParent}
        alignmentX={'middle'}
        alignmentY={'bottom'}
      >
        <div className={styles.modal} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          <div className={styles.container}>
            <div className={styles.actions}>
              {(rights.write) && (
                <>
                  <div
                    className={styles.edit}
                    onClick={(e) => openEventDetail(e)}
                  >
                    <HiOutlinePencil />
                  </div>
                  <div
                    className={styles.duplicate}
                    onClick={(e) => openEventDuplicate(e)}
                  >
                    <HiOutlineDuplicate />
                  </div>
                </>
              )}
              <div
                className={styles.close}
                onClick={(e) => handleModalClose(e)}
              >
                <IoIosAdd size='24' />
              </div>
            </div>
            <div className={carrier ? styles.detail : `${styles.detail} ${styles.form}`}>
              <div className={styles.type} style={{ background: type ? type.color : '' }}>
                {direction === 'departure' && (
                  <HiArrowSmUp />
                )}
                {direction === 'return' && (
                  <HiArrowSmDown />
                )}
              </div>
              <div>
                <p className={styles.date}>{format(new Date(startDate), 'eeee dd LLLL kk:mm', { locale: fr })}</p>
                <div className={styles.clients}>
                  {clients.map((d) => (
                    <div className={styles.client} key={`client-${d._id}`}>
                      <h5>
                        {d.client}
                        {d.name && <span> / {d.name}</span>}
                      </h5>
                      {(d?.user?.profile || d?.user?.profile?.tel) && (
                        <>
                          <p>
                            Contact chantier nom du client / Nom du chargé d’affaire
                          </p>
                          <ul>
                            <li>
                              {(d?.user?.profile && (
                                `${d?.user?.profile?.lastName} ${d?.user?.profile?.firstName}`
                              )) }
                              {d?.user?.profile?.tel && ` - ${d?.user?.profile?.tel}`}
                            </li>
                          </ul>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {carrier?.name && (
                  <div className={styles.carrier}>
                    <p>Transports: {carrier?.name}</p>
                    {(type?.label || trunkType) && (
                      <ul>
                        <li>
                          {type?.label || ''}
                          {(type?.label && trunkType) && ' - '}
                          {trunkType || ''}
                        </li>
                      </ul>
                    )}
                  </div>
                )}
              </div>
              {(transport || trunkType) && (
                <div className={styles.transport}>
                  {transport && (
                    <p>Transport: <span>{transport}</span></p>
                  )}
                  {trunkType && (
                    <p>Type de camion: <span>{trunkType}</span></p>
                  )}
                </div>
              )}
              {(commentIntern) && (
                <div className={styles.comments}>
                  {commentIntern && (
                    <div>
                      <h5>Commentaire interne :</h5>
                      <p>{commentIntern}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
            {steps.length > 0 && (
              <div className={styles.steps}>
                <ul>
                  {steps.map((step, i) => (
                    <li key={`step-${i}`} >
                      <span className={step.isComplete ? styles.done : ''} >
                        {step.value === 'Chargement' && <img src={load} alt='chargement' />}
                        {step.value === 'Déchargement' && <img src={unload} alt='déchargement' />}
                        {step.value === 'Préparation' && <img src={prepare} alt='préparation' />}
                        {step.value === 'Retour' && <img src={review} alt='retour' />}
                        {step.value}
                      </span>
                      {(rights.write && (step.value === 'Préparation' || step.value === 'Retour')) && (
                        <button
                          title="Créer tâche(s) associée(s)"
                          onClick={(e) => handleCreateTask(e)}
                        >
                          <MdAddTask size={30}/>
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </PositionalModal >
    </>
  );
};

export default TransportEvent;
