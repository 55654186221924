import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from '../styled-select.module.scss';
import { styleBase, stylePrimary, styleDark } from '../style';

const StyledSelect = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  icon = null,
  className,
  isClearable = false,
  isSearchable = false,
  highlight = false,
  defaultValue,
  handleChange,
  ...props
}) => {
  function getClassName() {
    let style;
    if (className === 'primary') style = stylePrimary;
    else if (className === 'dark') style = styleDark;
    else style = styleBase;
    return style;
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <span className={highlight ? styles.highlight : ''}>
            <Select
              {...field}
              {...props}
              onChange={(value) => {
                field.onChange(value);
                if (handleChange) handleChange(value);
              }}
              defaultValue={defaultValue}
              isClearable={isClearable}
              isSearchable={isSearchable}
              components={{
                DropdownIndicator: (p) => (icon || components.DropdownIndicator(p)),
              }}
              className={`${styles.select}`}
              placeholder={placeholder}
              styles={getClassName()}
            />
          </span>
        </div>
      )}
    />
  );
};

StyledSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default StyledSelect;
