import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { BsCheck } from 'react-icons/bs';
import { updateTask } from '../../../actions/task';
import { InputText } from '../../../lib/HooksFormFields';
import Loader from '../../Loader';
import load from '../../../assets/images/task-types/load.svg';
import unload from '../../../assets/images/task-types/unload.svg';
import prepare from '../../../assets/images/task-types/prepare.svg';
import review from '../../../assets/images/task-types/review.svg';
import styles from './task-articles.module.scss';

const TaskArticles = ({
  taskId,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const { taskReducer, authReducer } = useSelector((store) => store);
  const { user: { rights = {} } } = authReducer;
  const { tasks, changedAt, isLoading } = taskReducer;
  const [task, setTask] = useState({});
  const {
    control,
    setValue,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      numberRetour: '',
      purchaseOrder: '',
    },
  });

  const watchNumberRetour = watch('numberRetour');
  const watchPurchaseOrder = watch('purchaseOrder');

  useEffect(() => {
    const found = (tasks || []).find((t) => t._id === taskId);
    if (found) {
      setTask(found);
      if (found?.type === 'Retour') {
        setValue('numberRetour', found?.numberRetour || '');
      }
      if (found?.type === 'Préparation') {
        setValue('purchaseOrder', found?.purchaseOrder || '');
      }
    }
  }, [tasks, taskId, changedAt]);

  const toggleCheckbox = async (articleId) => {
    await updateTask(dispatch, {
      ...task,
      articles: task.articles.map((article) => {
        const checkedSage = article._id === articleId ? !article.checkedSage : article.checkedSage;
        return { ...article, checkedSage };
      }),
    });
  };

  const submitNumber = async (data) => {
    const dataNumber = {};
    if (task?.type === 'Retour') {
      dataNumber.numberRetour = data.numberRetour;
    }
    if (task?.type === 'Préparation') {
      dataNumber.purchaseOrder = data.purchaseOrder;
    }
    await updateTask(dispatch, {
      _id: task._id,
      ...dataNumber,
    });
  };

  const arrArticles = useMemo(() => {
    let arr = [];
    const { articles = [] } = task;
    arr = articles.filter((article) => (
      article.plannedQuantity
      || article.return.ok
      || article.return.damaged
      || article.return.hs
    ));
    // if (type === 'Retour') {
    //   arr = [];
    //   articles.forEach((article) => {
    //     if (article.return.ok) {
    //       arr.push({
    //         ...article,
    //         _id: `ok-${article._id}`,
    //         quantity: article.return.ok,
    //         status: 'ok',
    //       });
    //     }
    //     if (article.return.damaged) {
    //       arr.push({
    //         ...article,
    //         _id: `damaged-${article._id}`,
    //         quantity: article.return.damaged,
    //         status: 'damaged',
    //       });
    //     }
    //     if (article.return.hs) {
    //       arr.push({
    //         ...article,
    //         _id: `hs-${article._id}`,
    //         quantity: article.return.hs,
    //         status: 'hs',
    //       });
    //     }
    //   });
    // } else {
    //   arr = articles;
    // }
    return arr;
  }, [task]);

  const isTaskLoading = useMemo(() => (
    isLoading.some((d) => /^TASK_PUT+.*/.test(d))
  ), [isLoading]);

  const getBasketLabels = useCallback((key) => {
    const labels = {
      t0pan050: 'Panier IPN',
      t0pantol: 'Panier Tole/Grillage',
      t0pantte: 'Panier Tole/Grill. TE (petit)',
      e0pantre: 'Panier Treillis',
      e0pandem: 'Panier Démontable',
      t2pantoe: 'Panier Tour échelle',
      t2pantom: 'Panier tour échelle avec barre',
      t0pacr50: 'Panier crémaillère pr 0.50m',
      d3panier: 'Panier Dalutec sans roue',
      d9panrou: 'Panier Dalutec avec roue',
    };
    return labels[key];
  }, []);

  const getBasketRefs = useCallback((key) => {
    const labels = {
      t0pan050: 'TOPAN050_L',
      t0pantol: 'TOPANTOL_L',
      t0pantte: 'TOPANTTE_L',
      e0pantre: 'E0PANTRE_L',
      e0pandem: 'E0PANDEM_L',
      t2pantoe: 'T2PANTOE_L',
      t2pantom: 'T2PANTOM_L',
      t0pacr50: 'T0PACR50_L',
      d3panier: 'D3PANIER_L',
      d9panrou: 'D9PANROU_L',
    };
    return labels[key];
  }, []);

  // const inputs = Object.entries((task?.baskets || {})).map(([key, value]) => {

  return (
    <div className={styles.modalArticles}>
    <div className={styles.header}>
      {task?.type === 'Chargement' && <img src={load} alt='chargement' />}
      {task?.type === 'Déchargement' && <img src={unload} alt='déchargement' />}
      {task?.type === 'Préparation' && <img src={prepare} alt='préparation' />}
      {task?.type === 'Retour' && <img src={review} alt='retour' />}
      {(task?.case?.ref && task?.case?.name) && (
        <div>
          <p className={styles.client}>
          {task?.case?.client} {task?.number && <>/ <span>{task?.number}</span></>}
          </p>
          <p>
            {(!task?.date && !task?.case?.name) && '---'}
            {task?.date && <>{format(new Date(task?.date), 'dd.MM.yy', { locale: fr })}</>}
            {task?.case?.name && <span> {task?.case?.name}</span>}
          </p>
          <p>{`#${task?.case?.ref}`}</p>
        </div>
      )}
    </div>
    <div>
      <div className={styles.baskets}>
        {Object.entries((task?.baskets || {})).map(([key, value]) => (
          <div className={styles.basket} key={`basket-${key}`}>
            <p><span>{getBasketRefs(key)}</span> <label>{getBasketLabels(key)}</label> : {value}</p>
          </div>
        ))}
      </div>
      {(rights.write) && (
        <>
          {(task?.type === 'Retour') && (
            <div className={styles.fieldNumber}>
              <InputText
                name='numberRetour'
                control={control}
                placeholder='N° LRS...'
              />
              <button
                onClick={handleSubmit(submitNumber)}
                disabled={!watchNumberRetour}
              >
                Enregistrer n° LRS
              </button>
            </div>
          )}
          {(task?.type === 'Préparation') && (
            <div className={styles.fieldNumber}>
              <InputText
                name='purchaseOrder'
                control={control}
                placeholder='N° du bon de livraison...'
              />
              <button
                onClick={handleSubmit(submitNumber)}
                disabled={!watchPurchaseOrder}
              >
                Enregistrer n° du bon de livraison
              </button>
            </div>
          )}
        </>
      )}
      <ul className={!isTaskLoading ? styles.articles : `${styles.articles} ${styles.loading}`}>
        {isTaskLoading && <Loader />}
        {arrArticles.map((article) => (
          <li key={`article-${article._id}`}>
            <div className={styles.content}>
              <div className={article?.substitutes?.length > 0 ? `${styles.article} ${styles.withSubtitutes}` : styles.article}>
                <p className={styles.ref}>{article.ref}</p>
                <div className={styles.infosArticle}>
                  <p className={styles.articleName}>
                    <span>{article.label}</span>
                    {article?.unit && <span>{article.unit}</span>}
                  </p>
                  <div className={styles.quantity}>
                    {article.return.hs ? (
                      <div className={styles.contentQuantity}>
                        <span className={`${styles.icon} ${styles.hs}`}>
                          <span>{article.return.hs}</span>
                        </span>
                      </div>
                    ) : ''}
                    {article.return.damaged ? (
                      <div className={styles.contentQuantity}>
                        <span className={`${styles.icon} ${styles.damaged}`}>
                          <span>{article.return.damaged}</span>
                        </span>
                      </div>
                    ) : ''}
                    {article.return.ok ? (
                      <div className={styles.contentQuantity}>
                        <span className={`${styles.icon} ${styles.ok}`}>
                          <span>{article.return.ok}</span>
                        </span>
                      </div>
                    ) : ''}
                    {task?.type === 'Retour' ? (
                      <div className={styles.plannedQuantity}>
                        <p>
                          <span>{article.quantity}</span>
                        </p>
                      </div>
                    ) : (
                      <div className={styles.plannedQuantity}>
                        <p>
                          <span>{article.checked ? article.quantity : 0}</span>
                          <>/ {article.plannedQuantity}</>
                          {/* {!article.status && <>/ {article.plannedQuantity}</>} */}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {(article.return.damagedComment || article.return.hsComment) && (
                  <div className={styles.comments}>
                    {article.return.damagedComment && (
                      <p className={`${styles.comment} ${styles.damaged}`}>
                        <span>{article.return.damagedComment}</span>
                      </p>
                    )}
                    {article.return.hsComment && (
                      <p className={`${styles.comment} ${styles.hs}`}>
                        <span>{article.return.hsComment}</span>
                      </p>
                    )}
                  </div>
                )}
              </div>
              {article?.substitutes?.length > 0 && (
                <>
                  <h3>
                    Produit{article?.substitutes.length > 1 && 's'} de substitution
                  </h3>
                  <div className={styles.substitutes}>
                  {article?.substitutes.map((substitute) => (
                    <div className={styles.substitute} key={`substitute-${substitute._id}`}>
                      <div className={styles.quantity}>
                        <p>
                          {substitute.quantity} -&nbsp;
                        </p>
                      </div>
                      <div>
                        <p className={styles.substituteName} >{substitute.label}</p>
                        {substitute.comment && (
                          <p className={styles.comment}>{substitute.comment}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                </>
              )}
            </div>
            <Checkbox
              checked={article?.checkedSage}
              handleClick={() => toggleCheckbox(article._id)}
              isDisabled={!rights.write}
            />
          </li>
        ))}
      </ul>
    </div>
    <div className={styles.containerButton}>
      <button onClick={() => handleClose()}>Fermer le détail</button>
    </div>
  </div>
  );
};

const Checkbox = (({ checked, handleClick, isDisabled = false }) => {
  useEffect(() => {}, []);
  return (
    <div className={`${styles.checkbox} ${isDisabled ? styles.disabled : ''}`} onClick={() => {
      if (!isDisabled) handleClick();
    }}>
      {checked && <BsCheck />}
    </div>
  );
});

export default TaskArticles;
