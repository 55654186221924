/* eslint-disable max-len */
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IoIosAdd } from 'react-icons/io';
import { FaFileExport } from 'react-icons/fa';

import {
  getGedFolders,
  getGedFolder,
  updateGedFolder,
  deleteGedFolder,
  deleteGedFile,
} from '../../actions/ged';
import { uploadFileInFolder } from '../../actions/file';
import { GET_GED_FOLDER, GET_GED_FOLDER_BY_ID, FILE_ADD } from '../../actions/types';

import usePrevious from '../../hooks/usePrevious';
import Loader from '../../components/Loader';
import ButtonUpload from '../../components/ButtonUpload';
import TileFolder from '../../components/TileFolder';
import FoldersList from '../../components/list/FoldersList';
import FilesList from '../../components/list/FilesList';
import ModalStandalone from '../../lib/ModalStandalone';
import ModalSendEmail from '../../components/ModalSendEmail';
import CreateFolder from '../../components/form/CreateFolder';
import ExportFolders from '../../components/form/ExportFolders';
import FileUploader from '../../components/FileUploader';

import styles from './ged.module.scss';

import {
  InputText,
  ErrorField,
} from '../../lib/HooksFormFields';

const loadOptionsCase = async () => {
  const config = {
    method: 'POST',
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      status: ['Litige', 'En attente', 'En cours'],
    }),
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/case/search`, config);
    const data = await response.json();
    const opts = data?.cases.map((c) => ({
      status: c.status,
      label: `#${c.ref} - ${c.client}`,
      value: c._id,
    }));
    return opts;
  } catch (e) {
    return [];
  }
};

const Ged = () => {
  const dispatch = useDispatch();
  const modalCreateFolder = useRef();
  const modalprintFolders = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const { authReducer, gedReducer, fileReducer } = useSelector((store) => store);
  const { error: errorFile, isLoading: isLoadingFile } = fileReducer;
  const { isLoading, currentFolder } = gedReducer;
  const { selectedAgency, user: { rights = {} } } = authReducer;
  const [folderId, setFolderId] = useState(false);
  const [file, setFile] = useState(null);
  const [optsMove, setOptsMove] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [sendDocuments, setSendDocuments] = useState(null);
  const fileList = useRef();

  console.log(rights);

  const {
    control,
    watch,
    reset,
  } = useForm();

  const watchSearch = watch('search');
  const prevSearch = usePrevious(watchSearch);

  useEffect(() => {
    const getOpstsMoveToCases = async () => {
      const cases = await loadOptionsCase();
      setOptsMove(cases);
    };
    getOpstsMoveToCases();
  }, [selectedAgency]);

  // HANDLE GET FOLDER ACCORDING TO SEARCH PARAMS
  useEffect(() => {
    const p = new URLSearchParams(searchParams);
    const paramsFolderId = p.get('folder');
    setFolderId(paramsFolderId || null);
    if (paramsFolderId) {
      getGedFolder(dispatch, paramsFolderId);
    } else {
      getGedFolders(dispatch);
    }
  }, [selectedAgency, searchParams.get('folder')]);

  // INIT SEARCH VALUE
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const valueSearch = params.get('search');
    if (valueSearch) reset({ search: valueSearch });
  }, []);

  // SET SEARCH PARAMS DEPENDING ON INPUTS VALUE
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    if (watchSearch) params.set('search', watchSearch);
    else if (prevSearch && !watchSearch) params.delete('search');
    setSearchParams(params);
  }, [watchSearch]);

  async function handleChangeFile(fileValue) {
    const formData = new FormData();
    formData.append('file', fileValue);
    formData.append('title', fileValue.name);
    setFile(fileValue);
    await uploadFileInFolder(dispatch, formData, currentFolder);
    setFile(null);
  }

  function handleSelectionChange(selectionFiles) {
    setSelectedFiles(selectionFiles);
  }

  function handleSend() {
    setSendDocuments(selectedFiles);
  }

  function openFolder(id) {
    const p = new URLSearchParams(searchParams);
    p.set('folder', id);
    p.delete('search');
    setSearchParams(p);
  }

  // function getLastModifiedFolders() {
  //   const lastModifiedCases = (currentFolder.folders || []).sort((a, b) => (
  //     new Date(b.updatedAt) - new Date(a.updatedAt)
  //   ));
  //   return lastModifiedCases.slice(0, 3);
  // }

  const filteredFolders = useMemo(() => {
    const searchValue = searchParams.get('search')?.toLowerCase() || '';
    const filtered = (currentFolder.folders || []).filter((d) => !searchValue || d.name.toLowerCase().includes(searchValue));
    const sorted = filtered.sort((a, b) => (
      new Date(b.updatedAt) - new Date(a.updatedAt)
    ));
    return sorted;
  }, [currentFolder.folders, searchParams.get('search')]);

  const lastModifiedFolders = useMemo(() => (
    filteredFolders.slice(0, 3)
  ), [filteredFolders]);

  const restFolders = useMemo(() => (
    filteredFolders.slice(folderId ? 0 : 3, filteredFolders.length)
  ), [filteredFolders, folderId]);

  function getBreadcrumbTrail(folder) {
    const links = [<NavLink key='root-ged' to={'/ged'}>...</NavLink>, ' / '];
    const minSlice = folder.parents.length - 4 || 0;
    folder.parents.slice(minSlice, folder.parents.length).forEach((f, i) => {
      links.push(
        <NavLink key={`folder-${f._id}`} to={`/ged?folder=${f._id}`}>
          {f.name || '...'}
        </NavLink>,
      );
      if (i < folder.parents.length) links.push(' / ');
    });
    return links;
  }

  return (
    <>
      <ModalSendEmail
        sendDocuments={sendDocuments}
        handleClose={() => {
          setSendDocuments(null);
        }}
        handleSuccess={() => {
          fileList.current.resetSelection();
          setSendDocuments(null);
          setSelectedFiles([]);
        }}
      />
      <ModalStandalone ref={modalCreateFolder}>
        <CreateFolder
          handleClose={() => modalCreateFolder.current.close()}
          parent={folderId}
        />
      </ModalStandalone>
      <ModalStandalone ref={modalprintFolders}>
        <ExportFolders
          folderId={currentFolder._id}
        />
      </ModalStandalone>
      <div className={styles.ged}>
        <div className={styles.container}>
          {(isLoading.includes(GET_GED_FOLDER)
            || isLoading.includes(GET_GED_FOLDER_BY_ID)) && <Loader />}
          {((!isLoading.includes(GET_GED_FOLDER)
            && !isLoading.includes(GET_GED_FOLDER_BY_ID)) && currentFolder.folders) && (
              <>
                {!folderId ? (
                  <>
                    <div className={styles.search}>
                      <InputText
                        name="search"
                        control={control}
                        placeholder="Rechercher..."
                        className='primary'
                      />
                    </div>
                    <div className={styles.lastFolders}>
                      <div className={styles.header}>
                        <h3>Derniers dossiers Modifiés</h3>
                        {(rights.write) && (
                          <button onClick={() => modalCreateFolder.current.open()}>
                            <span className='icon'><IoIosAdd size='20px' /></span>
                            <span>Créer un dossier</span>
                          </button>
                        )}
                      </div>
                      <ul className={styles.list}>
                        {lastModifiedFolders.map((c) => <li key={`tile-${c._id}`}>
                          <TileFolder
                            link={`/ged?folder=${c._id}`}
                            lastUpdate={c.updatedAt ? new Date(c.updatedAt) : new Date()}
                            name={c.name}
                            id={c._id}
                            initOptsMove={optsMove}
                            actions={{
                              move: updateGedFolder,
                              rename: updateGedFolder,
                              download: true,
                              delete: (folder) => deleteGedFolder(dispatch, folder),
                            }}
                          />
                        </li>)}
                      </ul>
                      {watchSearch && lastModifiedFolders.length === 0 && (
                        <p className={styles.noResult}>Aucun dossier ne correspond à cette recherche.</p>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.header}>
                      <h1>
                        {currentFolder.name}
                      </h1>
                      <span className={styles.fields}>
                        <button
                          className={styles.export}
                          onClick={() => modalprintFolders.current.open()}
                        ><FaFileExport /></button>
                      </span>
                    </div>
                    <div className={styles.breadcrumbTrail}>
                      <p>
                        {getBreadcrumbTrail(currentFolder)}
                        <span> {currentFolder.name}</span></p>
                    </div>
                    <div className={styles.files}>
                      <div className={styles.header}>
                        <h3>Documents</h3>
                        <div className={styles.containerButtons}>
                          {selectedFiles.length > 0 && (
                            <button
                              className={`third ${styles.btnSend}`}
                              onClick={() => handleSend()}
                            >
                              Envoyer par email
                            </button>
                          )}
                          {(rights.write) && (
                            <ButtonUpload
                              isLoading={isLoadingFile.includes(FILE_ADD)}
                              handleChangeFile={handleChangeFile}
                            />
                          )}
                        </div>
                      </div>
                      {errorFile && (
                        <div className={styles.errorMessage}>
                          <ErrorField message={errorFile} />
                        </div>
                      )}
                      {currentFolder.files?.length
                        ? <div className={styles.listFiles}>
                          <FilesList
                            ref={fileList}
                            handleSelectionChange={handleSelectionChange}
                            files={currentFolder.files}
                            actions={{
                              print: true,
                              send: true,
                              delete: (f) => deleteGedFile(dispatch, f),
                            }}
                          />
                        </div>
                        : <div className={file ? `${styles.containerInputField} ${styles.disabled}` : styles.containerInputField}>
                          {(rights.write) && (
                            <FileUploader
                              handleChangeFile={file ? () => { } : handleChangeFile}
                              isLoading={isLoadingFile.includes(FILE_ADD)}
                              file={file}
                            />
                          )}
                        </div>
                      }
                    </div>
                    <div className={styles.header}>
                      <h3>Dossier{currentFolder.folders?.length > 1 && 's'}</h3>
                      {(rights.write) && (
                        <button onClick={() => modalCreateFolder.current.open()}>
                          <span className='icon'><IoIosAdd size='20px' /></span>
                          <span>Créer un dossier</span>
                        </button>
                      )}
                    </div>
                  </>
                )}
                <FoldersList
                  folders={restFolders}
                  handleClickFolder={openFolder}
                  initOptsMove={optsMove}
                  actions={{
                    move: updateGedFolder,
                    rename: updateGedFolder,
                    download: true,
                    delete: (folder) => deleteGedFolder(dispatch, folder),
                  }}
                />
              </>
          )}
        </div>
      </div>
    </>
  );
};

export default Ged;
