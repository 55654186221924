import {
  getData,
  postData,
  putData,
  deleteData,
} from './index';
import {
  EVENTS_GET,
  EVENTS_GET_LIST,
  EVENT_SELECT,
  EVENT_POST,
  EVENT_PUT,
  EVENT_DELETE,
  EVENT_LOADING,
  EVENT_ERROR,
} from './types';

export const selectEvent = async (dispatch, eventId) => {
  dispatch({
    type: EVENT_SELECT,
    payload: eventId,
  });
};

export const getEvents = async (dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/transport${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: EVENT_LOADING,
    payload: EVENTS_GET,
  });
  const response = await getData(EVENT_ERROR, url, dispatch, true);
  if (response.data?.transports) {
    setTimeout(() => {
      dispatch({
        type: EVENTS_GET,
        payload: response.data?.transports,
      });
    }, 500);
  }
};

export const getListEvent = async (dispatch) => {
  const url = '/transport/list';
  dispatch({
    type: EVENT_LOADING,
    payload: EVENTS_GET_LIST,
  });
  const response = await getData(EVENT_ERROR, url, dispatch, true);
  if (response.data?.list) {
    const { list } = response.data;
    const options = {};
    Object.keys(list).forEach((key) => {
      const arr = list[key];
      const opts = arr.filter((d) => d).map((d) => {
        if (key === 'cases') {
          return ({ value: d._id, label: `${d.client} - #${d.ref}${d.name ? ` - ${d.name}` : ''}` });
        }
        if (key === 'users') {
          return ({ value: d._id, label: `${d.profile.firstName} ${d.profile.lastName}` });
        }
        if (['directions', 'trunkTypes', 'types', 'carriers', 'buyers', 'agencies', 'usersTasks'].includes(key)) {
          return d;
        }
        return ({ value: d, label: d });
      });
      options[key] = opts;
    });

    setTimeout(() => {
      dispatch({
        type: EVENTS_GET_LIST,
        payload: options,
      });
    }, 500);
  }
};

export const createEvent = async (dispatch, data) => {
  const url = '/transport';
  dispatch({
    type: EVENT_LOADING,
    payload: EVENT_POST,
  });
  const response = await postData(EVENT_ERROR, url, dispatch, data, true);
  if (response.data?.transport) {
    dispatch({
      type: EVENT_POST,
      payload: response.data.transport,
    });
  }
  return response;
};

export const updateEvent = async (dispatch, data) => {
  const url = `/transport/${data._id}`;
  const { _id, ...updatedEvent } = data;
  dispatch({
    type: EVENT_LOADING,
    payload: `${EVENT_PUT}-${data._id}`,
  });
  const response = await putData(EVENT_ERROR, url, dispatch, updatedEvent, true);
  if (response.data.transport) {
    setTimeout(() => {
      dispatch({
        type: EVENT_PUT,
        payload: {
          ...response.data,
          event: response.data.transport,
        },
      });
    }, 600);
  }
  return response;
};

export const deleteEvent = async (dispatch, data) => {
  const url = `/transport/${data._id}`;
  dispatch({
    type: EVENT_LOADING,
    payload: EVENT_DELETE,
  });
  const response = await deleteData(EVENT_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: EVENT_DELETE,
      payload: data._id,
    });
  }
  return response;
};
