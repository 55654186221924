import React, { useState } from 'react';
import Navbar from './Navbar';

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      {children}
    </>
  );
};

export default Layout;
