import {
  FILE_ADD,
  FILE_PUT_IN_FOLDER_ERROR,
  FILE_ERROR,
  FILE_LOADING,
} from '../actions/types';

const DEFAULT_STATE = {
  cases: null,
  files: null,
  list: {},
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const fileReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  switch (action.type) {
    case FILE_ADD:
      updatedState = {
        ...state,
        error: null,
        isLoading: state.isLoading?.filter((d) => d !== FILE_ADD),
      };
      break;
    case FILE_LOADING:
      updatedState = { ...state, error: null, isLoading: [...state.isLoading, action.payload] };
      break;
    case FILE_PUT_IN_FOLDER_ERROR:
      updatedState = { ...state, error: 'Le fichier n\'a pas pu être ajouter au dossier' };
      break;
    case FILE_ERROR:
      updatedState = { ...state, error: 'L\'upload du fichier a échoué' };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default fileReducer;
