import { combineReducers, createStore } from 'redux';
import authReducer from './authReducer';
import caseReducer from './caseReducer';
import taskReducer from './taskReducer';
import eventReducer from './eventReducer';
import searchReducer from './searchReducer';
import fileReducer from './fileReducer';
import gedReducer from './gedReducer';

const rootReducer = combineReducers({
  authReducer,
  caseReducer,
  searchReducer,
  fileReducer,
  eventReducer,
  taskReducer,
  gedReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
