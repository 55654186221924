import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { formatISO, getDayOfYear } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { BsCheck } from 'react-icons/bs';
import { TASK_POST, TASK_PUT } from '../../../../actions/types';
import { createTask } from '../../../../actions/task';
import Loader from '../../../Loader';
import SelectTaskUser from '../SelectTaskUser';
import styles from './create-task-form.module.scss';

import { loadOptionsTasks, loadOptionsTransports } from '../../../../utils';

import {
  StyledSelect,
  DatePicker,
  ErrorField,
} from '../../../../lib/HooksFormFields';

const loadOptions = async () => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
    },
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/task/list`, config);
    const data = await response.json();
    return data.list;
  } catch (e) {
    return [];
  }
};

const CreateTaskForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { caseReducer, authReducer } = useSelector((store) => store);
  const { selectedAgency } = authReducer;
  const { isLoading } = caseReducer;
  const [listOpts, setListOpts] = useState();
  const [optionsTask, setOptionsTask] = useState([]);
  const [optionsTransport, setOptionsTransport] = useState([]);
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const currentCaseId = watch('case');
  const currentType = watch('type');

  useEffect(() => {
    const getList = async () => {
      const list = await loadOptions();
      const options = {};
      Object.keys(list).forEach((key) => {
        const arr = list[key];
        const opts = arr.map((d) => {
          if (key === 'users') {
            return ({
              value: d._id,
              label: `${d.profile.firstName} ${d.profile.lastName}`,
              initial: `${d.profile.firstName[0]}${d.profile.lastName[0]}`,
              item: d,
            });
          }
          if (key === 'cases') {
            return ({ value: d._id, label: `${d.client} - #${d.ref}` });
          }
          return ({ value: d, label: d });
        });
        options[key] = opts;
      });
      setListOpts(options);
    };
    getList();
  }, []);

  useEffect(() => {
    if ((currentType?.value === 'Préparation'
      || currentType?.value === 'Déchargement')
      && getValues('linkedTask')
    ) {
      setValue('linkedTask', null);
    }
  }, [currentType]);

  useEffect(() => {
    if (!currentCaseId?.value) return;
    loadOptionsTasks(currentCaseId?.value).then((opts) => {
      setOptionsTask(opts);
    });

    loadOptionsTransports(currentCaseId?.value).then((opts) => {
      setOptionsTransport(opts);
    });
  }, [currentCaseId]);

  function onSubmit(data) {
    const type = data?.type?.value;
    const priority = data?.priority?.value || 'Basse';
    const assignedTo = (data?.assignedTo || []).map((user) => user.value) || null;
    const caseId = data?.case?.value;

    const payload = {
      type,
      date: data.date ? formatISO(data.date) : null,
      priority,
      status: 'Todo',
      assignedTo,
      case: caseId,
      agency: selectedAgency._id,
      linkedTask: data?.linkedTask?.value || null,
      linkedTransport: data?.linkedTransport?.value || null,
    };

    createTask(dispatch, payload).then(() => {
      const params = Object.fromEntries(searchParams.entries());
      if (data.date) {
        params.day = getDayOfYear(data.date);
        setSearchParams(params);
      }
      handleClose();
    });
  }

  function handlePressEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }
  // 658019c611bc493acfbe0b26
  return (
    <div onKeyDown={handlePressEnter} className={styles.form}>
      <h1>
        Créer une tâche
      </h1>
      {listOpts && (
        <>
          <div className={styles.containerField}>
            <StyledSelect
              name="case"
              control={control}
              label={'Affaire'}
              placeholder="Choisir une affaire"
              inline
              isSearchable
              // rules={{ required: 'Veuillez renseigner choisir affaire associée' }}
              options={listOpts.cases}
            />
          </div>
          <div className={styles.containerField}>
            <StyledSelect
              name="type"
              control={control}
              label={'Type de tâche'}
              placeholder="Choisir un type de tâche"
              inline
              rules={{ required: 'Veuillez renseigner le type' }}
              options={listOpts.types}
            />
          </div>
          <div className={styles.optionalFields}>
            <div className={styles.title}><h4>Optionnel</h4></div>
            <div className={styles.containerField}>
              <div className={styles.containerField}>
                <SelectTaskUser
                  optsUser={listOpts.users}
                  control={control}
                />
              </div>
            </div>
            <div className={styles.containerField}>
              <DatePicker
                name="date"
                control={control}
                label={'Planifier'}
                placeholder="Choisir une date"
                inline
              />
            </div>
            <div className={styles.containerField}>
              <StyledSelect
                name="priority"
                control={control}
                label={'Priorité'}
                placeholder="Choisir une priorité"
                inline
                options={listOpts.priorities}
              />
            </div>
            {(currentType?.value !== 'Préparation'
              && currentType?.value !== 'Déchargement'
              && optionsTask.length > 0
            ) && (
              <div className={styles.containerField}>
                <StyledSelect
                  name="linkedTask"
                  control={control}
                  label={'Tâche associée'}
                  placeholder="Choisir une tâche associée"
                  inline
                  isSearchable
                  options={optionsTask}
                />
              </div>
            )}
            {(optionsTransport.length > 0) && (
              <div className={styles.containerField}>
                <StyledSelect
                  name="linkedTransport"
                  control={control}
                  label={'Transport associé'}
                  placeholder="Choisir un transport associé"
                  inline
                  isSearchable
                  options={optionsTransport}
                />
              </div>
            )}
          </div>
          {!!Object.keys(errors).length && (
            ['case', 'type'].map((key) => (
              errors?.[key] ? <div className={styles.errorMessage}>
                <ErrorField key={`error-${key}`} message={errors?.[key].message} />
              </div> : null
            )).filter((d) => d)[0]
          )}
          <div className={styles.center}>
            <button onClick={handleSubmit(onSubmit)}>
              {isLoading.includes(TASK_POST) || isLoading.includes(TASK_PUT)
                ? <span className='icon loader'><Loader size={14} className='secondary' /></span>
                : <span className='icon'><BsCheck size={'24px'} /></span>
              }
              <span> Valider </span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateTaskForm;
