import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { getProfileAction } from './actions/auth';

import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';

import Home from './pages/home';
import Cases from './pages/cases';
import Case from './pages/case';
import Ged from './pages/ged';
import Transport from './pages/transport';
import Parc from './pages/parc';
import Loader from './components/Loader';
import Layout from './components/Layout';

const Redirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getProfileAction(dispatch).catch(() => { navigate('/'); });
    else navigate('/');
  }, []);

  return (
    <Loader />
  );
};

const AppRoutes = () => {
  const authReducer = useSelector((state) => state.authReducer);
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      {
        authReducer.user && (
          <>
            <Route path="/home" element={<Layout key='home'><Home /></Layout>} />
            <Route path="/transport" element={<Layout key='transport'><Transport /></Layout>} />
            <Route path="/parc" element={<Layout key='parc'><Parc /></Layout>} />
            <Route path="/cases" element={<Layout key='case'><Cases /></Layout>} />
            <Route path="/case/:id" element={<Layout key='case'><Case /></Layout>} />
            <Route path="/ged" element={<Layout key='ged'><Ged /></Layout>} />
          </>
        )
      }
      <Route path="*" element={<Redirect />} />
    </Routes >
  );
};

export default AppRoutes;
