import React from 'react';
import styles from './item-list.module.scss';

const ItemList = ({
  children,
  className,
  selected = true,
  handleClick = () => {},
}) => (
  <div
    onClick={handleClick}
    data-item-list='true'
    className={`${styles.row} ${className}${selected ? ` ${styles.selected}` : ''}`}
  >
    {children.map((child, key) => (
      <div key={`case-${key}`}>{child}</div>
    ))}
  </div>
);

export default ItemList;
