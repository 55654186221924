import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { IoIosAdd } from 'react-icons/io';
import { BsCheck } from 'react-icons/bs';
import ModalBottomStandalone from '../../lib/ModalBottomStandalone';
import styles from './modalSendEmail.module.scss';
import { sendTo, sendManyTo } from '../../utils';

import Loader from '../Loader';
import { InputEmail } from '../../lib/HooksFormFields';

const ModalSendEmail = ({ sendDocuments, handleSuccess, handleClose }) => {
  const modalSendEmailRef = useRef();
  const [sendDocumentState, setSendDocumentState] = useState();

  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (sendDocuments) modalSendEmailRef.current.open();
  }, [sendDocuments]);

  async function onSubmitSend(data) {
    setSendDocumentState('loading');
    if (Array.isArray(sendDocuments)) {
      const response = await sendManyTo(sendDocuments, data);
      setTimeout(() => {
        setSendDocumentState(response.status === 200 ? 'done' : 'error');
      }, 1000);
      setTimeout(() => {
        setSendDocumentState('');
        reset();
        modalSendEmailRef.current.toggle();
        if (handleSuccess) handleSuccess();
      }, response.status === 200 ? 2500 : 5000);
      return;
    }

    // FOR ONE ELEMENT
    const sendDocument = !Array.isArray(sendDocuments) ? sendDocuments : null;
    const response = await sendTo(sendDocument.type, sendDocument._id, data);
    setTimeout(() => {
      setSendDocumentState(response.status === 200 ? 'done' : 'error');
    }, 1000);
    setTimeout(() => {
      setSendDocumentState('');
      reset();
      modalSendEmailRef.current.toggle();
      if (handleSuccess) handleSuccess();
    }, response.status === 200 ? 2500 : 5000);
  }

  function handlePressForSend(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmitSend)();
    }
  }

  const documentType = useMemo(() => {
    let result = ' les fichiers ';
    if (!Array.isArray(sendDocuments)) {
      const sendDocument = !Array.isArray(sendDocuments) ? sendDocuments : null;
      if (sendDocument?.type === 'case') result = ' l\'affaire ';
      else if (sendDocument?.type === 'folder') result = ' le dossier ';
      else if (sendDocuments?.type === 'file') result = ' le fichier ';
    }
    return result;
  }, [sendDocuments]);

  return (
    <ModalBottomStandalone
      hideCloseButton={true}
      ref={modalSendEmailRef}
      handleClose={() => {
        if (handleClose) handleClose();
      }}
    >
      <div className={styles.modalSend} onKeyDown={handlePressForSend}>
        <label>
          Envoyer
          {documentType}
          {sendDocuments?.name && (
            <span className={styles.docName}>{sendDocuments?.name}</span>
          )}
        </label>
        <div className={styles['container-field']}>
          <button
            className={styles.close}
            onClick={() => modalSendEmailRef.current.close()}
          >
            <IoIosAdd />
          </button>
          <div className={styles.email}>
            <InputEmail
              name="email"
              control={control}
              required
              requiredMessage="Veuillez renseignez le destinataire"
              placeholder="adressemail@mail.fr"
            />
          </div>
          <button onClick={handleSubmit(onSubmitSend)}>
            <span>Envoyer</span>
            {sendDocumentState === 'loading' && <span className='icon loader' style={{ marginLeft: '10px' }}><Loader size={14} className='secondary' /></span>}
            {sendDocumentState === 'done' && <span className='icon'><BsCheck size={'24px'} /></span>}
          </button>
        </div>
        <div></div>
        {sendDocumentState === 'error' && <div className={styles.error}>
          <p>
            {`${documentType}n'a pas pu être envoyé`}
          </p>
        </div>}
        {errors.email && <div className={styles.error}><p>{errors.email.message}</p></div>}
      </div>
    </ModalBottomStandalone>
  );
};

export default ModalSendEmail;
