import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { BiChevronUp, BiChevronDown } from 'react-icons/bi';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  getDayOfYear,
  setDayOfYear,
  getWeek,
  format,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import Task from '../Task';
import styles from './planning-task.module.scss';

const PlanningTasks = ({
  listTasks,
  defaultView = 'week',
  handleChangeWeek,
}, ref) => {
  const days = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
  const [searchParams, setSearchParams] = useSearchParams();
  const [day, setDay] = useState();
  const [stateDay, setStateDay] = useState({});
  const [view, setView] = useState(defaultView);

  function setSearchParam(param, d) {
    const params = new URLSearchParams(searchParams);
    params.set(param, d);
    setSearchParams(params);
  }

  useImperativeHandle(ref, () => ({
    getWeek: () => getWeek(setDayOfYear(new Date(), day)),
    selectToday: () => {
      const currDay = getDayOfYear(new Date());
      setSearchParam('day', currDay);
    },
    setViewType: (type) => {
      if (!['week', 'day'].includes(type)) {
        console.warn(`view type should be ['week', 'day'], got : ${type}`);
      } else {
        if (type === 'day') {
          const currDay = setDayOfYear(new Date(), day);
          const currDisplayWeek = getWeek(currDay);
          const todayWeek = getWeek(new Date());

          // IF WE SWITCH TO VIEW DAY AND THE CURRENT DISPLAY WEEK
          // ISN'T THE CURRENT REAL WEEK SET DAY TO THE FIRST DAY OF THE
          // DISPLAY WEEK
          if (todayWeek !== currDisplayWeek) {
            const firstDayOfCurrentWeek = (currDay.getDate() - currDay.getDay()) + 1;
            const firstDateOfCurrentWeek = new Date(currDay
              .setDate((firstDayOfCurrentWeek))).toUTCString();
            setSearchParam('day', getDayOfYear(new Date(firstDateOfCurrentWeek)));
          } else if (todayWeek === currDisplayWeek) {
            // IF WE SWITCH TO VIEW DAY AND
            // THE CURRENT DISPLAY WEEK === THE CURRENT REAL WEEK
            // SET DAY TO THE REAL CURRENT DAY OF THE REAL WEEK
            setSearchParam('day', getDayOfYear(new Date()));
          }
        }
        setSearchParam('view', type);
      }
    },
    prevWeek: () => {
      let curr = day;
      curr -= view === 'week' ? 7 : 1;
      const currentIndex = setDayOfYear(new Date(), day).getDay();
      // JUMP OVER SUNDAY
      if (view === 'day' && currentIndex === 1) curr -= 1;
      setSearchParam('day', curr);
    },
    // eslint-disable-next-line no-param-reassign
    nextWeek: () => {
      let curr = day;
      curr += view === 'week' ? 7 : 1;
      const currentIndex = setDayOfYear(new Date(), day).getDay();
      // JUMP OVER SUNDAY
      if (view === 'day' && currentIndex === 6) curr += 1;
      setSearchParam('day', curr);
    },
  }));

  // HANDLE STATE DAY DEPENDING ON PARAM DAY
  // HANDLE STATE VIEW DEPENDING ON PARAM VIEW
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const paramDay = params.get('day');
    if (/^(-|\d)[0-9]*$/g.test(paramDay)) {
      let value = parseInt(paramDay, 10);
      if (value < (365 * -3)) value = getDayOfYear(new Date());
      setDay(value);
    } else {
      setDay(getDayOfYear(new Date()));
    }

    const paramView = params.get('view');
    if (['week', 'day'].includes(paramView)) {
      setView(paramView);
    } else setView(defaultView);
  }, [searchParams]);

  // HANDLE CHANGE WEEK DEPENDING ON DAY
  useEffect(() => {
    if (handleChangeWeek) {
      handleChangeWeek(getWeek(setDayOfYear(new Date(), day)));
    }
  }, [day]);

  function getDay(strDay) {
    const indexDay = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam'].indexOf(strDay);
    const curr = setDayOfYear(new Date(), day);
    const first = (curr.getDate() - curr.getDay()) + 1;
    const resDay = new Date(curr.setDate((first + indexDay))).toUTCString();
    return new Date(resDay);
  }

  function getListRenderDay() {
    const curr = setDayOfYear(new Date(), day);
    const currentIndex = curr.getDay() - 1;
    return (view === 'day' ? days.filter((d) => d === days[currentIndex]) : days);
  }

  function getCellIsOpened(targetDay) {
    let isOpened;
    if (!stateDay[targetDay] && targetDay >= getDayOfYear(new Date())) {
      isOpened = true;
    } else if (!stateDay[targetDay]) {
      isOpened = false;
    } else if (stateDay[targetDay]) {
      isOpened = stateDay[targetDay].open;
    }
    return isOpened;
  }

  function getClassCell(d) {
    const targetDay = getDayOfYear(getDay(d));
    const open = getCellIsOpened(targetDay);
    return open
      ? styles.cell
      : `${styles.cell} ${styles.closed}`;
  }

  function toggleDay(d) {
    const targetDay = getDayOfYear(getDay(d));
    const open = !getCellIsOpened(targetDay);
    setStateDay(
      (state) => ({ ...state, [targetDay]: { open } }),
    );
  }

  return (
    <div className={styles.planning}>
      <div className={styles.row}>
        <div className={styles.containerCell}>
          <div className={`${styles.cell} ${styles.header}`}>
            <p>Tâches attribuées</p>
          </div>
          <div className={`${styles.cell} ${styles.header}`}>
            <p>Tâches en cours</p>
          </div>
          <div className={`${styles.cell} ${styles.header}`}>
            <p>Tâches finies</p>
          </div>
        </div>
      </div>
      {getListRenderDay().map((d) => (
        <div key={`row-day-${d}`} className={styles.row}>
          <div
            key={`legend-day-${d}`}
            className={styles['legend-line']}
          >
            <p onClick={() => toggleDay(d)}>
              {getDayOfYear(getDay(d)) === getDayOfYear(new Date()) && (
                <span className={styles.today} />
              )}
              {d}
              {getCellIsOpened(getDayOfYear(getDay(d)))
                ? <BiChevronUp />
                : <BiChevronDown />
              }
              <br/>
              {format(new Date(getDay(d)), 'dd/MM', { locale: fr })}
            </p>
          </div>
          <div className={styles.containerCell}>
            <div className={getClassCell(d)} >
              {!getCellIsOpened(getDayOfYear(getDay(d)))
                ? <p>{(listTasks?.[`${getDayOfYear(getDay(d))}-Todo`] || []).length > 1
                  ? `${(listTasks?.[`${getDayOfYear(getDay(d))}-Todo`] || []).length} Tâches attribuées`
                  : `${(listTasks?.[`${getDayOfYear(getDay(d))}-Todo`] || []).length} Tâche attribuée`
                }</p>
                : <DroppableZone
                  index={1}
                  id={`${getDayOfYear(getDay(d))}-Todo`}
                  tasks={listTasks?.[`${getDayOfYear(getDay(d))}-Todo`]}
                />
              }
            </div>
            <div className={getClassCell(d)} >
              {!getCellIsOpened(getDayOfYear(getDay(d)))
                ? <p>
                  {(listTasks?.[`${getDayOfYear(getDay(d))}-Doing`] || []).length > 1
                    ? `${(listTasks?.[`${getDayOfYear(getDay(d))}-Doing`] || []).length} Tâches en cours`
                    : `${(listTasks?.[`${getDayOfYear(getDay(d))}-Doing`] || []).length} Tâche en cours`
                  }
                </p>
                : <DroppableZone
                  index={2}
                  id={`${getDayOfYear(getDay(d))}-Doing`}
                  tasks={listTasks?.[`${getDayOfYear(getDay(d))}-Doing`]}
                />
              }
            </div>
            <div className={getClassCell(d)} >
              {!getCellIsOpened(getDayOfYear(getDay(d)))
                ? <p>{(listTasks?.[`${getDayOfYear(getDay(d))}-Done`] || []).length > 1
                  ? `${(listTasks?.[`${getDayOfYear(getDay(d))}-Done`] || []).length} Tâches finies`
                  : `${(listTasks?.[`${getDayOfYear(getDay(d))}-Done`] || []).length} Tâche finie`
                }</p>
                : <DroppableZone
                  id={`${getDayOfYear(getDay(d))}-Done`}
                  index={3}
                  tasks={listTasks?.[`${getDayOfYear(getDay(d))}-Done`]}
                />
              }
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const DroppableZone = ({ id, tasks, index }) => {
  const { taskReducer, authReducer } = useSelector((store) => store);
  const { selectedTask } = taskReducer;
  const { user: { rights = {} } } = authReducer;

  return (
    <Droppable droppableId={id} type="TASK">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={`${styles.droppable}${snapshot.isDraggingOver ? ` ${styles.hover}` : ''}`}
        >
          {tasks?.map((task, i) => (
            <Draggable
              key={task._id}
              draggableId={task._id}
              index={i}
              isDragDisabled={!rights.write}
            >
              {(providedDraggable) => (
                <div
                  className={styles.containerTask}
                  ref={providedDraggable.innerRef}
                  {...providedDraggable.draggableProps}
                  {...providedDraggable.dragHandleProps}
                  style={{
                    ...providedDraggable.draggableProps.style,
                    zIndex: (selectedTask === task._id) ? 4 : 1,
                  }}
                >
                  <Task
                    id={task._id}
                    name={task.case?.name}
                    date={task.date}
                    refCase={task.case?.ref}
                    number={task.number}
                    clientName={task.case?.client}
                    caseId={task.case?._id}
                    type={task.type}
                    linkedTask={task.linkedTask}
                    linkedTransport={task.linkedTransport}
                    priority={task.priority}
                    status={task.status}
                    zone={task?.linkedTransport?.zone}
                    files={task.files}
                    articles={task.articles}
                    assignement={task.assignedTo}
                    hasWarning={task.hasWarning}
                    index={index}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default forwardRef(PlanningTasks);
