import React, { useMemo, useEffect } from 'react';
import { useForm, useController } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { formatISO } from 'date-fns';
import { BsCheck } from 'react-icons/bs';
import { createEvent } from '../../../../actions/event';
import { EVENT_POST } from '../../../../actions/types';
import Loader from '../../../Loader';
import Timepicker from '../Timepicker';
import SelectEventCases from '../SelectEventCases';
import styles from './create-transport-event.module.scss';

import {
  InputText,
  DatePicker,
  StyledCreatable,
  StyledSelect,
  Textarea,
  ErrorField,
} from '../../../../lib/HooksFormFields';

const defaultTimes = ['06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00',
  '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];

const getCases = async (id) => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
    },
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/case/${id}`, config);
    const data = await response.json();
    return data.case;
  } catch (e) {
    return [];
  }
};

const CreateTransportEvent = ({
  defaultDate = null,
  defaultTime = null,
  handleClose,
  listOpts,
}) => {
  const dispatch = useDispatch();
  const { eventReducer, authReducer } = useSelector((store) => store);
  const { selectedAgency } = authReducer;
  const { isLoading } = eventReducer;
  const { agencies = [] } = eventReducer.list || {};

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    // setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: defaultDate,
    },
  });

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const watchType = watch('type');
  const watchDirection = watch('direction');

  const {
    field: { onChange: changeDirection, value: valueDirection },
  } = useController({
    control,
    name: 'direction',
  });

  useEffect(() => {
    if (!defaultTime) return;
    const index = defaultTimes.findIndex((d) => d === defaultTime);
    setValue('startDate', defaultTimes[index]);
    setValue('endDate', defaultTimes[index + 1]);
  }, [defaultTime]);

  // HANDLE DATE END
  useEffect(() => {
    const indexStart = defaultTimes.findIndex((d) => watchStartDate === d);
    const indexEnd = defaultTimes.findIndex((d) => watchEndDate === d);
    if (indexStart >= 0 && indexEnd >= 0 && indexStart >= indexEnd) {
      setValue('endDate', defaultTimes[indexStart + 1]);
    }
  }, [watchStartDate]);

  // HANDLE DATE START
  useEffect(() => {
    const indexStart = defaultTimes.findIndex((d) => watchStartDate === d);
    const indexEnd = defaultTimes.findIndex((d) => watchEndDate === d);
    if (indexStart >= 0 && indexEnd >= 0 && indexStart >= indexEnd) {
      setValue('startDate', defaultTimes[indexEnd - 1]);
    }
  }, [watchEndDate]);

  // INIT DIRECTION
  useEffect(() => {
    if (listOpts.directions?.length) changeDirection(listOpts.directions[0].value);
  }, [listOpts]);

  function onSubmit(data) {
    // if (data.cases.length === 0) {
    //   setError('cases', { type: 'empty', message: 'Veuillez renseigner le client' });
    //   return;
    // }
    let startDate = null;
    let endDate = null;
    if (data.date && data.startDate) {
      startDate = new Date(data.date);
      const [hoursStart = 0, minutesStart = 0] = data.startDate.split(':');
      startDate.setHours(parseInt(hoursStart, 10));
      startDate.setMinutes(parseInt(minutesStart, 10));
      startDate.setSeconds(0);
    }
    if (data.date && data.endDate) {
      endDate = new Date(data.date);
      const [hoursEnd = 0, minutesEnd = 0] = data.endDate.split(':');
      endDate.setHours(parseInt(hoursEnd, 10));
      endDate.setMinutes(parseInt(minutesEnd, 10));
      endDate.setSeconds(0);
    }

    // IF LESS THAN 30 MINUTES INTERVAL SET
    // ENDDATE TO 30 MINUTES AFTER START
    if ((endDate && startDate)
      && (endDate.getTime() - startDate.getTime()) < (1800 * 1000)
    ) {
      endDate = new Date(startDate.getTime() + 30 * 60000);
    }

    const { address, city, ...rest } = data;

    if (rest.direction === 'departure') {
      rest.deliveryLocation = {
        address: address || '',
        city: city || '',
      };
    } else if (rest.direction === 'return') {
      rest.loadLocation = {
        address: address || '',
        city: city || '',
      };
    }

    const { date, destAgency, ...newEvent } = {
      ...rest,
      agency: selectedAgency._id,
      cases: data.cases.map((c) => c.value),
      carrier: data?.carrier?.value || null,
      trunkType: data?.trunkType?.value || null,
      type: data?.type || null,
      deliveryDate: data?.deliveryDate ? formatISO(data?.deliveryDate) : null,
      startDate: startDate ? formatISO(startDate) : null,
      endDate: endDate ? formatISO(endDate) : null,
    };

    createEvent(dispatch, newEvent).then(() => {
      handleClose();
    });
  }

  const getAddressString = (address) => {
    const addressInfos = [];
    let strAddress = '';
    if (address.number) addressInfos.push(address.number);
    if (address.street) addressInfos.push(address.street);
    if (address.complement) addressInfos.push(`(${address.complement})`);
    strAddress = addressInfos.join(' ');
    if (address.postal_code) strAddress += strAddress ? `, ${address.postal_code}` : address.postal_code;
    return strAddress;
  };

  const onCaseChange = async (caseId) => {
    const { address } = await getCases(caseId);
    const strAddress = getAddressString(address);
    if (strAddress) setValue('address', strAddress);
    if (address.city) setValue('city', address.city);
  };

  const preFilledAddress = (agencyId) => {
    const { address } = agencies.find((d) => d._id === agencyId);
    const strAddress = getAddressString(address);
    if (strAddress) setValue('address', strAddress);
    if (address.city) setValue('city', address.city);
  };

  function handlePressEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  // function getMinTime() {
  //   const findIndex = defaultTimes.findIndex((d) => d === watchStartDate);
  //   return defaultTimes[findIndex + 1];
  // }

  // function getMaxTime() {
  //   const findIndex = defaultTimes.findIndex((d) => d === watchEndDate);
  //   return defaultTimes[findIndex - 1];
  // }

  const optsAgencies = useMemo(
    () => (agencies || [])
      .sort((a, b) => a.label.localeCompare(b.label)),
    [agencies],
  );

  return (
    <div onKeyDown={handlePressEnter} className={styles.form}>
      <h1>
        Créer un événement
      </h1>
      <div className={styles.containerField}>
        <div className={styles.dateFields}>
          <div className={styles.date}>
            <DatePicker
              name='date'
              control={control}
              dateFormat='eeee dd LLLL'
              placeholder='Choisir une date'
              className='discreet'
              inline
            />
          </div>
          <div className={styles.time}>
            <Timepicker
              name='startDate'
              times={defaultTimes.slice(0, defaultTimes.length - 1)}
              placeholder={defaultTimes[0]}
              // maxValue={getMaxTime()}
              control={control}
            />
          </div>
          <span>&nbsp;-&nbsp;</span>
          <div className={styles.time}>
            <Timepicker
              name='endDate'
              times={defaultTimes.slice(1, defaultTimes.length)}
              placeholder={defaultTimes[1]}
              // minValue={getMinTime()}
              control={control}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerField}>
        <SelectEventCases
          control={control}
          clearErrors={clearErrors}
          options={listOpts?.cases}
          onChange={(opt) => {
            onCaseChange(opt.value);
          }}
        />
      </div>
      <div className={styles.containerField}>
        <div className={styles.directions}>
          {listOpts?.directions.map((opt) => (
            <button
              onClick={() => changeDirection(opt.value)}
              key={`direction-${opt.value}`}
              className={valueDirection === opt.value ? styles.selected : ''}
            >{opt.label}</button>
          ))}
        </div>
      </div>
      <div className={styles.containerField}>
        <DatePicker
          name='deliveryDate'
          control={control}
          dateFormat='eeee dd LLLL'
          placeholder='Date de livraison'
          className='discreet'
          inline
        />
      </div>
      <div className={styles.containerField}>
        <StyledCreatable
          name="carrier"
          control={control}
          placeholder="Nom du transporteur"
          labelCreate="Créer le transporteur : "
          inline
          options={listOpts?.carriers}
        />
      </div>
      <div className={styles.containerField}>
        <StyledSelect
          name="type"
          control={control}
          placeholder="Type de transport"
          inline
          rules={{ required: 'Veuillez sélectionner un type de transport.' }}
          options={listOpts?.types}
        />
      </div>
      {watchType?.value === 'TRANSFERT DE PARC' && (
        <div className={styles.containerField}>
          <StyledSelect
            name="destAgency"
            control={control}
            placeholder={
              watchDirection === 'departure'
                ? 'Agence de destination...'
                : 'Agence d\'origine...'
            }
            inline
            options={optsAgencies}
            handleChange={(opt) => preFilledAddress(opt.value)}
          />
        </div>
      )}
      <div className={styles.containerField}>
        <StyledSelect
          name="trunkType"
          control={control}
          placeholder="Type de camion"
          inline
          options={listOpts?.trunkTypes}
        />
      </div>
      <div className={styles.containerField}>
        {/*
          logic to save address in deliveryLocation or loadLocation
          is handle in submit()
        */}
        <InputText
          name="address"
          control={control}
          placeholder="Adresse du chantier"
          inline
        />
      </div>
      <div className={styles.containerField}>
        <InputText
          name="city"
          control={control}
          placeholder="Ville de départ"
          inline
        />
      </div>
      <div className={styles.containerField}>
        <InputText
          name="purchaseOrder"
          control={control}
          placeholder="Bon de commande fournisseur"
          inline
        />
      </div>
      <div className={styles.containerField}>
        <Textarea
          name="commentIntern"
          control={control}
          placeholder="Commentaire interne"
          inline
        />
      </div>
      {!!Object.keys(errors).length && (
        ['startDate', 'endDate', 'cases', 'case', 'carrier', 'type', 'trunkTypes', 'city'].map((key) => (
          errors?.[key] ? <div className={styles.errorMessage}>
            <ErrorField key={`error-${key}`} message={errors?.[key].message} />
          </div> : null
        )).filter((d) => d)[0]
      )}
      <div className={styles.center}>
        <button onClick={handleSubmit(onSubmit)}>
          {isLoading.includes(EVENT_POST)
            ? <span className='icon loader'><Loader size={14} className='secondary' /></span>
            : <span className='icon'><BsCheck size={'24px'} /></span>
          }
          <span> Créer un événement </span>
        </button>
      </div>
    </div>
  );
};

export default CreateTransportEvent;
