import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import slugify from 'slugify';
import {
  format,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { useForm, useController } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAdd } from 'react-icons/io';
import { BsCheck } from 'react-icons/bs';
import { FaFileExport } from 'react-icons/fa';
import { HiFlag, HiOutlinePencil } from 'react-icons/hi';
import { CASE_GET_BY_ID, FILE_ADD, SEARCH_GET_LIST } from '../../actions/types';
import {
  getCase,
  setFolderCase,
  getFolderCase,
  deleteFolderCase,
  deleteFileCase,
  updateCase,
} from '../../actions/case';
import { deleteData } from '../../actions';
import { uploadFileInFolder, uploadFile } from '../../actions/file';

import {
  getSearchList,
} from '../../actions/search';
import imgCamera from '../../assets/images/icons/camera.svg';
import Loader from '../../components/Loader';
import ButtonUpload from '../../components/ButtonUpload';
import ModalStandalone from '../../lib/ModalStandalone';
import ModalSendEmail from '../../components/ModalSendEmail';
import CaseForm from '../../components/form/CaseForm';
import CreateFolder from '../../components/form/CreateFolder';
import ExportFolders from '../../components/form/ExportFolders';
import FoldersList from '../../components/list/FoldersList';
import FilesList from '../../components/list/FilesList';
import FileUploader from '../../components/FileUploader';
import styles from './case.module.scss';

import {
  StyledSelect,
  ErrorField,
} from '../../lib/HooksFormFields';

const Case = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const modalCreateFolder = useRef();
  const modalprintFolders = useRef();
  const modalCase = useRef();
  const fileList = useRef();
  const {
    caseReducer, searchReducer, fileReducer, authReducer,
  } = useSelector((store) => store);
  const {
    isLoading: isLoadingCase, selectedCase, currentFolder,
  } = caseReducer;
  const { user: { rights = {} } } = authReducer;
  const { list, isLoading: isLoadingSearch } = searchReducer;
  const { error: errorFile, isLoading: isLoadingFile } = fileReducer;
  const [file, setFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [sendDocuments, setSendDocuments] = useState(null);

  const {
    control,
    reset,
  } = useForm();
  const {
    field: {
      onChange,
      value: doubleCheck,
    },
  } = useController({
    name: 'doubleCheck',
    control,
  });

  useEffect(() => {
    const { id } = params;
    getCase(dispatch, id);
    getSearchList(dispatch);
  }, []);

  // HANDLE GET FOLDER ACCORDING TO SEARCH PARAMS
  useEffect(() => {
    if (!selectedCase) return;
    const p = new URLSearchParams(searchParams);
    const folderId = p.get('folder');
    if (folderId) {
      getFolderCase(dispatch, folderId);
    } else {
      // IF NO ID SPECIFICIED SET FOLDER WITH selectedCase.folders
      setFolderCase(dispatch, {
        ...selectedCase,
        root: true,
      });
    }
  }, [searchParams, selectedCase]);

  // INIT VALUE DOUBLECHECK
  // INIT VALUE STATUS
  useEffect(() => {
    if (
      !list.status
      || typeof selectedCase?.doubleCheck !== 'boolean'
      || typeof doubleCheck === 'boolean'
    ) return;
    const defaultStatus = list.status?.find((opt) => opt.value === slugify(selectedCase.status || '', { lower: true }));
    reset({ doubleCheck: selectedCase?.doubleCheck, status: defaultStatus });
  }, [selectedCase?.doubleCheck, list]);

  // SUBMIT CASE STATUS ON CHANGE
  function handleChangeStatus(status) {
    updateCase(dispatch, {
      ...selectedCase,
      status: status.label,
    });
  }

  // SUBMIT CASE doubleCheck ON CHANGE
  function handleChangeDoubleCheck() {
    updateCase(dispatch, {
      ...selectedCase,
      doubleCheck: !doubleCheck,
    }).then(() => {
      onChange(!doubleCheck);
    });
  }

  function handleSend() {
    setSendDocuments(selectedFiles);
  }

  function handleSelectionChange(selectionFiles) {
    setSelectedFiles(selectionFiles);
  }

  const addPictureCase = async (formData) => {
    const res = await uploadFile(formData);
    const type = searchParams.get('pictures');
    if (res.status === 201) {
      const updateFiles = [
        ...res.data.files,
        ...selectedCase.pictures,
      ].map((d) => ({
        file: d?.file?._id || d._id,
        type: d?.type || type,
      }));
      // type : departure | return | hs
      updateCase(dispatch, {
        _id: selectedCase._id,
        pictures: updateFiles,
      });
    }
  };

  const deletePictureCase = async (fileId) => {
    const res = await deleteData(null, `/file/${fileId}`, null, true);
    if (res.status === 200) {
      const updateFiles = selectedCase.pictures.filter((f) => f.file._id !== fileId);
      updateCase(dispatch, {
        _id: selectedCase._id,
        pictures: updateFiles,
      });
    }
  };

  async function handleChangeFile(fileValue) {
    const formData = new FormData();
    formData.append('file', fileValue);
    formData.append('title', fileValue.name);

    if (searchParams.get('pictures')) {
      addPictureCase(formData);
    } else {
      setFile(fileValue);
      await uploadFileInFolder(dispatch, formData, currentFolder);
      setFile(null);
    }
  }

  function openFolder(folderId) {
    const p = new URLSearchParams(searchParams);
    p.set('folder', folderId);
    setSearchParams(p);
  }

  function openPictureFolder(type) {
    const p = new URLSearchParams(searchParams);
    p.set('pictures', type);
    setSearchParams(p);
  }
  function getBreadcrumbTrail(folder) {
    const links = [<NavLink key={`root-${folder._id}`} to={`/case/${selectedCase._id}`}>...</NavLink>, ' / '];
    const minSlice = folder.parents.length - 4 || 0;
    folder.parents.slice(minSlice, folder.parents.length).forEach((f, i) => {
      links.push(
        <NavLink key={`folder-${f._id}`} to={`/case/${selectedCase._id}?folder=${f._id}`}>
          {f.name || '...'}
        </NavLink>,
      );
      if (i < folder.parents.length) links.push(' / ');
    });
    return links;
  }

  function renderHeaderPictures(type) {
    let strType;
    if (type === 'departure') strType = 'départ';
    else if (type === 'return') strType = 'retour';
    else if (type === 'hs') strType = 'hs';

    return (
      `Photo${currentFolder.pictures.filter((f) => f.type === type).length > 1 ? 's' : ''} ${` ${strType}`}`
    );
  }

  const renderDocument = useCallback((files) => {
    const groupFiles = {};
    (files || []).forEach((d) => {
      if (!d) return;
      const strDate = format(new Date(d.updatedAt), 'dd.MM.yy', { locale: fr });
      if (groupFiles[strDate]) groupFiles[strDate].push(d);
      else groupFiles[strDate] = [d];
    });
    const sortedGroup = Object.entries(groupFiles).map((d) => d[1])
      .sort((a, b) => new Date(a[0].createdAt)
        .getTime() - new Date(b[0].createdAt).getTime());

    const elements = sortedGroup.map((group) => {
      const key = format(new Date(group[0].updatedAt), 'dd.MM.yy', { locale: fr });
      return (
        <div key={`files-${key}`}>
          <p className={styles.titleListFiles} >Document(s) du : {key}</p>
          <div className={styles.listFiles}>
            <FilesList
              ref={fileList}
              files={group}
              handleSelectionChange={handleSelectionChange}
              actions={{
                print: true,
                send: (doc) => {
                  setSendDocuments(doc);
                },
                delete: (f) => {
                  if (searchParams.get('pictures')) {
                    deletePictureCase(f._id);
                    return;
                  }
                  deleteFileCase(dispatch, f);
                },
              }}
            />
          </div>
        </div>
      );
    });
    return elements;
  }, [selectedCase, currentFolder?.files]);

  return (
    <>
      <ModalSendEmail
        sendDocuments={sendDocuments}
        handleClose={() => {
          setSendDocuments(null);
        }}
        handleSuccess={() => {
          fileList.current.resetSelection();
          setSendDocuments(null);
          setSelectedFiles([]);
        }}
      />
      <ModalStandalone
        ref={modalCase}
      >
        <CaseForm
          dataCase={selectedCase}
          handleClose={() => modalCase.current.close()}
        />
      </ModalStandalone>
      <ModalStandalone ref={modalCreateFolder}>
        <CreateFolder
          handleClose={() => modalCreateFolder.current.close()}
          parent={currentFolder?.root ? null : currentFolder?._id}
          caseId={selectedCase}
        />
      </ModalStandalone>
      <ModalStandalone ref={modalprintFolders}>
        <ExportFolders
          folderId={currentFolder?.root ? null : currentFolder?._id}
          currentCase={selectedCase}
        />
      </ModalStandalone>
      <div className={styles.case}>
        {(isLoadingCase.includes(CASE_GET_BY_ID)
          || isLoadingSearch.includes(SEARCH_GET_LIST)
          || !selectedCase)
          ? <Loader />
          : <div className={styles.container}>
            <div className={styles.header}>
              <h1
              >
                <NavLink to={`/case/${params.id}`} >#{selectedCase.ref} {selectedCase.client}</NavLink>
                {(rights.write) && (
                  <span onClick={() => {
                    console.log('modalCase open');
                    modalCase.current.open();
                  }}>
                    <HiOutlinePencil />
                  </span>
                )}
              </h1>
              <span className={styles.fields}>
                {(rights.write) && (
                  <div className={styles.checkboxField} onClick={() => handleChangeDoubleCheck()}>
                    <HiFlag />
                    <p>Double contrôle</p>
                    <div className={styles.checkbox}>
                      {doubleCheck && <BsCheck />}
                    </div>
                  </div>
                )}
                <span>
                  <div className={styles.containerSelect}>
                    <StyledSelect
                      name="status"
                      control={control}
                      options={list.status}
                      className='dark'
                      isSearchable={false}
                      isDisabled={!rights.write}
                      handleChange={handleChangeStatus}
                    />
                  </div>
                  <button
                    className={styles.export}
                    onClick={() => modalprintFolders.current.open()}><FaFileExport /></button>
                </span>
              </span>
              {selectedCase.name && <h3>{selectedCase.name}</h3>}
            </div>
            {(currentFolder && !currentFolder?.root) && (
              <>
                <div className={styles.breadcrumbTrail}>
                  <p>
                    {getBreadcrumbTrail(currentFolder)}
                    <span> {currentFolder.name}</span></p>
                </div>
                <div className={styles.files}>
                  <div className={styles.header}>
                    <h3>Documents</h3>
                    <div className={styles.containerButtons}>
                      {selectedFiles.length > 0 && (
                        <button
                          className={`third ${styles.btnSend}`}
                          onClick={() => handleSend()}
                        >
                          Envoyer par email
                        </button>
                      )}
                      {(rights.write) && (
                        <ButtonUpload
                          isLoading={isLoadingFile.includes(FILE_ADD)}
                          handleChangeFile={handleChangeFile}
                        />
                      )}
                    </div>
                  </div>
                  {errorFile && (
                    <div className={styles.errorMessage}>
                      <ErrorField message={errorFile} />
                    </div>
                  )}
                  {currentFolder.files.length
                    ? renderDocument(currentFolder.files)
                    : <div className={file ? `${styles.containerInputField} ${styles.disabled}` : styles.containerInputField}>
                      {(rights.write) && (
                        <FileUploader
                          handleChangeFile={file ? () => { } : handleChangeFile}
                          isLoading={isLoadingFile.includes(FILE_ADD)}
                          file={file}
                        />
                      )}
                    </div>
                  }
                </div>
              </>
            )}
            {!searchParams.get('pictures') && currentFolder?.root && (
              <div className={styles.pictures}>
                <div className={styles.tile} onClick={() => openPictureFolder('departure')}>
                  <div className={styles.icon}>
                    <img src={imgCamera} alt="photos départ" />
                  </div>
                  <div className={styles.count}>
                    <p>Photos départ</p>
                    <p>{currentFolder.pictures.filter((f) => f.type === 'departure').length}</p>
                  </div>
                </div>
                <div className={styles.tile} onClick={() => openPictureFolder('return')}>
                  <div className={styles.icon}>
                    <img src={imgCamera} alt="photos retour" />
                  </div>
                  <div className={styles.count}>
                    <p>Photos retour</p>
                    <p>{currentFolder.pictures.filter((f) => f.type === 'return').length}</p>
                  </div>
                </div>
                <div className={styles.tile} onClick={() => openPictureFolder('hs')}>
                  <div className={styles.icon}>
                    <img src={imgCamera} alt="photos hs" />
                  </div>
                  <div className={styles.count}>
                    <p>Photos hs</p>
                    <p>{currentFolder.pictures.filter((f) => f.type === 'hs').length}</p>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.folders}>
              {currentFolder && searchParams.get('pictures')
                ? <>
                  <div className={styles.breadcrumbTrail}>
                    <p>
                      <NavLink key={`root-${currentFolder._id}`} to={`/case/${selectedCase._id}`}>...</NavLink>
                      <span> / {renderHeaderPictures(searchParams.get('pictures'))}</span></p>
                  </div>
                  <div className={styles.header}>
                    <h3>Documents</h3>
                    <div className={styles.containerButtons}>
                      {selectedFiles.length > 0 && (
                        <button
                          className={`third ${styles.btnSend}`}
                          onClick={() => handleSend()}
                        >
                          Envoyer par email
                        </button>
                      )}
                      {(rights.write) && (
                        <ButtonUpload
                          isLoading={isLoadingFile.includes(FILE_ADD)}
                          handleChangeFile={handleChangeFile}
                        />
                      )}
                    </div>
                  </div>
                  {currentFolder.pictures.length > 0 && (
                    renderDocument(currentFolder.pictures
                      .filter((f) => f.type === searchParams.get('pictures'))
                      .map((d) => d.file))
                  )}
                  {/* <FilesList
                    files={currentFolder.pictures
                      .filter((f) => f.type === searchParams.get('pictures'))
                      .map((d) => d.file)}
                    actions={{
                      print: true,
                      send: (doc) => {
                        setSendDocuments(doc);
                      },
                    }}
                  /> */}
                </>
                : <>
                  <div className={styles.header}>
                    <h3>Dossier{currentFolder?.folders?.length > 1 && 's'}</h3>
                    {(rights.write) && (
                      <button onClick={() => modalCreateFolder.current.open()}>
                        <span className='icon'><IoIosAdd size='20px' /></span>
                        <span>Créer un dossier</span>
                      </button>
                    )}
                  </div>
                  <FoldersList
                    folders={currentFolder?.folders}
                    handleClickFolder={openFolder}
                    actions={{
                      print: true,
                      send: (doc) => {
                        setSendDocuments(doc);
                      },
                      delete: (folder) => deleteFolderCase(dispatch, folder),
                    }}
                  />
                </>
              }
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default Case;
