import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useMemo,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import { FaFileExport } from 'react-icons/fa';
import { BsCheck } from 'react-icons/bs';
import { dateStringDot } from '../../../utils';
import PositionalModal from '../../../lib/PositionalModal';
import ModalStandalone from '../../../lib/ModalStandalone';
import fileImg from '../../../assets/images/icons/file.svg';
import dots from '../../../assets/images/icons/green-dots.svg';
import mailIcon from '../../../assets/images/icons/mail.svg';
import deleteIcon from '../../../assets/images/icons/delete.svg';

import styles from './files-list.module.scss';

const FileItem = ({
  file,
  selected,
  handleSelect,
  actions = {},
}) => {
  const { user: { rights = {} } } = useSelector((store) => store).authReducer;
  const modalRef = useRef();
  const modalParent = useRef();
  const modalImage = useRef();
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (preview) modalImage.current.open();
  }, [preview]);

  function openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    modalRef.current.open();
  }

  function sendFile(e) {
    e.preventDefault();
    e.stopPropagation();
    modalRef.current.close();
    actions.send({ _id: file._id, name: file.name, type: 'file' });
  }

  function deleteFile(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!actions.delete || typeof actions.delete !== 'function') return;
    actions.delete(file);
  }

  async function dowloadFile(e) {
    e.preventDefault();
    e.stopPropagation();
    const config = {
      headers: {
        Authorization: `${localStorage.getItem('token')}`,
        agency: `${localStorage.getItem('agency')}`,
      },
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/file/${file._id}`, config);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${file.name}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      console.log(err);
    }
  }

  const previewImgUrl = useMemo(() => {
    const token = localStorage.getItem('token');
    return `${process.env.REACT_APP_API_URL}/static/${file?.path.replace('upload/', '')}?token=${token}`;
  }, [file]);

  return (
    <li>
      <ModalStandalone
        ref={modalImage}
        handleClose={() => setPreview(false)}
      >
        <div className={styles.modalImage}>
          <img
            src={previewImgUrl}
          />
        </div>
      </ModalStandalone>
      <PositionalModal
        ref={modalRef}
        parent={modalParent}
        alignmentX={'right'}
        alignmentY={'middle'}
      >
        <div className={styles.containerActions}>
          {actions.print && (<div onClick={(e) => dowloadFile(e)}>
            <FaFileExport size='20px' />
            <p>Télécharger</p>
          </div>
          )}
          {actions.send && (<div onClick={(e) => sendFile(e)}><img src={mailIcon} alt='Envoyer par mail' /><p>Envoyer par mail</p></div>)}
          {(rights.write && actions.delete) && (
            <div onClick={(e) => deleteFile(e)}>
              <img src={deleteIcon} alt='Supprimer' />
              <p>Supprimer</p>
            </div>
          )}
        </div>
      </PositionalModal>
      <div className={styles.content}>
        <div
          style={(file.mimetype === 'image/jpeg'
          || file.mimetype === 'image/png') ? { cursor: 'pointer' } : {}}
          onClick={() => {
            if (
              file.mimetype === 'image/jpeg'
              || file.mimetype === 'image/png'
            ) {
              console.log('here');
              setPreview(true);
            }
          }
        }>
          <img src={fileImg} alt={`dossier ${file.name}`} />
          <p>{file.name}</p>
        </div>
        <div className={styles.date}>
          {file.createdAt && dateStringDot(new Date(file.createdAt))}
        </div>
        <div className={styles.toggle}>
          <span onClick={openModal} ref={modalParent}>
            <img src={dots} alt={`action sur le dossier: ${file.name}`} />
          </span>
        </div>
      </div>
      <Checkbox checked={selected} handleClick={() => handleSelect(file._id)}/>
    </li>
  );
};

const FilesList = ({
  files,
  actions = {},
  handleSelectionChange,
}, ref) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  useImperativeHandle(ref, () => ({
    resetSelection: () => setSelectedFiles([]),
  }), []);

  const handleSelectFile = (fileId) => {
    setSelectedFiles(
      (state) => (state.includes(fileId)
        ? state.filter((id) => id !== fileId)
        : [...state, fileId]),
    );
  };

  useEffect(() => {
    if (handleSelectionChange) handleSelectionChange(selectedFiles);
  }, [selectedFiles]);

  return (
    <div className={styles.foldersList}>
      {files && (
        <ul>
          {files.map((file) => (
            <FileItem
              handleSelect={handleSelectFile}
              actions={actions}
              key={`file-${file._id}`}
              file={file}
              selected={selectedFiles.includes(file._id)}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

const Checkbox = (({ checked, handleClick }) => {
  useEffect(() => {}, []);
  return (
    <div className={styles.checkbox} onClick={() => handleClick()}>
      {checked && <BsCheck />}
    </div>
  );
});

export default forwardRef(FilesList);
