import {
  getData,
  putData,
  postData,
  deleteData,
} from './index';
import {
  CASE_GET_BY_ID,
  CASE_POST,
  CASE_PUT,
  CASE_DELETE,
  CASE_FOLDER_GET,
  CASE_FOLDER_POST,
  CASE_FOLDER_DELETE,
  CASE_FILE_DELETE,
  CASE_FOLDER_SET,
  CASE_RESET_ERROR,
  CASE_LOADING,
  CASE_ERROR,
} from './types';

export const getCase = async (dispatch, id) => {
  const url = `/case/${id}`;
  dispatch({
    type: CASE_LOADING,
    payload: CASE_GET_BY_ID,
  });
  const response = await getData(CASE_ERROR, url, dispatch, true);
  if (response.data.case) {
    dispatch({
      type: CASE_GET_BY_ID,
      payload: response.data.case,
    });
  }
};

export const createCase = async (dispatch, data) => {
  const url = '/case';
  dispatch({
    type: CASE_LOADING,
    payload: CASE_POST,
  });
  const response = await postData(CASE_ERROR, url, dispatch, data, true);
  if (response.data?.case) {
    dispatch({
      type: CASE_POST,
      payload: response.data.case,
    });
  }
  return response;
};

export const updateCase = async (dispatch, data) => {
  const url = `/case/${data._id}`;
  const { _id, ...updatedCase } = data;
  if (dispatch) {
    dispatch({
      type: CASE_LOADING,
      payload: CASE_PUT,
    });
  }

  const response = await putData(CASE_ERROR, url, dispatch, updatedCase, true);
  if (dispatch && response.data.case) {
    dispatch({
      type: CASE_PUT,
      payload: response.data,
    });
  }
};

export const deleteCase = async (dispatch, id) => {
  const url = `/case/${id}`;
  dispatch({
    type: CASE_LOADING,
    payload: CASE_DELETE,
  });
  const response = await deleteData(CASE_ERROR, url, dispatch, true);
  if (response.data.message === 'Case successfully removed!') {
    dispatch({
      type: CASE_DELETE,
      payload: { case: { _id: id } },
    });
  }
};

export const getFolderCase = async (dispatch, id) => {
  const url = `/folder/${id}`;
  dispatch({
    type: CASE_LOADING,
    payload: CASE_FOLDER_GET,
  });
  const response = await getData(CASE_ERROR, url, dispatch, true);
  if (response.data?.folder) {
    dispatch({
      type: CASE_FOLDER_GET,
      payload: response.data.folder,
    });
  }
  return response;
};

export const setFolderCase = async (dispatch, folders) => {
  dispatch({
    type: CASE_FOLDER_SET,
    payload: folders,
  });
};

export const createFolderCase = async (dispatch, data) => {
  const url = '/folder';
  dispatch({
    type: CASE_LOADING,
    payload: CASE_FOLDER_POST,
  });
  const response = await postData(CASE_ERROR, url, dispatch, data, true);
  if (response.data?.folder) {
    dispatch({
      type: CASE_FOLDER_POST,
      payload: response.data.folder,
    });
  }
  return response;
};

export const deleteFolderCase = async (dispatch, data) => {
  const url = `/folder/${data._id}`;
  dispatch({
    type: CASE_LOADING,
    payload: CASE_FOLDER_DELETE,
  });
  const response = await deleteData(CASE_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: CASE_FOLDER_DELETE,
      payload: data._id,
    });
  }
  return response;
};

export const deleteFileCase = async (dispatch, data) => {
  const url = `/file/${data._id}`;
  dispatch({
    type: CASE_LOADING,
    payload: CASE_FILE_DELETE,
  });

  const response = await deleteData(CASE_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: CASE_FILE_DELETE,
      payload: data._id,
    });
  }
  return response;
};

export const resetErrorCase = async (dispatch) => {
  dispatch({
    type: CASE_RESET_ERROR,
  });
};
