import React, { useEffect, useState } from 'react';
import cloneDeep from 'clone-deep';
import slugify from 'slugify';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import Loader from '../../Loader';
import iconCheckboxChecked from './icons/checkbox-checked.svg';
import iconCheckbox from './icons/checkbox.svg';
import iconFolder from './icons/folder.svg';
import iconFile from './icons/file.svg';
import {
  set,
  get,
  unset,
  downloadFolders,
} from '../../../utils';
import styles from './export-folder.module.scss';

const loadFolder = async (id) => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
    },
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/folder/${id}`, config);
    const data = await response.json();
    return data.folder;
  } catch (e) {
    return [];
  }
};

const getParentsKeys = (parent) => {
  const parents = parent.replace('folders.', '').split('.folders.');
  let parentsKeys = [];
  if (parents.length > 0) {
    parentsKeys = parents.map((parentId, i) => {
      const slice = parents.slice(0, i + 1);
      return `folders.${slice.join('.folders.')}`;
    });
  }
  return parentsKeys;
};

const getChildKeys = (folderObject) => {
  const childrenKeys = [];
  const traverse = (d, parentKey) => {
    if (d.folders) {
      Object.keys(d).forEach((k) => {
        if (k === 'folders' || k === 'files') {
          const children = [...Object.values(d[k] || {})];
          children.forEach((child, index) => {
            const targetProperty = Array.isArray(d[k]) ? index : child._id;
            const childKey = parentKey ? `${parentKey}.${k}.${targetProperty}` : `${k}.${targetProperty}`;
            childrenKeys.push(childKey);
            if (child.folders) {
              traverse(child, childKey);
            }
          });
        }
      });
    }
  };
  traverse(folderObject);
  return childrenKeys;
};

// ENVOYER TOUS LES ID DES FOLDERS COCHER

const ExportFolders = ({ folderId, currentCase }) => {
  const [folder, setFolder] = useState();
  const [isOpened, setIsOpened] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selection, setSelection] = useState({});
  const [isLoading, setIsLoading] = useState([]);

  function createStructure(currentFolders) {
    const res = {};
    currentFolders.forEach((f) => {
      res[f._id] = {
        _id: f._id,
        name: f.name,
        folders: f.folders?.length > 0 ? createStructure(f.folders) : null,
        files: f.files?.length > 0 ? f.files : null,
      };
    });
    return res;
  }

  useEffect(() => {
    if (folderId) {
      const getFolder = async () => {
        const resFolders = await loadFolder(folderId);
        if (resFolders) {
          const folders = createStructure([resFolders]);
          setFolder({ folders });
        }
      };
      getFolder();
    } else if (currentCase) {
      const folders = createStructure(currentCase.folders);
      setFolder({ folders });
    }
  }, [folderId, currentCase]);

  async function handleOpenFolder(e, id, parent) {
    e.preventDefault();
    e.stopPropagation();

    if (isOpened.includes(id)) {
      // CLOSE
      setIsOpened((state) => state.filter((d) => d !== id));
    } else {
      // OPEN
      setIsOpened((state) => [...state, id]);
      const key = !parent ? `folders.${id}` : `${parent}.folders.${id}`;

      // IF CONTENT EXIST IN TARGET FOLDER
      // RETURN THEN DONT LOAD CONTENT AGAIN
      if (get(folder, key).folders) return;

      const updatedFolder = cloneDeep(folder);
      setIsLoading((state) => [...state, id]);
      const resFolder = await loadFolder(id);
      setTimeout(() => {
        setIsLoading((state) => state.filter((d) => d !== id));
        set(updatedFolder, key, {
          _id: resFolder._id,
          name: resFolder.name,
          folders: resFolder.folders?.length > 0 ? createStructure(resFolder.folders) : {},
          files: resFolder.files?.length > 0 ? resFolder.files : null,
        });

        // IF SELECTALL === true
        // SELECT FILES AND FOLDERS
        // WHICH ARE LOADED
        if (selectAll) {
          const updatedSelection = cloneDeep(selection);
          const targetFolder = get(updatedFolder, key);
          if (targetFolder) {
            const childKeys = getChildKeys(targetFolder);
            childKeys.forEach((childKey) => {
              const child = get(updatedFolder, `${key}.${childKey}`);
              const childType = childKey.includes('files') ? 'file' : 'folder';
              const targetChildKey = childType === 'file' ? childKey.replace(/(files.\d)$/, `folders.${child._id}`) : childKey;
              set(updatedSelection, `${key}.${targetChildKey}`, {
                type: childType,
                selected: true,
              });
            });
          }
          setSelection(updatedSelection);
        }

        setFolder(updatedFolder);
      }, 500);
    }
  }

  function handleSelect(e, id, parent, type) {
    e.preventDefault();
    e.stopPropagation();

    const updatedSelection = cloneDeep(selection);
    const key = parent ? `${parent}.folders.${id}` : `folders.${id}`;
    const value = !!get(updatedSelection, key)?.selected;
    if (value) {
      unset(updatedSelection, `${key}.selected`);
      unset(updatedSelection, `${key}.type`);

      // UNSELECT PARENT WHEN A CHILD IS UNSELECTED
      const parentsKeys = getParentsKeys(parent || '');
      parentsKeys.forEach((parentKey) => {
        unset(updatedSelection, `${parentKey}.selected`);
      });
      //

      if (Object.keys(get(updatedSelection, key)).length <= 0) unset(updatedSelection, key);
    } else {
      // SELECT CHILD WHEN A PARENT FOLDER IS SELECTED
      const targetFolder = get(folder, key);
      if (targetFolder) {
        const childKeys = getChildKeys(targetFolder);
        childKeys.forEach((childKey) => {
          const child = get(folder, `${key}.${childKey}`);
          const childType = childKey.includes('files') ? 'file' : 'folder';
          const targetChildKey = childType === 'file' ? childKey.replace(/(files.\d)$/, `folders.${child._id}`) : childKey;
          set(updatedSelection, `${key}.${targetChildKey}`, {
            type: childType,
            selected: true,
          });
        });
      }
      //

      set(updatedSelection, `${key}.selected`, !value);
      set(updatedSelection, `${key}.type`, type);
    }
    setSelection(updatedSelection);
  }

  function toggleSelect() {
    const bool = !selectAll;
    setSelectAll(bool);
    const childKeys = getChildKeys(folder);
    const updatedSelection = cloneDeep(selection);
    childKeys.forEach((childKey) => {
      const child = get(folder, childKey);
      const childType = childKey.includes('files') ? 'file' : 'folder';
      const targetChildKey = childType === 'file' ? childKey.replace(/(files.\d)$/, `folders.${child._id}`) : childKey;
      set(updatedSelection, `${targetChildKey}`, {
        type: childType,
        selected: bool,
      });
    });
    setSelection(updatedSelection);
  }

  function submit() {
    const getSelected = (o) => {
      const selected = [];
      const prepareObj = (d) => {
        const obj = {};
        if (d.folders) {
          const { folders } = d;
          Object.keys(folders).forEach((key) => {
            const currentFolder = { ...folders[key] };
            if (currentFolder.selected) {
              selected.push({
                _id: key, type: currentFolder.type,
              });
            }
            if (currentFolder.folders) {
              obj[key] = { ...prepareObj(currentFolder) };
            } else {
              obj[key] = currentFolder;
            }
          });
        }
        return Object.keys(obj).length > 0 ? obj : null;
      };
      prepareObj(o);
      return selected;
    };
    const data = getSelected(selection);
    let name = 'archive';
    if (currentCase?.client) name = slugify(currentCase.client).toLowerCase();
    downloadFolders(name, data);
  }

  function renderFolders(f, parent = null) {
    return (
      <ul>
        {Object.values(f).map((d) => <li
          key={`folder-${d._id}`}
        >
          <div
            className={styles.folder}
            onClick={(e) => handleOpenFolder(e, d._id, parent)}
          >
            <span className={styles.name}>
              <span className={styles.icon}>
                {(isLoading.includes(d._id) && isOpened.includes(d._id))
                  ? <Loader size={15} /> : <img src={iconFolder} alt={`dossier: ${d.name}`} />
                }
              </span>
              <p>{d.name}</p>
            </span>
            <span className={styles.action}>
              {isOpened.includes(d._id)
                ? <BiChevronUp size='20' />
                : <BiChevronDown size='20' />
              }
              {get(selection, parent ? `${parent}.folders.${d._id}` : `folders.${d._id}`)?.selected
                ? <img onClick={(e) => handleSelect(e, d._id, parent, 'folder')} src={iconCheckboxChecked} alt={`sélectionner ${d.name}`} />
                : <img onClick={(e) => handleSelect(e, d._id, parent, 'folder')} src={iconCheckbox} alt={`désélectionner ${d.name}`} />
              }
            </span>
          </div>
          {(!isLoading.includes(d._id) && isOpened.includes(d._id)) && (
            <>
              {Object.keys(d?.folders || {})?.length > 0 && (
                renderFolders(d.folders, `${parent ? `${parent}.folders.` : 'folders.'}${d._id}`)
              )}
              {(Object.keys(d?.files || {})?.length > 0) && (
                <ul>
                  {d.files.map((file) => (
                    <li key={typeof file === 'string' ? `file-${file}` : `file-${file._id}`}>
                      <div className={styles.file}>
                        <span className={styles.name}>
                          <img src={iconFile} alt={`fichier: ${file.name}`} />
                          <p>
                            {typeof file === 'string' ? file : file.name}
                          </p>
                        </span>
                        <span>
                          {get(selection, parent ? `${parent}.folders.${d._id}.folders.${file._id}` : `folders.${d._id}.folders.${file._id}`)?.selected
                            ? <img onClick={(e) => handleSelect(e, file._id, `${parent ? `${parent}.folders.` : 'folders.'}${d._id}`, 'file')} src={iconCheckboxChecked} alt={`désélectionner ${d.name}`} />
                            : <img onClick={(e) => handleSelect(e, file._id, `${parent ? `${parent}.folders.` : 'folders.'}${d._id}`, 'file')} src={iconCheckbox} alt={`sélectionner ${d.name}`} />
                          }
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
          {(isOpened.includes(d._id)
            && !isLoading.includes(d._id)
            && Object.keys(d?.folders || {})?.length <= 0
            && Object.keys(d?.files || {})?.length <= 0
          ) && (
            <p className={styles.empty}>Ce dossier ne contient pas de fichier</p>
          )}
        </li>)}
      </ul>
    );
  }

  return (
    <div className={styles.container}>
      <h1>Exporter</h1>
      <p className={styles.toggle} onClick={() => toggleSelect()}>
        {!selectAll ? 'Tout sélectionner' : 'Tout désélectionner'}
      </p>
      {Object.keys(folder?.folders || {})?.length > 0 && renderFolders(folder.folders)}
      <div className={styles.center}>
        <button onClick={() => submit()}>Télécharger</button>
      </div>
    </div>
  );
};

export default ExportFolders;
