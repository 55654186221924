/* eslint-disable import/prefer-default-export */
import {
  getData,
  putData,
  postData,
  deleteData,
} from './index';
import {
  GET_GED_FOLDER,
  GET_GED_FOLDER_BY_ID,
  GED_FOLDER_POST,
  GET_FOLDER_PUT,
  GED_FOLDER_DELETE,
  GED_FILE_DELETE,
  GED_ERROR,
  GED_LOADING,
} from './types';

export const getGedFolders = async (dispatch) => {
  const url = '/folder?notAssigned=true';
  dispatch({
    type: GED_LOADING,
    payload: GET_GED_FOLDER,
  });
  const response = await getData(GED_ERROR, url, dispatch, true);
  if (response.data.folders) {
    dispatch({
      type: GET_GED_FOLDER,
      payload: response.data,
    });
  }
};

export const getGedFolder = async (dispatch, id) => {
  const url = `/folder/${id}`;
  dispatch({
    type: GED_LOADING,
    payload: GET_GED_FOLDER_BY_ID,
  });
  const response = await getData(GED_ERROR, url, dispatch, true);
  if (response.data?.folder) {
    dispatch({
      type: GET_GED_FOLDER_BY_ID,
      payload: response.data.folder,
    });
  }
  return response;
};

export const updateGedFolder = async (dispatch, data) => {
  const url = `/folder/${data._id}`;
  const { _id, ...updatedCase } = data;
  dispatch({
    type: GED_LOADING,
    payload: GET_FOLDER_PUT,
  });
  const response = await putData(GED_ERROR, url, dispatch, updatedCase, true);
  if (response.data.folder) {
    dispatch({
      type: GET_FOLDER_PUT,
      payload: response.data,
    });
  }
  return response;
};

export const createGedFolder = async (dispatch, data) => {
  const url = '/folder';
  dispatch({
    type: GED_LOADING,
    payload: GED_FOLDER_POST,
  });
  const response = await postData(GED_ERROR, url, dispatch, data, true);
  if (response.data?.folder) {
    dispatch({
      type: GED_FOLDER_POST,
      payload: response.data.folder,
    });
  }
  return response;
};

export const deleteGedFolder = async (dispatch, data) => {
  const url = `/folder/${data._id}`;
  dispatch({
    type: GED_LOADING,
    payload: GED_FOLDER_DELETE,
  });
  const response = await deleteData(GED_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: GED_FOLDER_DELETE,
      payload: data._id,
    });
  }
  return response;
};

export const deleteGedFile = async (dispatch, data) => {
  const url = `/file/${data._id}`;
  dispatch({
    type: GED_LOADING,
    payload: GED_FILE_DELETE,
  });
  const response = await deleteData(GED_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: GED_FILE_DELETE,
      payload: data._id,
    });
  }
  return response;
};
