import axios from 'axios';
import { format } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export const dateStringDot = (date) => format(date, 'dd.MM.yy');

// eslint-disable-next-line import/prefer-default-export
export const get = (obj = {}, path) => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

// eslint-disable-next-line import/prefer-default-export
export const set = (obj, path, value) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
};

export const unset = (obj, path) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  pathArray.reduce((acc, key, i) => {
    if (i === pathArray.length - 1) delete acc[key];
    return acc[key];
  }, obj);
};

export const downloadFolders = async (name, folders) => {
  const config = {
    method: 'POST',
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
      'Content-Type': 'application/zip',
    },
    body: JSON.stringify({ folders }),
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/folder/export`, config);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${name}.zip`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

export const downloadTransportEvent = async (id, name) => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'application/pdf',
      responseType: 'blob',
    },
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/transport/${id}/export`, config);
    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = `${name}.pdf`;
    if (contentDisposition) {
      fileName = response.headers.get('content-disposition')
        .split(';')
        .find((n) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

export const downloadArticlesTask = async (id, name) => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'application/pdf',
      responseType: 'blob',
    },
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/task/${id}/export/articles`, config);
    const blob = await response.blob();
    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = `${name}.pdf`;
    if (contentDisposition) {
      fileName = response.headers.get('content-disposition')
        .split(';')
        .find((n) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
    }

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

export const sendManyTo = async (files, email) => {
  const config = {
    method: 'POST',
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
    },
    body: JSON.stringify({ ...email, files }),
  };
  const response = await fetch(`${process.env.REACT_APP_API_URL}/file/send/many`, config);
  return response;
};

export const sendTo = async (type, id, email) => {
  let response;
  if (!['file', 'folder', 'case'].includes(type)) {
    console.warn(`type should by file, folder or case. It can't ${type}.`);
  } else {
    const config = {
      method: 'POST',
      headers: {
        Authorization: `${localStorage.getItem('token')}`,
        agency: `${localStorage.getItem('agency')}`,
      },
      body: JSON.stringify(email),
    };
    response = await fetch(`${process.env.REACT_APP_API_URL}/${type}/send/${id}`, config);
  }
  return response;
};

export const loadOptionsCase = async (search = '') => {
  const config = {
    method: 'POST',
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      status: ['Litige', 'En attente', 'En cours'],
      search,
    }),
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/case/search`, config);
    const data = await response.json();
    const opts = data?.cases.map((c) => ({
      status: c.status,
      label: `#${c.ref} - ${c.client}`,
      value: c._id,
    }));
    return opts;
  } catch (e) {
    return [];
  }
};

export const loadOptionsTasks = async (caseId) => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
    },
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/task?caseId=${caseId}`, config);
    const data = await response.json();
    let opts = [];
    if (data.tasks) {
      opts = data.tasks.map((d) => ({
        value: d._id,
        label: ` ${d.case.client} #${d.case.ref} - ${d.type}`,
      }));
    }
    return opts;
  } catch (e) {
    return [];
  }
};

export const loadOptionsTransports = async (caseId) => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
    },
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/transport?case=${caseId}`, config);
    const data = await response.json();
    let opts = [];
    if (data.transports) {
      opts = data.transports.map((d) => {
        let label = `${d.cases.map((c) => c.client).join(' | ')}`;
        if (d.startDate && d.endDate) {
          label += ` ${dateStringDot(new Date(d.startDate))} - ${dateStringDot(new Date(d.endDate))}`;
        }
        return {
          value: d._id,
          label,
        };
      });
    }
    return opts;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export async function createTask(data) {
  const requestUrl = `${process.env.REACT_APP_API_URL}/task`;
  const config = {};
  let res = null;
  let error = null;
  try {
    const token = localStorage.getItem('token');
    const agency = localStorage.getItem('agency') || null;
    config.headers = { Authorization: `${token}`, agency };
    res = await axios.post(requestUrl, data, config);
  } catch (err) {
    error = err;
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
}

export async function updateTask(data, id) {
  const requestUrl = `${process.env.REACT_APP_API_URL}/task/${id}`;
  const config = {};
  let res = null;
  let error = null;
  try {
    const token = localStorage.getItem('token');
    const agency = localStorage.getItem('agency') || null;
    config.headers = { Authorization: `${token}`, agency };
    res = await axios.put(requestUrl, data, config);
  } catch (err) {
    error = err;
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
}
