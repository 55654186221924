import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import styles from './input-email.module.scss';

const InputEmail = ({
  name,
  control,
  required = false,
  requiredMessage = 'Ce champs email est obligatoire',
  widthLabel = null,
  inline = false,
  label = null,
  placeholder = '',
  className,
}) => (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? requiredMessage : false,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: 'Veuillez renseigner une adresse email valide',
        },
      }}
      render={({
        field: {
          // name,
          value = '',
          ref,
          onChange,
          onBlur,
        },
      }) => (
          <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input-email']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input-email']}`}>
            { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
            <input
              ref={ref}
              value={value}
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              className={styles.input}
              type="email"
              placeholder={placeholder}
            />
          </div>
      )}
    />

);

InputEmail.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputEmail;
