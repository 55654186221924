import React, { useEffect } from 'react';
import { parse as parseDate, isValid as isValidDate } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { IoIosAdd } from 'react-icons/io';
import usePrevious from '../../hooks/usePrevious';
import { dateStringDot } from '../../utils';
import SelectArrayField from './SelectArrayField';
import filterGreen from '../../assets/images/icons/filter-green.svg';
import filterWhite from '../../assets/images/icons/filter-white.svg';
import styles from './case-filters.module.scss';

import {
  InputText,
  DatePicker,
} from '../../lib/HooksFormFields';

const CaseFilters = ({ isOpened = true, setIsOpened }) => {
  const {
    control,
    setValue,
    reset,
    watch,
  } = useForm();
  const searchReducer = useSelector((store) => store.searchReducer);
  const { list } = searchReducer;
  const [searchParams, setSearchParams] = useSearchParams();

  const watchSearch = watch('search');
  const prevSearch = usePrevious(watchSearch);
  const watchDate = watch('creationDate');
  const prevDate = usePrevious(watchDate);

  // HANDLE CLOSE
  // RESET EVERYTHING EXCEPT SEARCH TXT
  useEffect(() => {
    if (isOpened === null || isOpened) return;
    const params = new URLSearchParams(searchParams);
    const search = Object.fromEntries(params);
    const { search: searchTxt = '' } = search;
    params.delete('users');
    params.delete('status');
    params.delete('clients');
    params.delete('creationDate');

    setSearchParams(params);
    reset({
      search: searchTxt,
      users: [],
      clients: [],
      status: [],
      creationDate: null,
    });
  }, [isOpened]);

  // INIT INPUTS VALUES
  useEffect(() => {
    if (!list.users?.length || !list.status?.length) return;
    const params = new URLSearchParams(searchParams);
    const search = Object.fromEntries(params);
    const {
      search: searchTxt = '',
      users = '',
      status = '',
      clients = '',
      creationDate,
    } = search;

    const initUsers = list.users.filter((opt) => users.split(',').includes(opt.value));
    const initClients = list.clients.filter((opt) => clients.split(',').includes(opt.value));
    const initStatus = list.status.filter((opt) => status.split(',').includes(opt.value));
    let initDate = null;

    if (creationDate) {
      initDate = parseDate(creationDate, 'dd.MM.yy', new Date());
      if (!isValidDate(initDate)) initDate = null;
    }

    reset({
      search: searchTxt,
      users: initUsers,
      clients: initClients,
      status: initStatus,
      creationDate: initDate,
    });
  }, [list]);

  // SET SEARCH PARAMS DEPENDING ON INPUTS VALUE
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    if (watchSearch) params.set('search', watchSearch);
    else if (prevSearch && !watchSearch) params.delete('search');

    if (watchDate) params.set('creationDate', dateStringDot(watchDate));
    else if (prevDate && !watchDate) params.delete('creationDate');

    setSearchParams(params);
  }, [
    watchDate,
    watchSearch,
  ]);

  function handleReset() {
    setSearchParams(new URLSearchParams());
    reset({
      search: '',
      commercials: [],
      status: [],
      clients: [],
      creationDate: null,
    });
  }

  function getClassNameStatus(status) {
    let className = 'pending';
    switch (status) {
      case 'Litige':
        className = 'dispute';
        break;
      case 'En attente':
        className = 'pending';
        break;
      case 'En cours':
        className = 'in-progress';
        break;
      case 'Archivés':
        className = 'archived';
        break;
      default:
        className = 'pending';
        break;
    }
    return className;
  }

  return (
    <div className={styles.caseFilters}>
      <div className={styles.containerField}>
        <div className={styles.fieldSearch}>
          <InputText
            name="search"
            control={control}
            placeholder="Rechercher..."
            className='primary'
          />
          <div
            className={`${styles.toggle}${isOpened ? ` ${styles.actived}` : ''}`}
            onClick={() => setIsOpened((state) => !state)}
          >
            {isOpened
              ? <img alt="ouvrir les filtres" src={filterWhite} />
              : <img alt="fermer les filtres" src={filterGreen} />
            }
          </div>
        </div>
      </div>
      <div className={`${styles.advancedFilters}${isOpened ? ` ${styles.opened}` : ''}`}>
        <div className={styles.containerField}>
          <SelectArrayField
            name="users"
            options={list.users}
            control={control}
            setValue={setValue}
            placeholder="Commercial"
            item={(field, index, removeItem) => (
              <div key={field.value} className={styles['item-commercial']}>
                <div className={styles.profile}>
                  <span className={styles.initials}>
                    <p>{field.initials}</p>
                  </span>
                  <p>{field.label} {field?.role?.label}</p>
                </div>
                <span className={styles.delete} onClick={() => removeItem(field.value, index)}>
                  <IoIosAdd />
                </span>
              </div>
            )}
          />
        </div>
        <div className={styles.containerField}>
          <SelectArrayField
            name="status"
            options={list.status}
            control={control}
            setValue={setValue}
            placeholder="Status"
            item={(field, index, removeItem) => (
              <div key={field.value} className={styles['item-status']}>
                <div className={`${styles.status} ${styles[getClassNameStatus(field.label)]}`}></div>
                <p>{field.label}</p>
                <span className={styles.delete} onClick={() => removeItem(field.value, index)}>
                  <IoIosAdd />
                </span>
              </div>
            )}
          />
        </div>
        <div className={styles.containerField}>
          <SelectArrayField
            name="clients"
            options={list.clients}
            control={control}
            setValue={setValue}
            placeholder="Client"
            item={(field, index, removeItem) => (
              <div key={field.value} className={styles['item-client']}>
                <p>{field.label}</p>
                <span className={styles.delete} onClick={() => removeItem(field.value, index)}>
                  <IoIosAdd />
                </span>
              </div>
            )}
          />
        </div>
        <div className={styles.containerField}>
          <DatePicker
            name="creationDate"
            control={control}
            widthLabel={'30%'}
            required
            placeholder="Date de création..."
            className='primary'
          />
        </div>
        <div className={styles.reset}>
          <p onClick={() => handleReset()}>Reset</p>
        </div>
      </div>
    </div>
  );
};

export default CaseFilters;
