import React from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from '../styled-select.module.scss';
import { styleBase, stylePrimary, styleDark } from '../style';

const StyledCreatable = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  placeholder = '',
  labelCreate = 'Créer : ',
  noOptionsMessage = 'Aucune option correspondante',
  label = null,
  icon = null,
  className,
  isClearable = false,
  defaultValue,
  handleChange,
  ...props
}) => {
  function getClassName() {
    let style;
    if (className === 'primary') style = stylePrimary;
    else if (className === 'dark') style = styleDark;
    else style = styleBase;
    return style;
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <CreatableSelect
            {...field}
            {...props}
            onChange={(value) => {
              field.onChange(value);
              if (handleChange) handleChange(value);
            }}
            defaultValue={defaultValue}
            isClearable={isClearable}
            components={{
              DropdownIndicator: (p) => (icon || components.DropdownIndicator(p)),
            }}
            formatCreateLabel={(value) => `${labelCreate}${value}`}
            className={`${styles.select}`}
            placeholder={placeholder}
            noOptionsMessage={() => `${noOptionsMessage}`}
            styles={getClassName()}
          />
        </div>
      )}
    />
  );
};

StyledCreatable.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  labelCreate: PropTypes.string,
};

export default StyledCreatable;
