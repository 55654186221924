import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../actions/auth';
import ResetPwdForm from '../../components/form/AuthForm/ResetPwdForm';
import styles from './reset-password.module.scss';

const ResetPassword = () => {
  const authReducer = useSelector((d) => d.authReducer);
  const [succeed, setSucceed] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/home');
  }, [authReducer.user]);

  function submitReset(data) {
    resetPassword(dispatch, { password: data.password, token: params?.token || '' }).then((res) => {
      if (res?.status === 200) setSucceed(true);
    });
  }

  return (
    <div className={styles['reset-password']}>
      <div className={styles.col}></div>
      <div className={styles.col}>
        <div className={styles['container-form']}>
          <h1>Mot de passe oublié</h1>
          <ResetPwdForm
            submit={submitReset}
            signinLink={succeed ? '/' : null}
            succeedMessage={succeed ? 'Votre mot de passe a été mis à jour.' : null}
            errorMessage={authReducer.error}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
