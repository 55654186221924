import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useReducer,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import produce, { current } from 'immer';
import cloneDeep from 'clone-deep';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  addDays, getDayOfYear, setDayOfYear, formatISO, // format,
} from 'date-fns';
// import { fr } from 'date-fns/locale';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronUp,
  BiChevronDown,
} from 'react-icons/bi';
import { FiRefreshCcw, FiArrowLeft } from 'react-icons/fi';
import { IoIosAdd } from 'react-icons/io';
import { BsSearch } from 'react-icons/bs';
import {
  getTasks,
  updateTasks,
} from '../../actions/task';
import { downloadArticlesTask } from '../../utils';
import RightModalStandalone from '../../lib/RightModalStandalone';
import ModalStandalone from '../../lib/ModalStandalone';
import CreateTaskForm from '../../components/form/TaskForm/CreateTaskForm';
import PlanningTasks from '../../components/PlanningTasks';
import Task from '../../components/Task';
import {
  DatePicker,
  InputText,
  StyledSelect,
} from '../../lib/HooksFormFields';
import styles from './parc.module.scss';
import TaskArticles from '../../components/Task/TaskArticles';
import TaskLegend from '../../components/TaskLegend';

const refrestTiming = 20 * 1000;

const loadOptions = async () => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      agency: `${localStorage.getItem('agency')}`,
    },
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/task/list`, config);
    const data = await response.json();
    return data.list;
  } catch (e) {
    return [];
  }
};

const dragReducer = produce((draft, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'SET': {
      const data = action.payload || {};
      const draftEntries = Object.keys(current(draft));
      draftEntries.forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        if (key !== 'notAssign') delete draft[key];
      });
      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        draft[key] = data[key];
      });
      break;
    }
    case 'MOVE': {
      // eslint-disable-next-line no-param-reassign
      draft[action.from] = action.source || [];
      // eslint-disable-next-line no-param-reassign
      draft[action.to] = action.destination || [];
    }
  }
});

const viewOpts = [
  { label: 'Vue semaine', value: 'week' },
  { label: 'Vue jour', value: 'day' },
];

const Parc = () => {
  const dispatch = useDispatch();
  const planningRef = useRef();
  const createTaskRef = useRef();
  const modalArticles = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const { authReducer, taskReducer } = useSelector((store) => store);
  const { selectedAgency, user: { rights = {} } } = authReducer;
  const { tasks, changedAt, isLoading } = taskReducer;
  const defaultView = 'day';
  const [taskType, setTaskType] = useState();
  const [week, setWeek] = useState();
  const [assignableTaskOpen, setAssignableTaskOpen] = useState(true);
  const [dateNotAssignedChanged, setDateNotAssignedChanged] = useState(false);
  const [listOpts, setListOpts] = useState();

  const idleTimestamp = useRef(Date.now());
  const taskArticlesRef = useRef();

  const initialData = { notAssign: [] };
  const [state, dispatchDrag] = useReducer(dragReducer, initialData);
  const {
    control,
    // reset,
    watch,
    getValues,
    setValue,
  } = useForm();
  const notAssignStartDate = watch('notAssignStartDate');
  const notAssignEndDate = watch('notAssignEndDate');
  const watchAssignedTo = watch('assignedTo');
  const watchType = watch('type');
  const watchPriority = watch('priority');

  const paramDay = searchParams.get('day');
  const paramView = searchParams.get('view') || defaultView;
  const paramsSearch = useDebounce(searchParams.get('search'));

  // Time range for assignated tasks
  const timerange = useMemo(() => {
    const { view = 'day' } = Object.fromEntries(searchParams.entries());
    let foundParamDay = searchParams.get('day');
    if (/^(-|\d)[0-9]*$/g.test(foundParamDay)) {
      foundParamDay = parseInt(foundParamDay, 10);
      if (foundParamDay < (365 * -3)) foundParamDay = parseInt(getDayOfYear(new Date()), 10);
    } else {
      foundParamDay = getDayOfYear(new Date());
    }

    const range = {
      startDate: null,
      endDate: null,
    };

    if (view === 'week') {
      const curr = setDayOfYear(new Date(), foundParamDay);
      const first = (curr.getDate() - curr.getDay()) + 1;
      const startDate = new Date(curr.setDate(first));
      // const endDate = new Date(curr.setDate(first + 6));
      const endDate = addDays(startDate, 6);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);
      range.startDate = startDate.toISOString();
      range.endDate = endDate.toISOString();
    } else if (view === 'day') {
      const startDate = setDayOfYear(new Date(), foundParamDay);
      const endDate = setDayOfYear(new Date(), foundParamDay);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);
      range.startDate = startDate.toISOString();
      range.endDate = endDate.toISOString();
    }

    return range;
  }, [searchParams]);

  // Init Datepicker date for filter notAssignedTasks
  useEffect(() => {
    if (!tasks || tasks.length === 0) return;

    const notAssignStartDateDefault = tasks.find((task) => task.date).date;
    const notAssignEndDateDefault = tasks[tasks.length - 1].date;

    if (timerange?.startDate && !dateNotAssignedChanged) setValue('notAssignStartDate', new Date(notAssignStartDateDefault));
    if (timerange?.endDate && !dateNotAssignedChanged) setValue('notAssignEndDate', new Date(notAssignEndDateDefault));
  }, [
    tasks,
  ]);

  // INIT TASKS
  useEffect(() => {
    if (!tasks || !notAssignStartDate || !notAssignEndDate) {
      return;
    }
    const data = { notAssign: [] };

    tasks.forEach((task) => {
      const key = getDayOfYear(new Date(task.date));

      const isInTimeRange = (
        new Date(task.date).getTime() >= new Date(timerange.startDate).getTime()
        && new Date(task.date).getTime() <= new Date(timerange.endDate).getTime()
      );

      const notAssignStartDateValue = getValues('notAssignStartDate');
      const notAssignEndDateValue = getValues('notAssignEndDate');
      const isInNotAssignedTimeRange = (
        new Date(task.date).getTime() >= notAssignStartDateValue.getTime()
        && new Date(task.date).getTime() <= notAssignEndDateValue.getTime()
      );

      if (task.assignedTo.length === 0 && isInNotAssignedTimeRange) {
        data.notAssign.push(task);
      } else if (task.date && !Number.isNaN(key) && isInTimeRange) {
        if (!data[`${key}-${task.status}`]) data[`${key}-${task.status}`] = [];
        data[`${key}-${task.status}`].push(task);
        data[`${key}-${task.status}`].sort((a, b) => a.position - b.position);
      }
    });

    data.notAssign = data.notAssign.sort((a, b) => {
      if (!b.date) return 1;
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    dispatchDrag({
      type: 'SET',
      payload: data,
    });
  }, [
    tasks,
    notAssignStartDate,
    notAssignEndDate,
    timerange,
    changedAt,
  ]);

  // HANDLE SELECT TASK ID
  // FOR ARTICLES DETAIL
  useEffect(() => {
    if (!modalArticles?.current) return;
    const taskId = searchParams.get('taskId');
    if (taskId) {
      modalArticles.current.open();
    } else {
      modalArticles.current.close();
    }
  }, [searchParams]);

  // GET LIST OPTS
  useEffect(() => {
    const getList = async () => {
      const list = await loadOptions();
      const options = {};
      Object.keys(list).forEach((key) => {
        const arr = list[key];
        const opts = arr.map((d) => {
          if (key === 'users') {
            return ({ value: d._id, label: `${d.profile.firstName} ${d.profile.lastName}`, item: d });
          }
          if (key === 'cases') {
            return ({ value: d._id, label: `${d.client} - #${d.ref}` });
          }
          return ({ value: d, label: d });
        });
        options[key] = opts;
      });
      setListOpts(options);
    };
    getList();
  }, [selectedAgency]);

  const handleGetTasks = () => {
    const {
      type, priority, assignedTo, search, ...params
    } = Object.fromEntries(searchParams.entries());

    if (type) params.type = type;
    if (priority) params.type = priority;
    if (assignedTo) params.assignedTo = assignedTo;
    if (search) params.search = search;

    let currentDay;

    if (/^(-|\d)[0-9]*$/g.test(paramDay)) {
      currentDay = parseInt(paramDay, 10);
      if (currentDay < (365 * -3)) currentDay = parseInt(getDayOfYear(new Date()), 10);
    } else {
      currentDay = getDayOfYear(new Date());
    }

    if (paramView === 'week') {
      const curr = setDayOfYear(new Date(), currentDay);
      const first = (curr.getDate() - curr.getDay()) + 1;
      const startDate = new Date(curr.setDate(first));
      const endDate = addDays(startDate, 6);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);
      params.startDate = startDate.toISOString();
      params.endDate = endDate.toISOString();
    } else if (paramView === 'day') {
      const startDate = setDayOfYear(new Date(), currentDay);
      const endDate = setDayOfYear(new Date(), currentDay);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);
      params.startDate = startDate.toISOString();
      params.endDate = endDate.toISOString();
    }
    getTasks(dispatch, {
      ...params,
    });
  };

  // GET TASKS
  useEffect(() => {
    handleGetTasks();
  }, [
    paramDay,
    paramView,
    selectedAgency,
    watchAssignedTo,
    watchType,
    watchPriority,
    paramsSearch,
  ]);

  // HANDLE CANCEL TRIGGER REFRESH TASKS
  useEffect(() => {
    const handleResetIdleTimer = () => {
      idleTimestamp.current = Date.now();
    };
    document.addEventListener('keyup', handleResetIdleTimer);
    document.addEventListener('click', handleResetIdleTimer);
    document.addEventListener('wheel', handleResetIdleTimer);
    return () => {
      document.addEventListener('keyup', handleResetIdleTimer);
      document.removeEventListener('wheel', handleResetIdleTimer);
      document.removeEventListener('click', handleResetIdleTimer);
    };
  }, []);

  // HANDLE REFRESH TASKS WHEN NO ACTION IS DONE FOR A WHILE
  useEffect(() => {
    const timer = setTimeout(() => {
      const modal = document.querySelector('#modal');
      // PREVENT REFRESH WHEN MODAL IS OPEN
      if (modal.childNodes.length === 0) handleGetTasks();
      idleTimestamp.current = Date.now();
    }, refrestTiming);
    return () => {
      clearTimeout(timer);
    };
  }, [idleTimestamp.current]);

  function setSearchParam(param, d) {
    const params = new URLSearchParams(searchParams);
    params.set(param, d);
    setSearchParams(params);
  }

  function deleteSearchParam(param) {
    const params = new URLSearchParams(searchParams);
    params.delete(param);
    setSearchParams(params);
  }

  // RESET ASSIGNED TO WHEN SELECTED AGENCY CHANGE
  useEffect(() => {
    deleteSearchParam('assignedTo');
    setValue('assignedTo', null);
    setValue('notAssignStartDate', null);
    setValue('notAssignEndDate', null);
    dispatchDrag({
      type: 'SET',
      payload: {
        notAssign: [],
      },
    });
  }, [selectedAgency]);

  const onDragEnd = useCallback((result) => {
    if (result.reason === 'DROP') {
      if (!result.destination) {
        return;
      }

      const [day, status] = result.destination.droppableId.split('-');
      let date = setDayOfYear(new Date(), day);
      date = formatISO(date);
      const updatedTask = tasks.find((task) => task._id === result.draggableId);

      // UPDATE TASK POSITION
      let updatedTasks = [];

      // DESTINATION TASKS
      let destinationTasks = cloneDeep(state[result.destination.droppableId]) || [];
      const sameDropzone = result.source.droppableId === result.destination.droppableId;
      if (!sameDropzone) {
        destinationTasks.splice(result.destination.index, 0, {
          ...updatedTask,
          _id: result.draggableId,
          date,
          status,
        });
      } else {
        const b = destinationTasks[result.source.index];
        destinationTasks[result.source.index] = destinationTasks[result.destination.index];
        destinationTasks[result.destination.index] = b;
      }

      destinationTasks = destinationTasks.map((task, index) => {
        let obj = {};
        obj = { ...task, position: index };
        return obj;
      });
      updatedTasks = [...destinationTasks];
      // SOURCE TASKS
      let sourceTasks = cloneDeep(state[result.source.droppableId]) || [];
      if (!sameDropzone) {
        sourceTasks.splice(result.source.index, 1);
        sourceTasks = sourceTasks.map((task, index) => ({ ...task, position: index }));
        updatedTasks = [...updatedTasks, ...sourceTasks];
      }
      updateTasks(dispatch, updatedTasks);
      //

      dispatchDrag({
        type: 'MOVE',
        from: result.source.droppableId,
        to: result.destination.droppableId,
        source: sourceTasks,
        destination: destinationTasks,
        fromIndex: result.source.index,
        toIndex: result.destination.index,
        updatedTask: {
          ...updatedTask,
          date,
          status,
        },
      });
    }
  }, [tasks, state]);

  const handleCloseModalArticles = () => {
    deleteSearchParam('taskId');
    modalArticles.current.close();
  };

  const handleGetArticlesPdf = () => {
    const taskId = searchParams.get('taskId');
    if (taskId) downloadArticlesTask(taskId, `articles-${taskId}`);
  };

  const getItemStyle = (isDragging, draggableStyle, index) => ({
    userSelect: 'none',
    margin: 0,
    zIndex: state.notAssign.length - index,
    ...draggableStyle,
  });

  function getDefaultViewValue() {
    return viewOpts.find((opt) => opt.value === (paramView || defaultView));
  }

  function getDefaultPriorityValue() {
    const paramPriority = searchParams.get('priority');
    return listOpts?.priorities.find((opt) => opt.value === paramPriority);
  }

  function getDefaultTypeValue() {
    const paramType = searchParams.get('type');
    return listOpts?.types.find((opt) => opt.value === paramType);
  }

  function getDefaultAssignedToValue() {
    const paramType = searchParams.get('assignedTo');
    return listOpts?.users.find((opt) => opt.value === paramType);
  }

  const notAssignTasksFiltered = useMemo(() => {
    let tasksFiltered = state?.notAssign;
    if (taskType === 'departure') {
      tasksFiltered = tasksFiltered.filter((d) => ['Préparation', 'Chargement'].includes(d.type));
    } else if (taskType === 'return') {
      tasksFiltered = tasksFiltered.filter((d) => ['Déchargement', 'Retour'].includes(d.type));
    }

    tasksFiltered = tasksFiltered.slice().sort((a, b) => {
      const dateA = a.date ? new Date(a.date) : new Date(0);
      const dateB = b.date ? new Date(b.date) : new Date(0);
      return dateA.getTime() - dateB.getTime();
    });

    return tasksFiltered;
  }, [
    taskType,
    state?.notAssign,
  ]);

  return (
    <>
      {rights.write && (
        <ModalStandalone ref={createTaskRef} handleClose={() => { }}>
          <CreateTaskForm handleClose={() => createTaskRef.current.close()} />
        </ModalStandalone>
      )}
      <RightModalStandalone ref={modalArticles} zIndex={2000}>
        <div className={styles.modalArticles}>
          <div className={styles.header}>
            <div className={styles.back}>
              <button className='light' onClick={() => handleCloseModalArticles()}>
                <span className='icon'><FiArrowLeft size={'18px'} /></span>
                <span>Retour</span>
              </button>
            </div>
            <button onClick={() => handleGetArticlesPdf()}>
              Générer un document
            </button>
          </div>
          <TaskArticles
            ref={taskArticlesRef}
            taskId={searchParams.get('taskId')}
            handleClose={() => handleCloseModalArticles()}
          />
        </div>
      </RightModalStandalone>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={styles.parc}>
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.filters}>
                <h3>Tâches à planifier</h3>
                <p className={taskType === 'departure' ? styles.selected : ''} onClick={() => setTaskType((type) => (type === 'departure' ? '' : 'departure'))}>Départ</p>
                <span> / </span>
                <p className={taskType === 'return' ? styles.selected : ''} onClick={() => setTaskType((type) => (type === 'return' ? '' : 'return'))}>Retour</p>
                {notAssignStartDate && notAssignEndDate && (
                  <div className={styles.filterNotAssigned}>
                    <DatePicker
                      name="notAssignStartDate"
                      control={control}
                      widthLabel={'30%'}
                      className='primary'
                      handleChange={() => {
                        setDateNotAssignedChanged(true);
                      }}
                    />
                    <DatePicker
                      name="notAssignEndDate"
                      control={control}
                      widthLabel={'30%'}
                      className='primary'
                      handleChange={() => {
                        setDateNotAssignedChanged(true);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className={styles.fieldSearch}>
                <InputText
                  name="search"
                  control={control}
                  defaultValue={searchParams.get('search')}
                  placeholder="Rechercher..."
                  className='primary'
                  onChange={(search) => {
                    if (!search) deleteSearchParam('search');
                    else {
                      setSearchParam('search', search);
                    }
                  }}
                />
                <div className={styles.icon}>
                  <BsSearch />
                </div>
              </div>
            </div>
            <div>
              <TaskLegend />
            </div>
            <div className={styles.notAssignTasks}>
              <div className={styles.containerBtn}>
                {rights.write && (
                  <button onClick={() => createTaskRef.current.open()}>
                    <span className='icon'><IoIosAdd size='20px' /></span>
                    <span>Créer une tâche</span>
                  </button>
                )}
                <button onClick={() => handleGetTasks()}>
                  <FiRefreshCcw className={isLoading.includes('TASKS_GET') ? styles.loading : ''}/>
                </button>
              </div>
              <div className={styles.containerTasks}>
              <div className={`${styles.toggle} ${styles.top}`}
                  onClick={() => setAssignableTaskOpen((bool) => !bool)}
                >
                  {assignableTaskOpen
                    ? <BiChevronUp size='24' />
                    : <BiChevronDown size='24' />
                  }
                </div>
                <div className={styles.toggle}
                  onClick={() => setAssignableTaskOpen((bool) => !bool)}
                >
                  {assignableTaskOpen
                    ? <BiChevronUp size='24' />
                    : <BiChevronDown size='24' />
                  }
                </div>
                {(assignableTaskOpen && notAssignTasksFiltered.length > 0) ? (
                  <Droppable
                    droppableId={'notAssign'}
                    type="TASK"
                    isDropDisabled={true}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={styles.droppable}
                      >
                        {notAssignTasksFiltered.map((task, index) => (
                          <Draggable
                            key={task._id}
                            draggableId={task._id}
                            index={index}
                            isDragDisabled={
                              !rights.write || task.assignedTo.length === 0 || !task.assignedTo
                            }
                          >
                            {(providedDraggable, snapshotDraggable) => (
                              <div
                                ref={providedDraggable.innerRef}
                                {...providedDraggable.draggableProps}
                                {...providedDraggable.dragHandleProps}
                                style={getItemStyle(
                                  snapshotDraggable.isDragging,
                                  providedDraggable.draggableProps.style,
                                  index,
                                )}
                              >
                                <Task
                                  id={task._id}
                                  name={task.case?.name}
                                  refCase={task.case.ref}
                                  number={task.number}
                                  caseId={task.case._id}
                                  clientName={task.case.client}
                                  type={task.type}
                                  date={task.date}
                                  linkedTask={task.linkedTask}
                                  linkedTransport={task.linkedTransport}
                                  zone={task?.linkedTransport?.zone}
                                  assignement={task.assignedTo}
                                  priority={task.priority}
                                  hasWarning={task.hasWarning}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ) : <div className={styles.containerTasksClosed}>
                  {(notAssignTasksFiltered.length === 0 || !notAssignTasksFiltered)
                    ? <p>Aucune tâche à planifier</p>
                    : <p>
                      <span>{notAssignTasksFiltered.length}</span>
                      Tâche{notAssignTasksFiltered.length > 1 && 's'} à planifier
                    </p>
                  }
                </div>
                }
              </div>
            </div>
            <div className={styles.planningActions}>
              <div className={styles.weekNav}>
                <h3>Semaine {week}</h3>
                <button onClick={() => planningRef.current.prevWeek()}><BiChevronLeft /></button>
                <button onClick={() => planningRef.current.nextWeek()}><BiChevronRight /></button>
              </div>
              <div className={styles.containerSelect}>
                <StyledSelect
                  name="view"
                  control={control}
                  className='primary'
                  defaultValue={getDefaultViewValue()}
                  options={viewOpts}
                  handleChange={(opt) => {
                    planningRef.current.setViewType(opt.value);
                  }}
                />
              </div>
              <button className='third' onClick={() => planningRef.current.selectToday()}>Aujourd'hui</button>
              <div className={styles.filters}>
                {listOpts?.users && (
                  <div className={styles.containerSelect}>
                    <StyledSelect
                      name="assignedTo"
                      control={control}
                      className='primary'
                      placeholder='Affectation: toutes'
                      options={listOpts?.users}
                      defaultValue={getDefaultAssignedToValue()}
                      isClearable
                      isSearchable
                      handleChange={(opt) => {
                        if (!opt?.value) deleteSearchParam('assignedTo');
                        else setSearchParam('assignedTo', opt.value);
                      }}
                    />
                  </div>
                )}
                {listOpts?.priorities && (
                  <div className={styles.containerSelect}>
                    <StyledSelect
                      name="priority"
                      control={control}
                      className='primary'
                      placeholder='Priorité: toutes'
                      options={listOpts?.priorities}
                      defaultValue={getDefaultPriorityValue()}
                      isClearable
                      handleChange={(opt) => {
                        if (!opt?.value) deleteSearchParam('priority');
                        else setSearchParam('priority', opt.value);
                      }}
                    />
                  </div>
                )}
                {listOpts?.types && (
                  <div className={styles.containerSelect}>
                    <StyledSelect
                      name="type"
                      control={control}
                      className='primary'
                      placeholder='Type: toutes'
                      options={listOpts?.types}
                      defaultValue={getDefaultTypeValue()}
                      isClearable
                      handleChange={(opt) => {
                        if (!opt?.value) deleteSearchParam('type');
                        else setSearchParam('type', opt.value);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <PlanningTasks
              ref={planningRef}
              defaultView={defaultView}
              listTasks={state}
              handleChangeWeek={(w) => setWeek(w)}
            />
          </div>
        </div>
      </DragDropContext >
    </>
  );
};

export default Parc;
