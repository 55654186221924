import { parse as parseDate, isValid as isValidDate } from 'date-fns';

import {
  getData,
  postData,
} from './index';
import {
  SEARCH_GET,
  SEARCH_GET_LIST,
  SEARCH_LOADING,
  SEARCH_ERROR,
} from './types';

export const getSearch = async (dispatch, searchParams, list) => {
  const params = new URLSearchParams(searchParams);
  const search = Object.fromEntries(params);
  const {
    search: searchTxt = null,
    users = null,
    status = null,
    clients = null,
    creationDate,
  } = search;
  const arrUsers = users?.split(',') || null;
  let arrStatus = status?.split(',') || null;
  let arrClients = clients?.split(',') || null;

  if (arrStatus && list.status) {
    arrStatus = list.status.filter((d) => arrStatus.includes(d.value)).map((d) => d.label);
  }
  if (arrClients && list.clients) {
    arrClients = list.clients.filter((d) => arrClients.includes(d.value)).map((d) => d.label);
  }

  let createdAt = parseDate(creationDate, 'dd.MM.yy', new Date());
  if (!isValidDate(createdAt)) createdAt = null;

  const url = '/case/search';
  dispatch({
    type: SEARCH_LOADING,
    payload: SEARCH_GET,
  });
  const response = await postData(SEARCH_ERROR, url, dispatch, {
    users: arrUsers,
    clients: arrClients,
    status: arrStatus,
    creationDate: createdAt,
    search: searchTxt,
  }, true);
  if (response.data.cases && response.data.files) {
    dispatch({
      type: SEARCH_GET,
      payload: response.data,
    });
  }
};

export const getSearchList = async (dispatch) => {
  const url = '/case/list';
  dispatch({
    type: SEARCH_LOADING,
    payload: SEARCH_GET_LIST,
  });
  const response = await getData(SEARCH_ERROR, url, dispatch, true);
  if (response.data.list) {
    dispatch({
      type: SEARCH_GET_LIST,
      payload: response.data.list,
    });
  }
};
