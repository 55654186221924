import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';

import styles from './select-task-user.module.scss';
import {
  StyledSelect,
} from '../../../../lib/HooksFormFields';

const SelectTaskUser = ({
  optsUser,
  control,
}) => {
  const {
    control: control2,
    watch,
    setValue,
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'assignedTo', // unique name for your Field Array
  });
  const selectedUser = watch('select-user');

  useEffect(() => {
    if (selectedUser) {
      setValue('select-user', null);
      append(selectedUser);
    }
  }, [selectedUser]);

  function handleDelete(index) {
    remove(index);
  }

  function filterOptions(opts) {
    const membersId = fields.map((member) => member.value);
    const filteredOpts = opts.filter((opt) => !membersId.includes(opt.value));
    return filteredOpts.sort((a, b) => a.label.localeCompare(b.label));
  }

  return (
    <div className={styles.assignedTo}>
      <div className={styles['add-users']}>
        <StyledSelect
          name="select-user"
          label={'Attribuer à'}
          placeholder="Choisir une personne"
          inline
          isSearchable
          control={control2}
          icon={<div className={styles.icon}><BsSearch /></div>}
          options={filterOptions(optsUser)}
        />
      </div>
      <div className={styles.users}>
        {fields.map((field, index) => (
          <div key={field.id} className={styles.user}>
            <div className={styles.profile}>
              <span className={styles.initial}>
                <p>{field.initial}</p>
              </span>
              <p>{field.label}</p>
            </div>
            <span className={styles.delete} onClick={() => handleDelete(index, field.label)}>
              <AiOutlineDelete />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectTaskUser;
