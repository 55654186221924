import {
  TASKS_GET,
  TASK_SELECT,
  TASK_POST,
  TASK_PUT,
  TASKS_PUT,
  TASK_DELETE,
  TASK_LOADING,
  TASK_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  tasks: null,
  selectedTask: null,
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const taskReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  let updateTasks = state.tasks || [];
  const taskIndex = (updateTasks || []).findIndex((p) => (
    p._id === action.payload?.task?._id
  ));
  switch (action.type) {
    case TASKS_GET:
      updatedState = {
        ...state,
        tasks: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== TASKS_GET),
        error: null,
      };
      break;
    case TASK_SELECT:
      updatedState = {
        ...state,
        selectedTask: action.payload,
      };
      break;
    case TASK_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        tasks: [action.payload, ...state.tasks],
        isLoading: state.isLoading?.filter((d) => d !== TASK_POST),
        error: null,
      };
      break;
    case TASK_PUT:
      if (typeof taskIndex === 'number' && updateTasks[taskIndex].agency === action.payload.task.agency) {
        updateTasks[taskIndex] = action.payload.task;
      } else if (updateTasks[taskIndex].agency !== action.payload.task.agency) {
        // If the agency change remove the tasks from the current list
        updateTasks = updateTasks.filter((task) => task._id !== action.payload.task._id);
      }

      updatedState = {
        ...state,
        selectedTask: null,
        tasks: updateTasks,
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter((d) => d !== `TASK_PUT-${action.payload.task._id}`),
        error: null,
      };
      break;
    case TASK_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        selectedTask: null,
        tasks: state.tasks.filter((task) => task._id !== action.payload),
        isLoading: state.isLoading?.filter((d) => d !== TASK_DELETE),
        error: null,
      };
      break;
    case TASKS_PUT:
      // UPDATE TASKS
      action.payload.forEach((task) => {
        const index = (updateTasks || []).findIndex((p) => (
          p._id === task._id
        ));
        if (typeof taskIndex === 'number') {
          updateTasks[index] = {
            ...updateTasks[index],
            ...task,
          };
        }
      });
      updatedState = {
        ...state,
      };
      break;
    case TASK_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case TASK_ERROR:
      updatedState = { ...state };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default taskReducer;
