import React, { useState } from 'react';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';
import { TiWarning } from 'react-icons/ti';
import load from '../../assets/images/task-types/load.svg';
import unload from '../../assets/images/task-types/unload.svg';
import prepare from '../../assets/images/task-types/prepare.svg';
import review from '../../assets/images/task-types/review.svg';
import styles from './task-legend.module.scss';

const TaskLegend = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.legend}>
      <div className={isOpen ? `${styles.header} ${styles.open}` : styles.header} onClick={() => setIsOpen((state) => !state)}>
        <p>Légende types de tâche</p>
        {isOpen ? <IoIosRemove /> : <IoIosAdd />}
      </div>
      {(isOpen) && (
        <ul>
          <li>
            <span className={styles.icon}><TiWarning /></span>
            <p>
              DAC, PB, REL
            </p>
          </li>
          <li>
            <div className={styles.icon}>
              <img src={prepare} alt="Préparation" />
            </div>
            <p>Préparation</p>
          </li>
          <li>
            <div className={styles.icon}>
              <img src={load} alt="Chargement" />
            </div>
            <p>Chargement</p>
          </li>
          <li>
            <div className={styles.icon}>
              <img src={unload} alt="Déchargement" />
            </div>
            <p>Déchargement</p>
          </li>
          <li>
            <div className={styles.icon}>
              <img src={review} alt="Retour" />
            </div>
            <p>Retour</p>
          </li>
        </ul>
      )}
    </div>
  );
};

export default TaskLegend;
