import {
  getData,
  postData,
  putData,
  deleteData,
} from './index';
import {
  TASKS_GET,
  TASK_SELECT,
  TASK_POST,
  TASK_PUT,
  TASK_DELETE,
  TASKS_PUT,
  TASK_LOADING,
  TASK_ERROR,
} from './types';

export const selectTask = async (dispatch, taskId) => {
  dispatch({
    type: TASK_SELECT,
    payload: taskId,
  });
};

export const getTasks = async (dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/task${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: TASK_LOADING,
    payload: TASKS_GET,
  });
  const response = await getData(TASK_ERROR, url, dispatch, true);
  if (response.data?.tasks) {
    setTimeout(() => {
      dispatch({
        type: TASKS_GET,
        payload: response.data?.tasks,
      });
    }, 500);
  }
};

export const createTask = async (dispatch, data) => {
  const url = '/task';
  dispatch({
    type: TASK_LOADING,
    payload: TASK_POST,
  });
  const response = await postData(TASK_ERROR, url, dispatch, data, true);
  if (response.data?.task) {
    dispatch({
      type: TASK_POST,
      payload: response.data.task,
    });
  }
  return response;
};

export const updateTask = async (dispatch, data) => {
  const url = `/task/${data._id}`;
  const { _id, ...updatedCase } = data;
  dispatch({
    type: TASK_LOADING,
    payload: `${TASK_PUT}-${data._id}`,
  });
  const response = await putData(TASK_ERROR, url, dispatch, updatedCase, true);
  if (response.data.task) {
    dispatch({
      type: TASK_PUT,
      payload: {
        ...response.data,
        task: response.data.task,
      },
    });
  }
  return response;
};

export const updateTasks = async (dispatch, data) => {
  const url = '/task';
  const response = await putData(TASK_ERROR, url, dispatch, { tasks: data }, true);
  dispatch({
    type: TASKS_PUT,
    payload: data,
  });
  return response;
};

export const deleteTask = async (dispatch, id) => {
  const url = `/task/${id}`;
  dispatch({
    type: TASK_LOADING,
    payload: TASK_DELETE,
  });
  const response = await deleteData(TASK_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: TASK_DELETE,
      payload: id,
    });
  }
  return response;
};
