import React from 'react';
import CasesList from '../list/CasesList';
import FilesList from '../list/FilesList';
import styles from './result-search.module.scss';

const ResultSearch = ({ files, cases }) => (
  <React.Fragment>
    <div className={styles.containerCases}>
      <div className={styles.header}>
        <h3>Résultats affaires</h3>
      </div>
      {cases?.length > 0
        ? <CasesList cases={cases} />
        : <p>Aucune affaire ne correspond à cette recherche</p>
      }
    </div>
    <div className={styles.containerFolders}>
      <div className={styles.header}>
        <h3>Résultats documents</h3>
      </div>
      <FilesList files={files} />
    </div>
  </React.Fragment>
);

export default ResultSearch;
