import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Controller, useWatch } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import styles from './input-format-number.module.scss';

const InputFormatNumber = forwardRef(({
  name,
  control,
  format,
  rules,
  defaultValue = '',
  disabled = false,
  inline = false,
  widthLabel = null,
  label = null,
  placeholder = '',
  maxLength = '',
  className,
}, ref) => {
  const [valueInput, setValueInput] = useState('');
  const inputWatch = useWatch({
    control,
    name,
  });

  useImperativeHandle(ref, () => ({
    getValue: () => valueInput,
    getObject: () => ({ [name]: valueInput }),
    // setValue : (str) => setValueInput(str)
  }));

  useEffect(() => {
    setValueInput(inputWatch);
  }, [inputWatch]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: {
          // name,
          value = '',
          // ref,
          onChange,
          onBlur,
        },
      }) => (
        <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input-format-number']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input-format-number']}` } >
          { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <NumberFormat
            // CONTROLLER -> FIELD PROPS
            name={name}
            getInputRef = {ref}
            onBlur={onBlur}
            className={styles.input}
            //
            format={format}
            disabled={disabled}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        </div>
      )}
    />

  );
});

InputFormatNumber.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
};

export default InputFormatNumber;
