import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, useController } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BiChevronRight, BiChevronUp } from 'react-icons/bi';
import { dateStringDot, loadOptionsCase } from '../../utils';
import PositionalModal from '../../lib/PositionalModal';
import Loader from '../Loader';
import dots from '../../assets/images/icons/green-dots.svg';
import deleteIcon from '../../assets/images/icons/delete.svg';
import downloadIcon from '../../assets/images/icons/download.svg';
import renameIcon from '../../assets/images/icons/rename.svg';
import moveFolderIcon from '../../assets/images/icons/folder-move-to.svg';
import styles from './tile-case.module.scss';

const TileFolder = ({
  id,
  lastUpdate,
  name,
  caseRef,
  commercialUser,
  status,
  link,
  initOptsMove,
  actions = null,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalParent = useRef();
  const modalRef = useRef();
  const inputNameRef = useRef();
  const [editName, setEditName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMoveListOpened, setIsMoveListOpened] = useState(false);
  const [optsMove, setOptsMove] = useState(null);
  const [folderMoveSuccess, setFolderMoveSuccess] = useState(false);

  const {
    control,
    watch,
  } = useForm();

  const {
    field,
    fieldState: { value: valueSearch },
  } = useController({
    name: 'search',
    control,
  });

  const watchSearch = watch('search');

  // INIT OPTIONS MOVE
  useEffect(() => {
    setOptsMove(initOptsMove);
  }, [initOptsMove]);

  // UPDATE OPTIONS MOVE
  useEffect(() => {
    if (!optsMove) return;
    const getOpstsMoveToCases = async () => {
      const cases = await loadOptionsCase(watchSearch);
      setOptsMove(cases);
    };
    getOpstsMoveToCases();
  }, [watchSearch]);

  // FOCUS ON FIELD WHEN IT BECOMES EDITABLE
  useEffect(() => {
    if (!editName) return;
    inputNameRef.current.focus();
    const range = document.createRange();
    const sel = window.getSelection();

    range.setStart(inputNameRef.current, 1);
    range.collapse(true);

    sel.removeAllRanges();
    sel.addRange(range);
  }, [editName]);

  function getClassNameStatus(s) {
    let className = 'pending';
    switch (s) {
      case 'Litige':
        className = 'dispute';
        break;
      case 'En attente':
        className = 'pending';
        break;
      case 'En cours':
        className = 'in-progress';
        break;
      case 'Archivés':
        className = 'archived';
        break;
      default:
        className = 'pending';
        break;
    }
    return className;
  }

  async function saveName(value) {
    if (!actions.rename || typeof actions?.rename !== 'function') return;
    setIsLoading(true);
    setEditName(false);
    await actions.rename(dispatch, {
      _id: id,
      name: value,
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  function renameActive(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!actions.rename) return;
    setEditName(true);
  }

  function toggleListCase(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!optsMove) return;
    if (!isMoveListOpened) setFolderMoveSuccess(false);
    setIsMoveListOpened((state) => !state);
  }

  function submitName(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      saveName(e.target.textContent);
    } else if (e.key === 'Escape') {
      e.target.textContent = name;
      setEditName(false);
    }
  }

  async function moveFolder(e, caseId) {
    e.preventDefault();
    e.stopPropagation();
    const response = await actions.move(dispatch, {
      _id: id,
      case: caseId,
    });
    setFolderMoveSuccess(response.status === 200 ? caseId : null);
    setTimeout(() => {
      setFolderMoveSuccess(null);
    }, 2000);
  }

  function handleBlur(e) {
    saveName(e.target.textContent);
  }

  function deleteFolder(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!actions.delete || typeof actions.delete !== 'function') return;
    actions.delete({ _id: id });
  }

  function handleClickOnTile() {
    if (link) navigate(link);
  }

  return (
    <>
      <PositionalModal
        ref={modalRef}
        parent={modalParent}
        alignmentX={'right'}
        alignmentY={'middle'}
      >
        <div className={styles.containerActions} data-close-modal='false'>
          {actions?.move && (
            <div className={isMoveListOpened ? `${styles.moveFolderTo} ${styles.opened}` : styles.moveFolderTo}>
              <div
                className={styles.titleAction}
                onClick={(e) => toggleListCase(e)}
              >
                <img src={moveFolderIcon} alt={'déplacer vers un dossier d\'affaire'} />
                <p>Déplacer vers un dossier d'affaire</p>
                {optsMove
                  ? <>
                    {isMoveListOpened
                      ? <BiChevronUp size={'23px'} />
                      : <BiChevronRight size={'23px'} />
                    }
                  </>
                  : <>
                    <div className={styles.loader}><Loader size={18} /></div>
                  </>
                }
              </div>
              {isMoveListOpened && (
                <div>
                  <div className={styles.fieldSearch}>
                    <input
                      {...field}
                      value={valueSearch}
                      name={'search'}
                      type='search'
                      placeholder='Rechercher une affaire...'
                    />
                  </div>
                  {folderMoveSuccess && <p className={styles['success-move']}>Votre dossier à été déplacé dans :</p>}
                  <ul>
                    {(optsMove || []).filter((d) => (
                      !folderMoveSuccess || (folderMoveSuccess && folderMoveSuccess === d.value)
                    )).map((c) => <li
                      key={`case-${c.value}`}
                      onClick={(e) => moveFolder(e, c.value)}
                    >
                      <div className={`${styles.status} ${styles[getClassNameStatus(c.status)]}`} />
                      <p>
                        {c.label}
                      </p>
                    </li>)}
                  </ul>
                </div>
              )}
            </div>
          )}
          <div onClick={(e) => renameActive(e)}>
            <img src={renameIcon} alt='Renommer' />
            <p>Renommer</p>
          </div>
          {actions?.download && (<div><img src={downloadIcon} alt='Télécharger' /><p>Télécharger</p></div>)}
          {actions?.delete && (
            <div onClick={(e) => deleteFolder(e)}>
              <img src={deleteIcon} alt='Supprimer' />
              <p>Supprimer</p>
            </div>
          )}
        </div>
      </PositionalModal>

      <div className={styles.wrapper}
        style={link ? { cursor: 'pointer' } : {}}
        onClick={() => handleClickOnTile()}
      >
        <svg width="170" height="137" viewBox="0 0 988 760" >
          <path id='folder' d="M55.93 760C25.09 760 0 734.91 0 704.07V55.93C0 25.09 25.09 0 55.93 0H244.45C249.74 0 254.92 1.82 259.04 5.13L382.9 104.55C393.7 113.22 407.28 117.99 421.12 117.99H932.06C962.9 117.99 987.99 143.08 987.99 173.92V704.06C987.99 734.9 962.9 759.99 932.06 759.99H55.93V760Z" fill="white" />
          <clipPath id="insideFolder">
            <use xlinkHref="#folder" />
          </clipPath>
          <use
            xlinkHref="#folder"
            strokeWidth="10" stroke="#DEE0EA" fill="none"
            clipPath="url(#insideFolder)" />
        </svg>
        <div className={styles.folder}>
          {actions && (
            <div
              className={styles['container-actions']}
              ref={modalParent}
              onClick={!isLoading ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                modalRef.current.open();
              } : () => { }}
            >
              {isLoading
                ? <div className={styles.loader}><Loader size={20} /></div>
                : <img src={dots} alt={`action sur : ${name}`} />
              }
            </div>
          )}
          {(status && !actions) && (
            <div className={styles['container-status']}>
              <div className={`${styles.status} ${styles[getClassNameStatus(status)]}`}></div>
            </div>
          )}
          <div className={styles.container}>
            {(commercialUser?.firstName && commercialUser?.lastName) && (
              <div className={styles.initials}>
                <span>{commercialUser?.firstName[0]}{commercialUser?.lastName[0]}</span>
              </div>
            )}
            <div className={styles.info}>
              {caseRef && <p className={styles.caseRef}>#{caseRef}</p>}
              {!editName
                ? <p className={styles.name}>{name}</p>
                : <p
                  contentEditable
                  suppressContentEditableWarning
                  className={styles.name}
                  ref={inputNameRef}
                  type='text'
                  onKeyDown={submitName}
                  onBlur={handleBlur}
                >{name}</p>
              }
              <p className={styles.lastUpdate}>
                Dernière modif&nbsp;: {dateStringDot(lastUpdate)}
              </p>
            </div>
          </div>
        </div >
      </div>
    </>
  );
};

export default TileFolder;
