import React from 'react';
import PropTypes from 'prop-types';
import styles from './error-field.module.scss';

const ErrorField = ({ message = '' }) => (
    <div className={styles['errors-fields']}>
      <p>{message}</p>
    </div>
);

ErrorField.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorField;
