import { postData, getData } from './index';
import {
  AUTH_SIGNIN,
  AUTH_GET_PROFILE,
  AUTH_SET_USER_AGENCY,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
} from './types';

export const signIn = async (dispatch, data) => {
  const url = '/auth/login';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  const user = response.data?.user;
  const token = response.data?.token;

  if (user && token) {
    dispatch({
      type: AUTH_SIGNIN,
      payload: { user, token },
    });
  }
  return response;
};

export const forgotPassword = async (dispatch, data) => {
  const url = '/auth/forgot-password';
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  return response;
};

export const resetPassword = async (dispatch, data) => {
  const url = `/auth/reset-password/${data.token}`;
  const response = await postData(AUTH_ERROR, url, dispatch, { password: data.password }, false);
  return response;
};

export const logout = (dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
  });
};

export const getProfileAction = async (dispatch) => {
  const url = '/profile';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await getData(AUTH_ERROR, url, dispatch, true);

  if (response.data.user) {
    dispatch({
      type: AUTH_GET_PROFILE,
      payload: response.data.user,
    });
  }
};

export const setUserAgency = (dispatch, agency) => {
  dispatch({
    type: AUTH_SET_USER_AGENCY,
    payload: agency,
  });
};

export const resetError = (dispatch) => {
  dispatch({
    type: AUTH_RESET_ERROR,
  });
};
