import {
  EVENTS_GET,
  EVENTS_GET_LIST,
  EVENT_SELECT,
  EVENT_POST,
  EVENT_PUT,
  EVENT_DELETE,
  EVENT_LOADING,
  EVENT_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  events: null,
  selectedEvent: null,
  isLoading: [],
  list: {},
  error: null,
  changedAt: Date.now(),
};

const eventReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  const updateEvents = state.events || [];
  const eventIndex = (updateEvents || []).findIndex((p) => (
    p._id === action.payload?.event?._id
  ));
  switch (action.type) {
    case EVENTS_GET:
      updatedState = {
        ...state,
        events: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== EVENTS_GET),
        error: null,
      };
      break;
    case EVENTS_GET_LIST:
      updatedState = {
        ...state,
        list: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== EVENTS_GET_LIST),
        error: null,
      };
      break;
    case EVENT_SELECT:
      updatedState = {
        ...state,
        selectedEvent: action.payload,
      };
      break;
    case EVENT_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        events: [action.payload, ...state.events],
        isLoading: state.isLoading?.filter((d) => d !== EVENT_POST),
        error: null,
      };
      break;
    case EVENT_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        events: state.events.filter((event) => event._id !== action.payload),
        isLoading: state.isLoading?.filter((d) => d !== EVENT_DELETE),
        error: null,
      };
      break;
    case EVENT_PUT:
      if (typeof eventIndex === 'number') {
        updateEvents[eventIndex] = action.payload.event;
      }
      updatedState = {
        ...state,
        events: updateEvents,
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter((d) => d !== `EVENT_PUT-${action.payload.event._id}`),
        error: null,
      };
      break;
    case EVENT_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case EVENT_ERROR:
      updatedState = { ...state };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default eventReducer;
