import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { IoIosAdd } from 'react-icons/io';
import { SEARCH_GET } from '../../actions/types';
import { getSearch, getSearchList } from '../../actions/search';
import { resetErrorCase } from '../../actions/case';
import ModalStandalone from '../../lib/ModalStandalone';
import CaseFilters from '../../components/CaseFilters';
import CasesList from '../../components/list/CasesList';
import CaseLegend from '../../components/CaseLegend';
import ResultSearch from '../../components/ResultSearch';
import ModalSendEmail from '../../components/ModalSendEmail';
import Loader from '../../components/Loader';
import CaseForm from '../../components/form/CaseForm';
import noCaseImg from '../../assets/images/no-case.png';
import styles from './cases.module.scss';

const Cases = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState(null);
  const [isSearchString, setIsSearchString] = useState(null);
  const [isFilterActive, setIsFilterActive] = useState(null);
  const [sendDocument, setSendDocument] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const { searchReducer, authReducer } = useSelector((store) => store);
  const { selectedAgency, user: { rights = {} } } = authReducer;
  const {
    cases,
    files,
    list,
    isLoading,
  } = searchReducer;
  const modalCreate = useRef();

  useEffect(() => {
    getSearchList(dispatch);
  }, [selectedAgency]);

  // HANDLE SEARCH WITH searchParams
  useEffect(() => {
    if (!Object.values(list).length) return;
    getSearch(dispatch, searchParams, list);
  }, [searchParams, list, selectedAgency]);

  // INIT IS OPENED
  useEffect(() => {
    if (typeof isOpened === 'boolean') return;
    const params = new URLSearchParams(searchParams);
    const search = Object.fromEntries(params);
    const {
      users,
      status,
      clients,
      creationDate,
    } = search;
    const isSearchParams = Object.values({
      users,
      status,
      clients,
      creationDate,
    }).filter((d) => d).length > 0;
    setIsOpened(isSearchParams);
  }, [searchParams, isOpened]);

  // INIT SEARCH STRING
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const search = Object.fromEntries(params);
    const { search: searchTxt } = search;
    setIsSearchString(!!searchTxt);
  }, [searchParams]);

  // HANDLE IF CASE FILTERS ACTIVE
  // TWO CONDITIONS => IS OPEN OR IF THERE ARE A TEXT SEARCH
  useEffect(() => {
    setIsFilterActive(isOpened || isSearchString);
  }, [isOpened, isSearchString]);

  useEffect(() => {
    const params = Object.fromEntries(searchParams);
    if (params.createCase !== undefined) {
      console.log('open modal');
      modalCreate.current.open();
    } else {
      modalCreate.current.close();
    }
  }, [searchParams]);

  function handleClose() {
    const params = new URLSearchParams(searchParams);
    params.delete('createCase');
    setSearchParams(params);
    resetErrorCase(dispatch);
  }

  return (
    <div className={styles.cases}>
      <ModalSendEmail sendDocument={sendDocument} />
      <ModalStandalone ref={modalCreate} handleClose={handleClose}>
        <CaseForm handleClose={handleClose} />
      </ModalStandalone>
      <div className={styles.search}>
        <CaseFilters isOpened={isOpened} setIsOpened={setIsOpened} />
      </div>
      <div className={styles.container}>
        {(isLoading.includes(SEARCH_GET))
          && <div className={styles.loader}><Loader /></div>
        }
        {cases !== null && (
          <>
            {!isFilterActive ? (
              <div>
              {cases?.length > 0
                ? <div className={styles.containerCases}>
                  <div className={styles.header}>
                    <h3>Affaires</h3>
                    <CaseLegend />
                  </div>
                  <div>
                    <CasesList
                      cases={cases}
                      sendCase={(doc) => {
                        setSendDocument(doc);
                      }}
                    />
                  </div>
                </div>
                : <div className={styles.noCase}>
                  <p>il n'y a pas encore d'affaires...</p>
                  {rights.write && (
                    <button className='secondary' onClick={() => navigate('/cases?createCase=1')}>
                      <span className='icon'><IoIosAdd size={'20px'} /></span>
                      <span>Créer une affaire</span>
                    </button>
                  )}
                  <img src={noCaseImg} alt="il n'y a pas encore d'affaires" />
                </div>
              }
            </div>
            ) : (
              <div>
                <ResultSearch cases={cases} files={files} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Cases;
