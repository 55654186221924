import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useController } from 'react-hook-form';
import { BiChevronRight, BiChevronUp } from 'react-icons/bi';
import { FaFileExport } from 'react-icons/fa';
import { downloadFolders, loadOptionsCase } from '../../../utils';
import PositionalModal from '../../../lib/PositionalModal';
import Loader from '../../Loader';
import folderImg from '../../../assets/images/icons/folder-filled.svg';
import dots from '../../../assets/images/icons/green-dots.svg';
import mailIcon from '../../../assets/images/icons/mail.svg';
import deleteIcon from '../../../assets/images/icons/delete.svg';
import downloadIcon from '../../../assets/images/icons/download.svg';
import renameIcon from '../../../assets/images/icons/rename.svg';
import moveFolderIcon from '../../../assets/images/icons/folder-move-to.svg';

import styles from './folders-list.module.scss';

const FolderItem = ({
  folder,
  handleClickFolder,
  actions = {},
  initOptsMove = [],
}) => {
  const { user: { rights = {} } } = useSelector((store) => store).authReducer;
  const modalRef = useRef();
  const modalParent = useRef();
  const inputNameRef = useRef();
  const dispatch = useDispatch();
  const [editName, setEditName] = useState(false);
  const [valueName, setValueName] = useState(folder.name);
  const [isLoading, setIsLoading] = useState(false);
  const [folderMoveSuccess, setFolderMoveSuccess] = useState(false);
  const [isMoveListOpened, setIsMoveListOpened] = useState(false);
  const [optsMove, setOptsMove] = useState(null);

  const {
    control,
    watch,
  } = useForm();

  const {
    field,
    fieldState: { value: valueSearch },
  } = useController({
    name: 'search',
    control,
  });

  const watchSearch = watch('search');

  // FOCUS ON FIELD WHEN IT BECOMES EDITABLE
  useEffect(() => {
    if (!editName) return;
    inputNameRef.current.focus();
  }, [editName]);

  // SET POSITION MODAL WHEN LIST CASE OPEN CHANGE
  useEffect(() => {
    if (!actions.move) return;
    modalRef.current.setPositionModal();
  }, [isMoveListOpened]);

  // INIT OPTIONS MOVE
  useEffect(() => {
    setOptsMove(initOptsMove);
  }, [initOptsMove]);

  // UPDATE OPTIONS MOVE
  useEffect(() => {
    if (!optsMove) return;
    const getOpstsMoveToCases = async () => {
      const cases = await loadOptionsCase(watchSearch);
      setOptsMove(cases);
    };
    getOpstsMoveToCases();
  }, [watchSearch]);

  function openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    modalRef.current.open();
  }

  async function saveName() {
    if (!actions.rename || typeof actions?.rename !== 'function') return;
    setIsLoading(true);
    setEditName(false);
    await actions.rename(dispatch, {
      _id: folder._id,
      name: valueName,
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  function renameActive(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!actions.rename) return;
    setEditName(true);
  }

  function deleteFolder(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!actions.delete || typeof actions.delete !== 'function') return;
    actions.delete(folder);
  }

  function toggleListCase(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!optsMove) return;
    setFolderMoveSuccess(null);
    setIsMoveListOpened((state) => !state);
  }

  async function moveFolder(e, caseId) {
    e.preventDefault();
    e.stopPropagation();
    const response = await actions.move(dispatch, {
      _id: folder._id,
      case: caseId,
    });
    setFolderMoveSuccess(response.status === 200 ? caseId : null);
    setTimeout(() => {
      setFolderMoveSuccess(null);
    }, 2000);
  }

  function sendFolder(e) {
    e.preventDefault();
    e.stopPropagation();
    modalRef.current.close();
    actions.send({ _id: folder._id, name: valueName, type: 'folder' });
  }

  function dowloadFolder(e) {
    e.preventDefault();
    e.stopPropagation();
    downloadFolders(valueName, [{ _id: folder._id, type: 'folder' }]);
  }

  function submitName(e) {
    if (e.key === 'Enter') {
      inputNameRef.current.blur();
    } else if (e.key === 'Escape') {
      setValueName(folder.name);
      inputNameRef.current.blur();
      setEditName(false);
    }
  }

  function handleBlur() {
    saveName();
  }

  function getClassNameStatus(s) {
    let className = 'pending';
    switch (s) {
      case 'Litige':
        className = 'dispute';
        break;
      case 'En attente':
        className = 'pending';
        break;
      case 'En cours':
        className = 'in-progress';
        break;
      case 'Archivés':
        className = 'archived';
        break;
      default:
        className = 'pending';
        break;
    }
    return className;
  }

  return (
    <li
      style={handleClickFolder ? { cursor: 'pointer' } : {}}
      onClick={handleClickFolder ? () => handleClickFolder(folder._id) : () => { }}
    >
      <PositionalModal
        ref={modalRef}
        parent={modalParent}
        alignmentX={'right'}
        alignmentY={'middle'}
        handleClose={() => setIsMoveListOpened(false)}
      >
        <div className={styles.containerActions} data-close-modal='false' onClick={(e) => e.stopPropagation()}>
          {(rights.write && actions.move) && (
            <div className={isMoveListOpened ? `${styles.moveFolderTo} ${styles.opened}` : styles.moveFolderTo}>
              <div
                className={styles.titleAction}
                onClick={(e) => toggleListCase(e)}
              >
                <img src={moveFolderIcon} alt={'déplacer vers un dossier d\'affaire'} />
                <p>Déplacer vers un dossier d'affaire</p>
                {optsMove
                  ? <>
                    {isMoveListOpened
                      ? <BiChevronUp size={'23px'} />
                      : <BiChevronRight size={'23px'} />
                    }
                  </>
                  : <>
                    <div className={styles.loader}><Loader size={18} /></div>
                  </>
                }
              </div>
              {isMoveListOpened && (
                <div>
                  <div className={styles.fieldSearch}>
                    <input
                      {...field}
                      value={valueSearch}
                      name={'search'}
                      type='search'
                      placeholder='Rechercher une affaire...'
                    />
                  </div>
                  {folderMoveSuccess && <p className={styles['success-move']}>Votre dossier à été déplacé dans :</p>}
                  <ul style={folderMoveSuccess ? { margin: 0 } : {}}>
                    {(optsMove || []).filter((d) => (
                      !folderMoveSuccess || (folderMoveSuccess && folderMoveSuccess === d.value)
                    )).map((c) => <li
                      key={`case-${c.value}`}
                      onClick={(e) => moveFolder(e, c.value)}
                    >
                      <div className={`${styles.status} ${styles[getClassNameStatus(c.status)]}`} />
                      <p>
                        {c.label}
                      </p>
                    </li>)}
                  </ul>
                </div>
              )}
            </div>
          )}
          {(rights.write && actions.rename) && (
            <div onClick={(e) => renameActive(e)}>
              <img src={renameIcon} alt='Renommer' />
              <p>Renommer</p>
            </div>
          )}
          {actions.print && (<div onClick={(e) => dowloadFolder(e)}>
            <FaFileExport size='20px' />
            <p>Télécharger</p>
          </div>
          )}
          {actions.send && (<div onClick={(e) => sendFolder(e)}><img src={mailIcon} alt='Envoyer par mail' /><p>Envoyer par mail</p></div>)}
          {actions.download && (<div><img src={downloadIcon} alt='Télécharger' /><p>Télécharger</p></div>)}
          {(rights.write && folder.isEditable && actions.delete) && (
            <div onClick={(e) => deleteFolder(e)}>
              <img src={deleteIcon} alt='Supprimer' />
              <p>Supprimer</p>
            </div>
          )}
        </div>
      </PositionalModal>
      <div>
        {isLoading
          ? <div className={styles.loader}><Loader size={20} /></div>
          : <img src={folderImg} alt={`dossier ${folder.name}`} />
        }
        {!editName
          ? <p>{valueName}</p>
          : <input
            ref={inputNameRef}
            type='text'
            value={valueName}
            onKeyDown={submitName}
            onBlur={handleBlur}
            onChange={(e) => setValueName(e.target.value)}
          />
        }
      </div>
      <div className={styles.toggle} ref={modalParent}>
        <span onClick={openModal}>
          <img src={dots} alt={`action sur le dossier: ${folder.name}`} />
        </span>
      </div>
    </li>
  );
};

const FoldersList = ({
  folders,
  handleClickFolder,
  actions,
  initOptsMove = [],
}) => (
  <div className={styles.foldersList}>
    {(folders) && (
      <ul>
        {folders.map((folder) => <FolderItem actions={actions} initOptsMove={initOptsMove} key={`folder-${folder._id}`} folder={folder} handleClickFolder={handleClickFolder} />)}
      </ul>
    )}
  </div>
);

export default FoldersList;
