/* eslint-disable no-dupe-keys */
const styleBase = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    // overflow: 'hidden',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    borderColor: 'transparent',
    borderWidth: '0px',
    backgroundColor: '#F4F7FE',
    minHeight: '31px',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Manrope-Regular',
    fontSize: '12px',
    color: '#717A97',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '12px' }),
  multiValue: (provided) => ({ ...provided, fontSize: '12px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 2px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    backgroundColor: state.isSelected ? '#717A97' : '',
    cursor: 'pointer',
    color: '#1B2559',
    '&:hover': {
      backgroundColor: state.isSelected ? '#717A97' : '#F4F7FE',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '31px',
  }),
};

const stylePrimary = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
    // overflow: 'hidden',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    borderColor: 'transparent',
    borderWidth: '0px',
    backgroundColor: '#FFFFFF',
    minHeight: '31px',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Manrope-Regular',
    fontSize: '12px',
    color: '#717A97',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '12px' }),
  multiValue: (provided) => ({ ...provided, fontSize: '12px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 2px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    backgroundColor: state.isSelected ? '#717A97' : '',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? '#717A97' : '#F4F7FE',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    backgroundColor: state.isSelected ? '#717A97' : '',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? '#717A97' : '#F4F7FE',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '31px',
  }),
};

const styleDark = {
  container: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0px 18px 32px rgba(208, 210, 218, 0.15)',
    borderColor: 'transparent',
    borderWidth: '0px',
    backgroundColor: '#DEE0EA',
    minHeight: '31px',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Manrope-Regular',
    fontSize: '12px',
    color: '#717A97',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '12px' }),
  multiValue: (provided) => ({ ...provided, fontSize: '12px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 2px',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '12px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    backgroundColor: state.isSelected ? '#717A97' : '',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? '#717A97' : '#F4F7FE',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px 0 ',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '31px',
  }),
};

export { styleBase, stylePrimary, styleDark };
