import React, {
  createRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HiFlag } from 'react-icons/hi';
import { FaFileExport } from 'react-icons/fa';
import { deleteCase } from '../../../actions/case';
import { dateStringDot, downloadFolders } from '../../../utils';
import PositionalModal from '../../../lib/PositionalModal';
import List from '../../../lib/List';
import dots from '../../../assets/images/icons/green-dots.svg';
import mailIcon from '../../../assets/images/icons/mail.svg';
import deleteIcon from '../../../assets/images/icons/delete.svg';
import styles from './cases-list.module.scss';

import withModalConfirm from '../../../lib/withModalConfirm';

const CellAction = ({
  caseRef,
  doubleCheck,
  _id,
  client,
  confirm,
  sendCase,
}) => {
  const { user: { rights = {} } } = useSelector((store) => store).authReducer;
  const dispatch = useDispatch();
  const cellRef = createRef(`case-${_id}`);
  const modalRef = createRef(`case-modal-${_id}`);

  function openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    modalRef.current.open();
  }

  function fnCeleteCase(e) {
    e.preventDefault();
    e.stopPropagation();
    confirm(() => deleteCase(dispatch, _id), 'Êtes vous sur de vouloir supprimer cette affaire ?');
  }

  function handleSendCase(e) {
    e.preventDefault();
    e.stopPropagation();
    modalRef.current.close();
    sendCase({ _id, name: `#${caseRef} - ${client}`, type: 'case' });
  }

  function dowloadFolder(e) {
    e.preventDefault();
    e.stopPropagation();
    downloadFolders(client, [{ _id, type: 'folder' }]);
  }

  return (
    <div className={styles.cellAction}>
      <PositionalModal
        ref={modalRef}
        parent={cellRef}
        alignmentX={'right'}
        alignmentY={'middle'}
      >
        <div className={styles.containerActions}>
          <div onClick={(e) => dowloadFolder(e)}>
            <FaFileExport size='20px' />
            <p>Télécharger</p>
          </div>
          <div
            onClick={(e) => handleSendCase(e)}
          >
            <img src={mailIcon} alt='Envoyer par mail' /><p>Envoyer par mail</p>
          </div>
          {rights.write && (
            <div
              onClick={(e) => fnCeleteCase(e)}
            >
              <img src={deleteIcon} alt='Supprimer' /><p>Supprimer</p>
            </div>
          )}
        </div>
      </PositionalModal>
      {doubleCheck && <HiFlag size='20px' />}
      <div className={styles.toggle} ref={cellRef}>
        <span onClick={(e) => openModal(e)}>
          <img src={dots} alt={`action sur l'affaire: ${caseRef}`} />
        </span>
      </div>
    </div>
  );
};

const CasesList = ({ cases, sendCase, confirm }) => {
  const navigate = useNavigate();

  function handleSelectRow(item) {
    navigate(`/case/${item._id}`);
  }

  function getClassNameStatus(status) {
    let className = 'pending';
    switch (status) {
      case 'Litige':
        className = 'dispute';
        break;
      case 'En attente':
        className = 'pending';
        break;
      case 'En cours':
        className = 'in-progress';
        break;
      case 'Archivés':
        className = 'archived';
        break;
      default:
        className = 'pending';
        break;
    }
    return className;
  }

  const CellDate = ({ updatedAt = new Date() }) => (
    <div className={styles.cellDate}>
      <p>{dateStringDot(new Date(updatedAt))}</p>
    </div>
  );

  const CellRef = ({ ref, status = 'En cours' }) => (
    <div className={styles.cellRef}>
      <div className={`${styles.status} ${styles[getClassNameStatus(status)]}`}></div>
      <p>#{ref}</p>
    </div>
  );

  const CellClient = ({ client }) => (
    <div className={styles.cellClient}>
      <p>{client}</p>
    </div>
  );

  const CellName = ({ name }) => (
    <div className={styles.cellClient}>
      <p>{name}</p>
    </div>
  );

  const CellCommercial = ({ user }) => (
    <div className={styles.cellCommercial}>
      {user && (
        <>
          <div className={styles.initials}>
            <span>{user.profile.firstName[0]}{user.profile.lastName[0]}</span>
          </div>
          <p>{user.profile.firstName} {user.profile.lastName}</p>
        </>
      )}
    </div>
  );

  return (
    <div className={styles.casesList}>
      {cases && <List
        classNameRow={styles.row}
        classNameRowHeader={styles.rowHeader}
        classNameRowItem={styles.rowItem}
        defaultSort={{ key: 'ref', order: 'asc' }}
        defaultOrder={['ref']}
        col={[
          {
            title: 'Numéro affaire',
            component: CellRef,
            itemProperty: 'ref',
            sortable: true,
          },
          {
            title: 'Client',
            component: CellClient,
            itemProperty: 'client',
            sortable: true,
          },
          {
            title: 'Nom',
            component: CellName,
            itemProperty: 'name',
            sortable: true,
          },
          {
            title: 'Commercial',
            component: CellCommercial,
            itemProperty: 'user.profile.lastName',
            sortable: true,
          },
          {
            title: 'Date',
            component: CellDate,
            itemProperty: 'updatedAt',
            sortable: true,
          },
          {
            title: '',
            itemProperty: 'doubleCheck',
            sortable: true,
            component: ({
              _id,
              ref,
              client,
              doubleCheck,
            }) => (
              <CellAction
                sendCase={sendCase}
                caseRef={ref}
                doubleCheck={doubleCheck}
                _id={_id}
                client={client}
                confirm={confirm}
              />
            ),
          },
        ]}
        items={cases}
        handleClickRow={handleSelectRow}
      >
      </List>}
    </div>
  );
};

export default withModalConfirm(CasesList);
